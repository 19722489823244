import React from 'react'
import { Select, MenuItem, } from '@mui/material';

function ZoomSelection(props) {

    const handleZoomChange = (event) => {
        props.setScaleCallback(parseFloat(event.target.value));
    };

    return (
            <Select
                variant="standard"
                style={{ height: '32px', }}
                value={props.scale}
                onChange={handleZoomChange}
                label="Zoom Level"
            >
            <MenuItem style={{ fontSize: '12px' }} value={0.6}>50%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={0.7}>70%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={0.8}>80%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={0.9}>90%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={1.}>100%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={1.1}>110%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={1.2}>120%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={1.3}>130%</MenuItem>
            <MenuItem style={{ fontSize: '12px' }} value={1.4}>150%</MenuItem>
            </Select>
    )

}
export default ZoomSelection;
