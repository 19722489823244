import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import RecordingReminderPopup from './recording_reminder_popup';
import Onboarding from './onboarding';
import RecordingTest from './recording_test';
import StudentDashboard from './student_dashboard';
import TeacherDashboard from './teacher_dashboard';
import ZoomSelection from './zoom_selection';
import AsStudentSelect from './as_student_select';
import { LinkButton, OutlinedButton } from './customized_components';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';

function Dashboard(props) {

    const lang = props.lang;
    const navigate = useNavigate();
    const location = useLocation();

    const [isRecordingTest, setIsRecordingTest] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [onboarding, setOnboarding] = useState(null);

    const sessionKey = "popupShown"; // Session key to track popup only once after login

    const clearOnboardingCallback = () => {
        setOnboarding(false);
        props.enableTabsCallback(true);
        sessionStorage.setItem(sessionKey, 'true');
    };
    const clearPopCallback = () => {
        setShowPop(false)
        sessionStorage.setItem(sessionKey, 'true');
    }
    const handleRecordingTestClick = () => {
        clearOnboardingCallback();
        setShowPop(false);
        setIsRecordingTest(true);
        navigate('/app/dashboard/recording_test');
    };

    useEffect(() => {
        const isSessionKeySet = sessionStorage.getItem(sessionKey);
        if (!isSessionKeySet) {
            if (props.firstLogin) {
                setOnboarding(true);
                props.enableTabsCallback(false);
            }
            else {
                setShowPop(true);
            }
            //sessionStorage.setItem(sessionKey, 'true'); // how did this caused to reset showPop back to false/null
        }
    }, []);

    const setAsStudentCallback = (val) => {
        axiosInstance.post('/api/user_settings/', { as_student: val }).then();
        props.setAsStudentCallback(val);
    };

    useEffect(() => {
        const handlePopState = () => {
            if (location.pathname === '/app/dashboard/recording_test') {
                setIsRecordingTest(true);
            } else {
                setIsRecordingTest(false);
            }
        };
        handlePopState();
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location.pathname]);

    const lang_dir = lang === 'he' ? 'rtl' : 'ltr';

    if (isRecordingTest) {
        return (
            <RecordingTest
                {...props}
                onExit={() => {
                    setIsRecordingTest(false);
                    navigate('/app/dashboard', { replace: true });
                }}
            />
        );
    }
    if (onboarding) {
        return (
            <Onboarding
                {...props}
                clearOnboardingCallback={clearOnboardingCallback}
                handleRecordingTestClick={handleRecordingTestClick}
                userFullname={props.userFullname}
            />
        )
    }

    return (
        <div style={{ flex: 1 }}>
            {props.passedRectest === 0 &&
                <RecordingReminderPopup
                    showTip={showPop}
                    clearTipCallback={clearPopCallback}
                    handleRecordingTestClick={handleRecordingTestClick}
                    lang={lang}
                />
            }
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                <LinkButton startIcon={<MicIcon />} onClick={handleRecordingTestClick}>
                    <div style={{ marginRight: '10px' }}> {Tabstext.RecordingTest[lang]} </div>
                </LinkButton>
                {props.headerContext.TeacherAttr && (
                    <AsStudentSelect
                        lang={lang}
                        asStudent={props.asStudent}
                        setAsStudentCallback={setAsStudentCallback}
                    />
                )}
                <ZoomSelection scale={props.scale} setScaleCallback={props.setScaleCallback} />
            </div>
            {props.asStudent && (
                <StudentDashboard lang={props.lang} setContentCallback={props.setContentCallback} />
            )}
            {props.headerContext.TeacherAttr && (
                <TeacherDashboard
                    lang={props.lang}
                    setContentCallback={props.setContentCallback}
                    display={!props.asStudent}
                />
            )}
        </div>
    );
}

export default Dashboard;
