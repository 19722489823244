import React, { useState, useEffect} from "react";
import { Popover, Slider, Button, Tooltip } from '@mui/material';
import Tabstext from './tabstext';
import { LinkButton, SmallTitle,} from './customized_components'
import { axiosInstance, } from './axios_instance';
import SettingsIcon from '@mui/icons-material/Settings';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

function ToleranceSetting(props) {
    if (!props.pitchToleranceRange)
        return null;

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSave = () => {
        handleClose();
        if (props.groupDisplay || props.feedbackDisplay)
            props.setPitchToleranceCallback(sliderValue);
        else
            axiosInstance.post('/api/user_settings/',{pitch_tolerance: sliderValue}).then();
    };

    const [sliderValue, setSliderValue] = useState(props.pitchTolerance); // Starting value
    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };
    if (props.feedbackData) {
        useEffect(() => {
            setSliderValue(props.pitchTolerance);
        }, [props.feedbackData.run_pk]);
    }
    // the marks reflects full-tone fractions while pitch tolerance is defined in semitones
    const marks = props.pitchToleranceRange;
    const minval = marks[0].value;
    const maxval = marks[marks.length-1].value;
    const step =  marks[1].value - minval;

    return (
        <div style={{display:props.display?'':'none'}} >
            <Tooltip title={props.tooltipText} placement="top" >
                <LinkButton size='small' onClick={handleClick} >
                    <SmallTitle >{Tabstext.SetTolerance[props.lang]}</SmallTitle>
                    {!props.feedbackDisplay && <SettingsIcon size='small' />}
                    {props.feedbackDisplay && <MusicNoteIcon style={{color:'green'}} />}
                </LinkButton>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                >
                <div style={{
                    padding:'0 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    }}>
                    <Slider
                      size="small"
                      value={sliderValue}
                      min={minval} // Minimum value
                      max={maxval} // Maximum value
                      step={0.5} // only discrete marks
                      marks={marks} // Show marks at each step
                      onChange={handleSliderChange}
                      style={{ width: '100px' }}
                    />
                    <Button onClick={handleSave}
                        style={{ mt: 2, alignSelf: 'flex-start', textTransform: 'none', }} >
                        {props.feedbackDisplay? Tabstext.ReanalyzeTab[props.lang]:Tabstext.SaveTab[props.lang]}
                    </Button>
                </div>
            </Popover>
        </div>
    );
}

export default ToleranceSetting
