import React from 'react'
import { useState, useCallback } from "react";
import { axiosInstance, } from './axios_instance';
import AudioMain from './audio';
import TitleAndSongSelections from './title_song';
import Tabstext from './tabstext';
import ScoreSyricsSelect from './score_lyrics_select';
import MusicScore from './music_score';
import ToleranceSetting from './tolerance_setting';
import {SmallTitle} from './customized_components'
import './styles/extras.css';
import { CircularProgress } from '@mui/material';

function ScorePage(props) {
    const [waitForFeedback, setWaitForFeedback] = useState(false);
    const context = props.context;
    const exe = props.exe;
    const [canvasRef, setCanvasRef] = useState(null)
    const canvasRefCallback = useCallback((vf, cursor, container) => {
        setCanvasRef([vf, cursor, container]);
    }, []);
    const [pitchTolerance, setPitchTolerance] = useState([props.pitchTolerance]);
    const setPitchToleranceCallback = (val) => {
        setPitchTolerance(val);
        if (props.feedbackData.run_pk != null) {
            setWaitForFeedback(true);
            axiosInstance.get('/api/reanalyze_run/', {'params':{ 'run_pk':props.feedbackData.run_pk, 'pitch_tolerance':val}})
                .then(function (response) {
                    setWaitForFeedback(false);
                    props.setMusicScoreCallback(response.data);
                })
                .catch(function (error) {
                    console.log('Error reanalyzing:', error);
                })
        }
    };
    const display = props.display && (!props.feedbackDisplay || props.feedbackData);

    return (
        <div style={{ overflowY:'hidden', display:display?'':'none'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor:'white', padding: '12px' }}>
                <div style={{ display: 'flex', alignItems:'center', gap:'20px'}} >
                <AudioMain tempos={context.tempos} exe={exe} lang={props.lang}
                    feedbackData={props.feedbackData}
                    feedbackDisplay={props.feedbackDisplay} setFeedbackDisplayCallback={props.setFeedbackDisplayCallback}
                    inClass={props.inClass}
                    canvasRef={canvasRef}
                    display={display}
                    />
                {props.feedbackDisplay && props.feedbackData &&
                    <SmallTitle >
                        {props.feedbackData.tempo} BPM
                    </SmallTitle>
                }
                </div>
                {props.feedbackDisplay && props.feedbackData &&
                <>
                    <ToleranceSetting
                        feedbackData={props.feedbackData} lang={props.lang}
                        setPitchToleranceCallback={setPitchToleranceCallback}
                        feedbackDisplay={props.feedbackDisplay}
                        pitchTolerance={props.feedbackData.pitch_tolerance}
                        pitchToleranceRange={props.pitchToleranceRange}
                        tooltipText={Tabstext.FeedbackToleranceTooltip[props.lang]}
                        display={!waitForFeedback}
                    />
                    {waitForFeedback &&
                        <CircularProgress size={20} />
                    }
                    <SmallTitle >
                        {Tabstext.RecordingNumber[props.lang]} {props.feedbackData.run_counter}
                    </SmallTitle>
                </>
                }
                {!props.feedbackDisplay &&
                    <ScoreSyricsSelect  context={context} lang={props.lang}
                        exerciseView={props.exerciseView} setExerciseViewCallback = {props.setExerciseViewCallback}
                    />
                }
            </div>
            <div style={{ backgroundColor:'white', marginTop: '12px' }}>
                <TitleAndSongSelections context={context} exe={exe} lang={props.lang}
                    exerciseView={props.exerciseView}
                    nextExe={props.nextExe} prevExe={props.prevExe}
                    nextEnabled={props.nextEnabled} prevEnabled={props.prevEnabled}
                    setPkCallback={props.setPkCallback}
                    feedbackDisplay={props.feedbackDisplay}
                    feedbackData={props.feedbackData}
                    inClass={props.inClass} recTest={false}
                    />
            </div>
            <MusicScore
                musicScore={props.musicScore} canvasRefCallback = {canvasRefCallback}
                windowSize={props.windowSize}
                sidebarWidth = {props.sidebarWidth}
                display={display}
                />
        </div>
    );
};


export default ScorePage