import React, { useState, useEffect } from "react";
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import GroupsTable from './groups_table';
import CreateGroupsForm from './create_groups';
import { ContainedButton, PageTitle } from './customized_components';
import Breadcrumbs from './breadcrumbs';
import { Dialog, DialogContent, Slide } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MyGroups(props) {
    const [myGroups, setMyGroups] = useState(JSON.parse(localStorage.getItem('myGroups')) || []);
    const [registerLink, setRegisterLink] = useState(localStorage.getItem('registerLink') || '');
    const [teacher, setTeacher] = useState(JSON.parse(localStorage.getItem('teacher')) || null);
    const [ageGroups, setAgeGroups] = useState(JSON.parse(localStorage.getItem('ageGroups')) || []);
    const [initialFormData, setInitialFormData] = useState(JSON.parse(localStorage.getItem('initialFormData')) || null);
    const [numCreatedGroups, setNumCreatedGroups] = useState(0);
    const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);

    const setNumCreatedGroupsCallback = (val) => {
        setNumCreatedGroups(numCreatedGroups + val);
    };
    const sessionKey = "groupsDataFetched"; // Session key to track server fetch

    const copyGroupLink = (group) => {
        const link = registerLink + group.pk;
        const text1 = Tabstext.JoinGroupText1[props.lang];
        const text2 = Tabstext.JoinGroupText2[props.lang];
        const text3 = Tabstext.JoinGroupText3[props.lang];
        const text2copy = text1 + group.name + text2 + group.pk + text3 + link;
        navigator.clipboard.writeText(text2copy);
        alert("Copied to clipboard: " + text2copy);
    };

    useEffect(() => {
        const fetchGroupsData = async () => {
            try {
                const response = await axiosInstance.get('/api/my_groups/');
                setTeacher(response.data.Teacher);
                setMyGroups(response.data.MyGroups);
                setRegisterLink(response.data.RegisterLink);
                setAgeGroups(response.data.AgeGroups);
                const formData = {
                    teachername: response.data.Teacher.user_firstname + '.' + response.data.Teacher.user_lastname,
                    location: response.data.Teacher.location || '',
                    schoolname: response.data.Teacher.school || '',
                    year: new Date().getFullYear(),
                    classname: '',
                };
                setInitialFormData(formData);

                // Save data to local storage
                localStorage.setItem('teacher', JSON.stringify(response.data.Teacher));
                localStorage.setItem('myGroups', JSON.stringify(response.data.MyGroups));
                localStorage.setItem('registerLink', response.data.RegisterLink);
                localStorage.setItem('ageGroups', JSON.stringify(response.data.AgeGroups));
                localStorage.setItem('initialFormData', JSON.stringify(formData));

                sessionStorage.setItem(sessionKey, 'true');

            } catch (error) {
                console.error("Failed to fetch group data:", error);
            }
        };

        const isDataInLocalStorage = localStorage.getItem('myGroups') && localStorage.getItem('teacher');
        const isSessionKeySet = sessionStorage.getItem(sessionKey);

        if (!isDataInLocalStorage || !isSessionKeySet) {
            fetchGroupsData();
        }
    }, [numCreatedGroups]);

    const openCreateGroupDialog = () => {
        setShowCreateGroupDialog(true);
    };

    const closeCreateGroupDialog = () => {
        setShowCreateGroupDialog(false);
    };

    const deleteGroup = (group) => {
        axiosInstance.post('/api/delete_group/', { group: group })
            .then((response) => {
                if (response.status === 200 && response.data.message === 'group deleted') {
                    setNumCreatedGroups(numCreatedGroups - 1);
                }
            });
    };

    if (!myGroups || !initialFormData) {
        return <div></div>;
    }

    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';
    return (
        <div style={{ overflowY: 'auto' }}>
            <Breadcrumbs />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
                <PageTitle>
                    {Tabstext.YourCurrentGroupsTab[props.lang]}
                </PageTitle>
                <ContainedButton
                    style={{ margin: '0 24px' }}
                    startIcon={<ControlPointIcon />}
                    onClick={openCreateGroupDialog}
                >
                    {Tabstext.CreateGroupsTab[props.lang]}
                </ContainedButton>
            </div>
            <GroupsTable
                lang={props.lang}
                myGroups={myGroups}
                setPageCallback={props.setPageCallback}
                copyGroupLink={copyGroupLink}
                deleteGroup={deleteGroup}
                windowSize={props.windowSize}
            />
            <Dialog
                open={showCreateGroupDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeCreateGroupDialog}
                aria-describedby="create-group-dialog"
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <CreateGroupsForm
                        lang={props.lang}
                        initialFormData={initialFormData}
                        setNumCreatedGroupsCallback={setNumCreatedGroupsCallback}
                        ageGroups={ageGroups}
                        onClose={closeCreateGroupDialog}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default MyGroups;
