import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import UserProfile from './user_profile';
import { OutlinedButton, FormTitle, } from './customized_components';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Paper, Typography } from '@mui/material';
import { ReactComponent as UserIcon } from '/static/icons/user.svg';

function User(props) {
    const lang = props.lang;
    const [reload, setReload] = useState(true);
    const [view, setView] = useState('user');
    const resetViewCallback = (val) => {
        setView('user');
        setReload(val);
    };

    const [groupName, setGroupName] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (reload) {
            axiosInstance.get('/api/user/', { 'params': {} }).then((response) => {
                setUser(response.data.user);
                setGroupName(response.data.user.group_name);
                props.setUserFullnameCallback(response.data.user.first_name, response.data.user.last_name);
            });
        }
    }, [reload]);

    const Logout = async () => {
        try {
            const response = await axiosInstance.post('/api/logout/');
            props.setContentCallback(null)
            props.setAuthenticatedCallback(false);
            console.log(response.data); // "Logged out successfully."
        } catch (error) {console.error(error)};
    };

    const lang_dir = lang == 'he' ? 'rtl' : 'ltr';

    if (user == null)
        return null;

    return (
        <div 
            dir={lang_dir}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: props.display ? '' : 'none',
                margin:'40px',
            }}
        >
            <div  style={{marginBottom:'12px'}}>
                <UserIcon />
            </div>
            <FormTitle gutterBottom> {user.username} </FormTitle>
            { view =='user' &&
                <div dir={lang_dir}  >
                    <Paper className='shortPaper' style={{marginBottom:'10px'}} >
                        <Typography style={{ padding: '20px' }}>{Tabstext.ProfileUpdate[lang]}</Typography>                        
                            <OutlinedButton startIcon={<PersonOutlineIcon style={{marginLeft:'10px'}} />} onClick={() => setView('profile_setting')} >
                            {Tabstext.ProfileTab[lang]}
                        </OutlinedButton>
                    </Paper>
                    <OutlinedButton onClick={() => Logout(true)} >
                        {Tabstext.SignOutTab[props.lang]}
                    </OutlinedButton>
                </div>
            }
            <UserProfile lang={props.lang} windowSize={props.windowSize}
                user={user} groupName={groupName}
                setUserFullnameCallback={props.setUserFullnameCallback}
                TeacherAttr={props.TeacherAttr}
                resetViewCallback={resetViewCallback}
                allGroupNames={props.allGroupNames}
                allGroupIds={props.allGroupIds}
                display={view == 'profile_setting'} 
            />
        </div>
    );
}

export default User;