import React, { useState } from "react";
import Tabstext from './tabstext';
import { retrieve_past_recording } from './exercise_utils';
import { HeadTableCell, BodyTableCell, CellLink } from './customized_components';
import { Paper, Table, TableHead, TableBody, TableRow, TableContainer, } from '@mui/material';

function RunsHistory(props) {
    const lang = props.lang;
    const runs_dict = props.runs_dict;
    const [lastDisplayedRun, setLastDisplayedRun] = useState(null);
    const tableHeight = props.windowHeight*0.75

    const handleRowClick = (run_pk, run_counter) => {
        if (run_pk !== lastDisplayedRun) {
            retrieve_past_recording(run_pk);
            setLastDisplayedRun(run_pk);
        }
        else
            props.setFeedbackDisplayCallback(true);
        props.setExerciseViewCallback('score');
    };

    return (
        <div style={{ display: props.display ? 'flex' : 'none', justifyContent: "flex-start",
            width: props.runsTableWidth, backgroundColor: 'white', padding: '16px', borderRadius: '0px 16px 16px 16px', margin: '0px'}} >
            <TableContainer component={Paper} style={{ boxShadow: 'none', maxHeight: `${tableHeight}px`}} >
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                            <HeadTableCell style={{ borderRadius:'6px 0 0 6px'}} >
                                {Tabstext.RecordingNumber[lang]}
                            </HeadTableCell>
                            <HeadTableCell>{Tabstext.DateInTable[lang]}</HeadTableCell>
                            <HeadTableCell>{Tabstext.GuideInTable[lang]}</HeadTableCell>
                            <HeadTableCell>{Tabstext.TempoInTable[lang]}</HeadTableCell>
                            <HeadTableCell>{Tabstext.PitchToleranceInTable[lang]}</HeadTableCell>
                            <HeadTableCell style={{ borderRadius:'0 6px 6px 0'}} >
                                {Tabstext.ScoreInTable[lang]}
                            </HeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {runs_dict.map((row, index) => (
                            <TableRow key={index}>
                                <BodyTableCell style={{ }}>
                                    <CellLink
                                        onClick={() => handleRowClick(row.runpk, row.run_counter)}
                                        >
                                        {row.run_counter}
                                    </CellLink>
                                </BodyTableCell>
                                <BodyTableCell>{row.date}</BodyTableCell>
                                <BodyTableCell>{row.guide}</BodyTableCell>
                                <BodyTableCell>{row.tempo}</BodyTableCell>
                                <BodyTableCell>{row.tolerance}</BodyTableCell>
                                <BodyTableCell>{row.performance_score}</BodyTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default RunsHistory;
