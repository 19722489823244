import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import ScorePage from './score_page';
import RunsHistory from './runs_history';
import PracticeRecordingsSelect from './practice_recordings_select';
import ZoomSelection from './zoom_selection';
import {PutSetFeedbackCallback} from './exercise_utils';
import Tabstext from './tabstext';
import { ExerciseContainer, PageTitle, ForwardArrowButton, BackArrowButton } from './customized_components';


function ExerciseDetails(props) {
    const [context, setContext] = useState({});
    const [exe, setExe] = useState([]);
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const setFeedbackCallback = (val,data) => {
        setFeedbackData(data);
        setMusicScore(data.score);
    };
    PutSetFeedbackCallback(setFeedbackCallback);

    const [reanalyzeTrig, setReanalyzeTrig] = useState(false);
    const setReanalyzeTrigCallback = (val) => {
        setReanalyzeTrig(val);
    };
    const [exerciseView, setExerciseView] = useState('runs_table');
    const setExerciseViewCallback = (val) => {
        setExerciseView(val);
        if (val == 'score')
            setFeedbackDisplay(true);
    };
    const [runs_dict, setRunsDict] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/exercise_details/',{'params':{'pk':props.pk,'studentUser':props.username}})
            .then((response) => {
                if (response.data.context) {
                    setContext(response.data.context);
                    setExe(response.data.exe);
                    setRunsDict(response.data.runs_dict);
                }
            })
            .catch((error) => {
                console.log('get exercise error:', error);
                props.setPageCallback('studentDetails', {username: props.username, group: props.group});
            });
    }, []);

    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const setFeedbackDisplayCallback = (val, temp_fdbk) => {
        setFeedbackDisplay(val);
        setExerciseView('score');
        if (val) {
            if (temp_fdbk) {
                setFeedbackData(temp_fdbk);
                setMusicScore(temp_fdbk.score);
            }
            else
                setMusicScore(feedbackData.score);
        }
        else
            setMusicScore(exe.score);
   };
    const setMusicScoreCallback = (data) => {
        setMusicScore(data.score);
    }

    if (context==null || Object.keys(context).length === 0) {
        return null;
    }
    const runsTableWidth = props.windowSize[0]-250 ;

    return (
        <ExerciseContainer >
            <div style={{display:exerciseView=='runs_table'?'':'none'}} >
                <div style={{display:"flex", alignItems:"center", margin:'10px'}} >
                    <BackToStudent lang={props.lang} username={props.username} group={props.group}
                        setPageCallback={props.setPageCallback} category={props.category}
                    />
                    <PageTitle style={{flex: 1, textAlign: 'center'}} >
                        {context.object_title}
                    </PageTitle>
                </div>
                <RunsHistory exe={exe} lang={props.lang}
                    runs_dict={runs_dict}
                    setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    setExerciseViewCallback={setExerciseViewCallback}
                    windowSize={props.windowSize}
                    runsTableWidth={runsTableWidth}
                    display={true}
                />
            </div>
            {exerciseView == 'score' &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PracticeRecordingsSelect context={context} lang={props.lang} reviewMode={true}
                            feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                        />
                        <BackToExercise lang={props.lang}
                            exerciseView={exerciseView} setExerciseViewCallback={setExerciseViewCallback}
                        />
                    </div>
                    <ZoomSelection scale={props.scale} setScaleCallback={props.setScaleCallback} />
                </div>
            }
            <ScorePage context={context} lang={props.lang}
                exe={exe} musicScore={musicScore}
                inExercisePage={false} feedbackData={feedbackData}
                inClass = {false}
                feedbackPresent={true} setFeedbackPresentCallback={[]}
                feedbackDisplay={true} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                setExerciseViewCallback={setExerciseViewCallback}
                pitchToleranceRange={props.pitchToleranceRange}
                reanalyzeTrig={reanalyzeTrig} setReanalyzeTrigCallback={setReanalyzeTrigCallback}
                setMusicScoreCallback={setMusicScoreCallback}
                sidebarWidth = {0}
                windowSize={props.windowSize}
                display={exerciseView=='score'}
                />
        </ExerciseContainer>
    );
}

function BackToStudent(props) {
    const handleButtonClick = () => {
        props.setPageCallback('studentDetails', { username: props.username, group: props.group, category: props.category });
    };
    return  (
        <BackArrowButton onClick={handleButtonClick} >
            {props.username} 
        </BackArrowButton>
    )
}

function BackToExercise(props) {
    const lang = props.lang;
    const handleButtonClick = () => {
        props.setExerciseViewCallback('runs_table');
    };
    return (
        <ForwardArrowButton onClick={handleButtonClick} >
            {Tabstext.all_recordings_tab[lang]}
        </ForwardArrowButton>
    )
}

export default ExerciseDetails;

