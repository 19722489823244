import React, { useState, useEffect } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Button, Tooltip } from '@mui/material';
import './styles/components.scss';
import Tabstext from './tabstext';

function FullScreenButton(props) {
    const [isFullScreen, setIsFullScreen] = useState(false);

    // Add fullscreen change listener to update state
    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        // Clean up listener on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    const handleFullScreenToggle = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    return (
        <Tooltip
            title={!isFullScreen ? Tabstext.FullScreenTooltip[props.lang] : Tabstext.ExitFullScreenTooltip[props.lang]}
            placement="top"
        >
            <Button
                onClick={handleFullScreenToggle}
                className="fullscreenButton"
                startIcon={isFullScreen ? <FullscreenExitIcon style={{ width: '30px', height: '30px' }} /> : <FullscreenIcon style={{ width: '30px', height: '30px' }} />}
            >
            </Button>
        </Tooltip>
    );
}

export default FullScreenButton;
