import React from 'react'
import { useState, useEffect, useRef } from "react";
import {renderScore} from './render_score'
import {moveAudioCursor} from './exercise_utils';

function MusicScore(props) {
    const canvasRef = useRef(null);
    const cursorRef = useRef(null);
    const containerRef = useRef(null);
    const containerWidth = props.windowSize[0]-150-props.sidebarWidth;
    const maxContainerHeight = props.windowSize[1]-200;
    const [containerHeight, setContainerHeight] = useState(maxContainerHeight);

    var musicScore;
    useEffect(() => {
        const vf_canvas = canvasRef.current;
        if (vf_canvas && props.musicScore) {
            if (typeof props.musicScore === 'string')
                musicScore = JSON.parse(props.musicScore);
            else
                musicScore = props.musicScore;
            let height = renderScore(vf_canvas, musicScore, cursorRef.current, containerWidth, maxContainerHeight);
            setContainerHeight(height);
            vf_canvas.addEventListener("click", moveAudioCursor, false);
            props.canvasRefCallback(canvasRef.current,cursorRef.current,containerRef.current);
        }
    }, [props.musicScore,props.windowSize,props.sidebarWidth]);

    return (
        <div id="canvas-container" ref={containerRef} style={{
            display: props.display?'block':'none',
            position: 'relative',
            height: `${containerHeight}px`,
            maxWidth: containerWidth+'px',
            clear: 'both',
            backgroundColor: 'white',
            overflowY:'auto',
            overflowX:'hidden',
            }}
            >
            <canvas id='vf-renderer' ref={canvasRef} ></canvas>
            <div id="music-cursor" ref={cursorRef} className="music-cursor"></div>
        </div>
    )
}

export default MusicScore