import React, { useState, useEffect, useRef, } from "react";
import { Grid, Tabs} from '@mui/material';
import { ExerciseContainer, VerticalTab } from '../customized_components';

import Puzzle from './puzzle';

function Games(props) {
    const [value, setValue] = useState('puzzleSound1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid
            style={{ flex: 1, display: props.display ? 'grid' : 'none', gridTemplateColumns: '200px 1fr' }} >
            <div className='sidebar' >
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }} >
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        indicatorColor='transparent'
                        style={{ borderRight: 'none', marginTop: '40px', }}
                    >
                        <VerticalTab label='Puzzle audio' value="puzzleSound1"  />
                        <VerticalTab label='Puzzle meter' value="puzzleMeter1" />
                    </Tabs>
                </div>
           </div>
            <ExerciseContainer >
                <Puzzle puzzleName={value}/>
            </ExerciseContainer>
        </Grid>
    )
}

export default Games;

