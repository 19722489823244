import React from 'react';
import { Typography, } from '@mui/material';
import Tabstext from './tabstext';

function ContactUs(props) {
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';

    return (
        <div className='creamBgrd' style={{ display: 'flex', flex: 1, flexDirection: 'row', gap: '12px' }} dir={lang_dir} >
            <Typography variant='body1' dir={lang_dir} >
                {Tabstext.ContactUsText[props.lang]}
                <EmailLink email="support@4solfy.com" />
            </Typography>
        </div>
    )
}


const EmailLink = ({ email }) => {
    const openGmail = (email) => {
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(email)}`;
        window.open(gmailUrl, '_blank');
    };

    const copyToClipboard = (text) => {
        const tempInput = document.createElement('input');
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        alert('The email address has been copied to your clipboard. Please paste it into your email client.');
    };

    const handleClick = (e) => {
        e.preventDefault();
        const useGmail = window.confirm('Would you like to open Gmail to send an email to ' + email + '? Click "Cancel" to copy the email address instead.');
        if (useGmail) {
            openGmail(email);
        } else {
            copyToClipboard(email);
        }
    };

    return (
        <a
            href="#"
            onClick={handleClick}
        >
            {email}
        </a>
    );
};

export default ContactUs;
