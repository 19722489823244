import React, { useState, useEffect, useRef } from "react";
import { axiosInstance,  } from './axios_instance';
import Sidebar from './sidebar';
import PracticeRecordingsSelect from './practice_recordings_select';
import ScoreSyricsSelect from './score_lyrics_select';
import ClassHomeSelect from './class_home_select';
import ToleranceSetting from './tolerance_setting';
import RenderLyricsHtml from './render_lyrics';
import RunsHistory from './runs_history';
import ScorePage from './score_page';
import ZoomSelection from './zoom_selection';
import FullScreenButton from './fullscreen';
import Tabstext from './tabstext';
import {render_lyrics_docx, PutSetFeedbackCallback, } from './exercise_utils';
import { Grid, } from '@mui/material';
import { ExerciseContainer, ForwardArrowButton } from './customized_components';

function Exercise(props) {
    const SolfegeLevels = props.SolfegeLevels;
    const TheoryCourses = props.TheoryCourses;
    const SongClasses = props.SongClasses;

    const [context, setContext] = useState({});
    const [exe, setExe] = useState(null);
    const [nextExe, setNext] = useState(null);
    const [prevExe, setPrev] = useState(null);
    const [inClass, setInClass] = useState(false);
    const inClassChangeCallback = (inClass) => {
        setInClass(inClass);
    };
    const [pk, setPk] = useState(null);
    useEffect(() => {
        if (props.content == 'solfege' || props.content == 'theory' || props.content=='songs') {
            setPk(props.content)
        //    if (props.content=='solfege')
        //        setExerciseView('score')
        }
    }, [props.content]);

    const setPkCallback = (val) => {
        setPk(val);
    }
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [runs_dict, setRunsDict] = useState([]);

    const [exerciseView, setExerciseView] = useState('score');
    const setExerciseViewCallback = (val) => {
        setExerciseView(val);
    };

    // practice / feedback / runs_table  display state machine
    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const setFeedbackDisplayCallback = (val, data) => {
        setFeedbackDisplay(val);
        if (val) {
            if (data || feedbackData) {
                if(data) {
                    setMusicScore(data.score);
                    setFeedbackData(data);
                } else {
                    setMusicScore(feedbackData.score);
                }
                setExerciseView('score');
            }
            else
                setExerciseView('runs_table');
        }
        else {
            setExerciseView('score');
            setMusicScore(exe.score);
        }
    };
    PutSetFeedbackCallback(setFeedbackDisplayCallback);     // invoked from exercise_utils

    const setMusicScoreCallback = (data) => {
        setMusicScore(data.score);
    }

    useEffect(() => {
        if(pk) {
            axiosInstance.get('/api/exercise/',{'params':{'pk':pk, 'studentUser':null, 'lang':props.lang}})
                .then((response) => {
                    if (response.data.context) {
                        setContext(response.data.context);
                        setExe(response.data.exe);
                        setNext(response.data.next);
                        setPrev(response.data.prev);
                        setMusicScore(response.data.exe.score);
                        setFeedbackDisplay(false);
                        setExerciseView('score');
                        setRunsDict(response.data.runs_dict);
                        setFeedbackData(null);
                    }
                });
        }
    }, [pk,props.asStudent]); // retrieve all exercise data when pk changes

    useEffect(() => {
        if(pk) {
            axiosInstance.get('/api/exercise/',{'params':{'pk':pk, 'studentUser':null}})
                .then((response) => {
                    if (response.data.context) {
                        setContext(response.data.context);
                        setRunsDict(response.data.runs_dict);
                    }
            });
        }
    }, [feedbackData]); // retrieve updated runs_dict after a new feedback

    const docxRef = useRef(null);
    useEffect(() => {
        if (context && Object.keys(context).length > 0 && !feedbackDisplay) {
            if (exerciseView=='score') {
                setMusicScore(exe.score);
            }
            else if (exerciseView=='lyrics' && context.lyrics_file_url && context.lyrics_file_url.includes('docx')) {
                render_lyrics_docx(context.lyrics_file_url,docxRef.current);
            }
        }
    }, [exe,exerciseView]);

    useEffect(() => {
        if (props.asStudent)
            setInClass(false)
    }, [props.asStudent, props.passedRectest]);

    if (context==null || Object.keys(context).length === 0 || exe==null) {
        return null;
    }
    const runsTableWidth = props.windowSize[0]-500 ;
    const sidebarWidth = !inClass ? (context.category == 'solfege' ? 230 : 210) : 0;
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    const lyric_dir = exe.lyric=='Hebrew' ? 'rtl' : 'ltr';

    return (
        <Grid
            style={{ flex: 1, display: props.display ? 'grid' : 'none', gridTemplateColumns: !inClass ? `${sidebarWidth}px 1fr` : 'none' }} >
            {!inClass &&
                <Sidebar category={context.category} lang={props.lang}
                    exe={exe} setPkCallback={setPkCallback}
                    asStudent={props.asStudent}
                    firstUnpassedLevel={context.firstUnpassedLevel}
                    firstUnpassedLesson={context.firstUnpassedLesson}
                    firstIncompleteExercise={context.firstIncompleteExercise}
                    firstIncompleteExeLastLesson={context.firstIncompleteExeLastLesson}
                    lastLessonInLevel={context.lastLessonInLevel}
                    firstExerciseLastLesson={context.firstExerciseLastLesson}
                    SolfegeLevels={SolfegeLevels} TheoryCourses={TheoryCourses} SongClasses={SongClasses}
                    solfegesPassStatus={context.solfegesPassStatus}
                    user_id={context.user_id} user_group_id={context.user_group_id}
                    />
            }
            <ExerciseContainer  >
                <div >
                    {exerciseView!=='lyrics' &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <PracticeRecordingsSelect context={context} lang={props.lang}
                                    feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                                />
                                {exerciseView != 'runs_table' && feedbackDisplay && feedbackData &&
                                    <AllRunsSelect lang={props.lang}
                                        exerciseView={exerciseView} setExerciseViewCallback={setExerciseViewCallback}
                                    />
                                }
                            </div>
                            <ToleranceSetting
                                lang={props.lang}
                                feedbackDisplay={feedbackDisplay}
                                pitchTolerance={props.pitchTolerance}
                                pitchToleranceRange={props.pitchToleranceRange}
                                tooltipText={Tabstext.SettingsToleranceTooltip[props.lang]}
                                display={exerciseView=='score' && !inClass && !feedbackDisplay}
                                />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                {Boolean(context.isteacher) &&  !props.asStudent && !feedbackDisplay &&
                                    <ClassHomeSelect
                                        context={context} lang={props.lang}
                                        inClass={inClass} inClassChangeCallback={inClassChangeCallback}
                                        passedRectest={props.passedRectest}
                                        />
                                }
                                <ZoomSelection scale={props.scale} setScaleCallback={props.setScaleCallback} />
                                <FullScreenButton lang={props.lang} />
                            </div>

                        </div>
                    }
                    {exerciseView=='lyrics' &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'right'}}>
                            <ScoreSyricsSelect
                                context={context}
                                lang={props.lang}
                                exerciseView={exerciseView}
                                setExerciseViewCallback={setExerciseViewCallback}
                            />
                        </div>
                        {context.lyrics_file_url.includes('html') &&
                            <RenderLyricsHtml lyrics_file_url = {context.lyrics_file_url} dir={lyric_dir} />
                        }
                        <div id="docx-container" ref={docxRef} dir={lyric_dir}></div>
                    </>
                    }
                </div>
                <RunsHistory exe={exe} lang={props.lang}
                    runs_dict={runs_dict}
                    setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    setExerciseViewCallback={setExerciseViewCallback}
                    windowSize={props.windowSize}
                    runsTableWidth={runsTableWidth}
                    display={exerciseView=='runs_table' && props.display}
                />
                <ScorePage context={context} lang={props.lang}
                    exe={exe} nextExe={nextExe} prevExe={prevExe}
                    setPkCallback={setPkCallback}
                    nextEnabled={context.next_enabled || (context.category==='solfege' && !props.asStudent)}
                    prevEnabled={context.prev_enabled}
                    musicScore={musicScore}
                    inExercisePage={true} feedbackData={feedbackData}
                    inClass = {inClass}
                    exerciseView={exerciseView} setExerciseViewCallback={setExerciseViewCallback}
                    feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                    pitchTolerance={props.pitchTolerance}
                    pitchToleranceRange={props.pitchToleranceRange}
                    setMusicScoreCallback={setMusicScoreCallback}
                    sidebarWidth = {sidebarWidth}
                    windowSize={props.windowSize}
                    display={exerciseView=='score' && props.display}
                />
            </ExerciseContainer>
        </Grid>
    )
}

function AllRunsSelect(props) {
    const lang = props.lang;
    const handleButtonClick = () => {
        props.setExerciseViewCallback('runs_table');
    };
    return (
        <ForwardArrowButton onClick={handleButtonClick} >
            {Tabstext.all_recordings_tab[lang]}
        </ForwardArrowButton>
    )
}


export default Exercise;

//                         <RenderLyricsHtml lyrics_file_url = {context.lyrics_file_url} dir={lyric_dir} />
