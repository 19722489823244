import React from 'react'

const ExerciseDescriptions_he = {
    'Level1_Lesson01_E01_sol4': 'הכרת הצליל והתו "סול"; תו של רבע והפסקה של רבע',
    'Level1_Lesson01_E02_sol4': 'רבעים: צלילים, תווים והפסקות. תבניות ריתמיות',
    'Level1_Lesson01_R01_sol4': 'רבעים: צלילים, תווים והפסקות. תבניות ריתמיות',
    'Level1_Lesson01_R02_sol4_v1': 'החמשה המוזיקלית – חמישה קווים (שורות) היוצרים 4 רווחים',

    'Level1_Lesson02_E01_mi4': 'הכרת הצליל והתו "מי"; תו של רבע והפסקה של רבע',
    'Level1_Lesson02_E02_mi4': 'רבעים: צלילים, תווים והפסקות. תבניות ריתמיות',
    'Level1_Lesson02_R01_mi4': 'רבעים: צלילים, תווים והפסקות. תבניות ריתמיות',
    'Level1_Lesson02_R02_mi4': 'סמל מפתח "סול". המקום שממנו מתחיל מפתח סול הוא מקום התו "סול". התו סול על הקו השני, התו מי על הקו הראשון',

    'Level1_Lesson03_E01_sol4-mi4': 'שילוב של "סול" ו"מי" בתבניות ריתמיות שונות של תווים והפסקות של רבע; גבהים שונים: סול ומי',
    'Level1_Lesson03_E02_sol4-mi4': 'רבעים: צלילים, תווים והפסקות. תבניות ריתמיות; גבהים שונים: "סול" ו"מי". סמלי תווי המוזיקה כתובים בחמשה',
    'Level1_Lesson03_R01_sol4-mi4': 'רבעים: צלילים, תווים והפסקות. תבניות ריתמיות; גבהים שונים: סול ומי',
    'Level1_Lesson03_R02_sol4-mi4': 'תיבה מוזיקלית. בין כל זוג קוים (סורגים), יש שני רבעים. זהו המשקל של שני רבעים- 2/4',

    'Level1_Lesson04_E01_sol4-mi4': '(f-p) דינמיקה (עוצמה): פורטה פיאנו ',
    'Level1_Lesson04_E02_sol4-mi4': '(f-p) רבעים: צלילים, תווים והפסקות. תבניות ריתמיות; גבהים שונים: "סול" ו"מי"; סימני דינמיקה: פורטה פיאנו ',
    'Level1_Lesson04_R01_sol4-mi4_v1': '(f-p) רבעים: צלילים, תווים והפסקות. תבניות ריתמיות; גבהים שונים: "סול" ו"מי"; סימני דינמיקה: פורטה פיאנו ',
    'Level1_Lesson04_R02_sol4-mi4_v1': '(f-p) רבעים: צלילים, תווים והפסקות. תבניות ריתמיות; גבהים שונים: "סול" ו"מי"; סימני דינמיקה: פורטה פיאנו ',

    'Level1_Lesson05_E01_sol4-mi4_v1': 'לגאטו, קשת המחברת שני תווים בעלי שם זהה ומבוצעים כתו יחיד. בתרגיל הזה, הקשת מחברת שני רבעים, ומייצרת תו של חצי (מתוך תו שלם)',
    'Level1_Lesson05_E02_sol4-mi4_v1': 'רבעים: צלילים, תווים והפסקות. קשת המחברת שני רבעים, ומייצרת תו של חצי. פורטה ופיאנו',
    'Level1_Lesson05_R01_sol4-mi4_v1': 'רבעים: צלילים, תווים והפסקות .f-p',
    'Level1_Lesson05_R02_sol4-mi4_v1': 'רבעים: צלילים, תווים והפסקות .f-p',

    'Level1_Lesson06_E01_sol4-mi4': 'סימן להפסקה של חצי, שווה לשני רבעים (2/4). צלילים, תווים והפסקות .f-p',
    'Level1_Lesson06_E02_sol4-mi4': 'רבעים וחצאים: צלילים, תווים והפסקות .f-p',
    'Level1_Lesson06_R01_sol4-mi4': 'רבעים וחצאים: צלילים, תווים והפסקות .f-p',
    'Level1_Lesson06_R02_sol4-mi4': 'תרגיל של 32 תיבות של שני רבעים. תווים והפסקות של רבע; תווים של חצי; סול ומי .f-p',

    'Level1_Lesson07_E01_sol4-mi4': 'סקירה: תווים וצלילים של רבעים, הפסקות של רבעים; תבניות ריתמיות; תרגול סולפג\'ם במצבים שונים: 1. החלפת ההפסקות ב"צלילי תיפוף גוף" (מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים); 2. שירה בקנון: חיקוי אחרי 2 או 4 תיבות ',
    'Level1_Lesson07_E02_sol4-mi4': 'סקירה: תווים וצלילים של רבעים, הפסקות של רבעים; תבניות ריתמיות; תרגול סולפג\'ם במצבים שונים: 1. החלפת ההפסקות ב"צלילי תיפוף גוף" (מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים); 2. שירה בקנון: חיקוי אחרי 2 או 4 תיבות ',
    'Level1_Lesson07_R01_sol4-mi4': 'סקירה: תווים וצלילים של רבעים, הפסקות של רבעים; תבניות ריתמיות; תרגול סולפג\'ם במצבים שונים: 1. החלפת ההפסקות ב"צלילי תיפוף גוף" (מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים); 2. שירה בקנון: חיקוי אחרי 1 או 3 תיבות',
    'Level1_Lesson07_R02_sol4-mi4': 'סקירה: תווים וצלילים של רבעים, הפסקות של רבעים; תבניות ריתמיות; תרגול סולפג\'ם במצבים שונים: 1. החלפת ההפסקות ב"צלילי תיפוף גוף" (מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים); 2. שירה בקנון: חיקוי אחרי 1 או 3 תיבות',

    'Level1_Lesson08_E01_mi4': ' סינקופה: שינוי הזרימה הריתמית הרגילה על ידי סימן לגאטו, המחברת את הרבע השני של תיבה אחת עם הרבע הראשון של התיבה הבאה',
    'Level1_Lesson08_E02_mi4': 'תרגול סינקופה: שתי קשתות לגאטו סמוכים המחברים 3 רבעים עם אותו השם, יוצרים ערך חדש, שווה לשלושה רבעים, או ערך של חצי ורבע (מתו שלם)',
    'Level1_Lesson08_R01_sol4-mi4': 'רבעים וחצאים: צלילים, תווים, והפסקות של רבע. קשת (לגאטו), סינקופה. 3 רבעים עם אותו השם (חצי + רבע)',
    'Level1_Lesson08_R02_sol4-mi4': 'רבעים וחצאים: צלילים, תווים, והפסקות של רבע. קשת (לגאטו), סינקופה. 3 רבעים עם אותו השם (חצי + רבע)',

    'Level1_Lesson09_E01_sol4-mi4': ' רבעים: צלילים, תווים והפסקות ',
    'Level1_Lesson09_E02_sol4-mi4': ' רבעים: צלילים, תווים והפסקות, לגטו, סינקופה ',
    'Level1_Lesson09_R01_sol4-mi4': ' רבעים: צלילים, תווים והפסקות. פעימה מודגשת והפסקה',
    'Level1_Lesson09_R02_sol4-mi4': ' רצף של הפסקה ופעימה לא מודגשת, יוצרים בעצם רצף הפוך של פעימה מודגשת והפסקה (הפעימה הלא מודגשת, הופכת להיות מודגשת)',

    'Level1_Lesson10_E01_do4_v1': 'צליל ותו חדש: "דו". תווים והפסקות של רבע; תווים של חצי',
    'Level1_Lesson10_E02_do4': 'רבעים וחצאים: צלילים, תווים והפסקות ',
    'Level1_Lesson10_R01_mi4-do4': 'תבניות ריתמיות עם "מי" "דו" ולגטו ',
    'Level1_Lesson10_R02_mi4-do4': 'תבניות ריתמיות עם "מי" "דו" ולגטו ',

    'Level1_Lesson11_E01_sol4-mi4': 'תבניות ריתמיות עם סול ו-מי ',
    'Level1_Lesson11_E02_mi4-do4': 'תבניות ריתמיות עם מי ו-דו ',
    'Level1_Lesson11_R01_sol4-mi4-do4': 'תבניות ריתמיות עם "סול", "מי" ו"דו"; שירה ב"קנון": החיקוי אחרי 4 תיבות',
    'Level1_Lesson11_R02_sol4-mi4-do4': 'תבניות ריתמיות עם סול, מי, דו, ; ארפג\'ו, שירה בקנון: החיקוי אחרי 4 תיבות',

    'Level1_Lesson12_E01_sol4-mi4-do4': 'תבניות ריתמיות ',
    'Level1_Lesson12_E02_sol4-mi4-do4': 'תבניות ריתמיות ',
    'Level1_Lesson12_R01_sol4-mi4-do4': 'תבניות ריתמיות. קשת המחברת תו של חצי ורבע; שירה בקאנון: חיקוי לאחר 8 תיבות',
    'Level1_Lesson12_R02_sol4-mi4-do4': 'תבניות ריתמיות. קשת המחברת תו של חצי ורבע; שירה בקאנון: חיקוי לאחר 16 תיבות',

    'Level1_Lesson13_E01_sol4-mi4-do4': 'תבניות ריתמיות. שירה בקאנון: חיקוי לאחר 8 תיבות',
    'Level1_Lesson13_E02_sol4-mi4-do4': 'תבניות ריתמיות. קשתות שמחברות שלושה רבעים ברצף. שירה בקאנון: חיקוי לאחר 8 תיבות',
    'Level1_Lesson13_R01_sol4-mi4-do4': 'תבניות ריתמיות. קשתות שמחברות תווים של חצי ורבע; שלושה רבעים ברצף; שירה בקאנון: חיקוי לאחר 8 תיבות',
    'Level1_Lesson13_R02_sol4-mi4-do4': 'תבניות ריתמיות; שירה בקאנון: חיקוי לאחר 8 תיבות',

    'Level1_Lesson14_E01_re4_v2': 'צליל ותו חדש: "רה"; תבניות ריתמיות ',
    'Level1_Lesson14_E02_mi4-re4-do4_v1': 'צליל ותו חדש: "רה" - המשך; תבניות ריתמיות ',
    'Level1_Lesson14_R01_mi4-re4-do4_v1': ' אקדם: תו/צליל המוביל אל הפעמה המודגשת. נקרא גם קדמה או אנקרוזיס ',
    'Level1_Lesson14_R02_mi4-re4-do4': ' אקדם: תו/צליל המוביל אל הפעמה המודגשת. נקרא גם קדמה או אנקרוזיס ',

    'Level1_Lesson15_E01_sol4-mi4-re4-do4': 'תבניות ריתמיות, סינקופה',
    'Level1_Lesson15_E02_sol4-mi4-re4-do4': 'תבניות ריתמיות, סינקופה',
    'Level1_Lesson15_R01_sol4-mi4-re4-do4': 'תבניות ריתמיות ,f – p',
    'Level1_Lesson15_R02_sol4-mi4-re4-do4': 'תבניות ריתמיות ,f – p',

    'Level1_Lesson16_E01_sol4-mi4-re4-do4': 'תבניות ריתמיות ,f – p',
    'Level1_Lesson16_E02_sol4-mi4-re4-do4': 'תבניות ריתמיות ,f – p',
    'Level1_Lesson16_R01_sol4-mi4-re4-do4': 'תבניות ריתמיות ,f – p',
    'Level1_Lesson16_R02_sol4-mi4-re4-do4': 'אוף-ביט ,תבניות ריתמיות',

    'Level1_Lesson17_E01_sol4-mi4-re4-do4': 'תבניות ריתמיות ,f – p',
    'Level1_Lesson17_E02_sol4-mi4-re4-do4': 'משכים חדשים: שמיניות; שתי שמיניות שוות לרבע; השמיניות מופיעות במחובר; תבניות ריתמיות',
    'Level1_Lesson17_R01_sol4-mi4-re4-do4': 'חצאים, רבעים, שמיניות ;תבניות ריתמיות ',
    'Level1_Lesson17_R02_sol4-mi4-re4-do4': 'חצאים, רבעים, שמיניות ;תבניות ריתמיות ',

    'Level1_Lesson18_E01_fa4_v2': 'תו וצליל חדש: "פה", תבניות ריתמיות ',
    'Level1_Lesson18_E02_fa4_v1'	: 'תו וצליל חדש: "פה", תבניות ריתמיות ',
    'Level1_Lesson18_R01_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות עם חצאים ורבעים ',
    'Level1_Lesson18_R02_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות עם רבעים ושמיניות ',

    'Level1_Lesson19_E01_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות עם רבעים ',
    'Level1_Lesson19_E02_sol4--do4'           :	'תבניות ריתמיות עם שמיניות',
    'Level1_Lesson19_R01_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות עם רבעים ושמיניות ',
    'Level1_Lesson19_R02_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות עם רבעים ושמיניות ',

    'Level1_Lesson20_E01_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות, חצאים ורבעים, סינקופה וקשת ',
    'Level1_Lesson20_E02_sol4-fa4-mi4-re4-do4':	'סולמות עולים; תבניות ריתמיות עם רבעים ',
    'Level1_Lesson20_R01_sol4-fa4-mi4-re4-do4':	'תבניות ריתמיות, חצאים ורבעים; הכנה לשיר "יונתן הקטן" - שיר ילדים עממי',
    'Level1_Lesson20_R02_sol4-fa4-mi4-re4-do4':	'שיר ילדים "יונתן הקטן", תבניות ריתמיות, חצאים ורבעים ',

    'Level1_Lesson21_E01_sol4-fa4-mi4-re4-do4': 'מרווחים: מרווח הוא ההבדל בגובה הצליל בין שני תווים וצלילים. דוגמאות: מדו לדו, המרווח נקרא פרימה. כנ״ל, רה-רה, מי-מי, פה-פה, סול-סול',
    'Level1_Lesson21_E02_sol4-fa4-mi4-re4-do4': 'מרווחים של פרימה: דו-דו, רה-רה, מי-מי, פה-פה. לאחר ביצוע מוצלח של תרגיל זה, נסו לזהות ולשיר את מרווחי ה"פרימה". בהצלחה',
    'Level1_Lesson21_R01_sol4-fa4-mi4-re4-do4': 'מרווח של סקונדה (2 צעדים) הוא ההבדל בגובה הצליל בין שני תווים סמוכים. דוגמאות: דו-רה, רה-דו, רה-מי, מי-רה, מי-פה, פה-מי, פה-סול, סול-פה',
    'Level1_Lesson21_R02_sol4-fa4-mi4-re4-do4': 'מרווחים של סקונדה: דו-רה, רה-דו, רה-מי, מי-רה, מי-פה, פה-מי, פה-סול, סול פה. לאחר ביצוע מוצלח של סולפג\' זה נסו לזהות ולשיר את כל המרווחים של סקונדה. בהצלחה',

    'Level1_Lesson22_E01_sol4-fa4-mi4-re4-do4': 'מרווחים של טרצה (3 צעדים): דו-מי, מי-דו. רה-פה, פה-רה. מי-סול, סול-מי',
    'Level1_Lesson22_E02_sol4-fa4-mi4-re4-do4': 'מרווחים. לאחר ביצוע מוצלח של סולפג\' זה, נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson22_R01_sol4-fa4-mi4-re4-do4': 'מרווחים. לאחר ביצוע מוצלח של סולפג\' זה, נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson22_R02_sol4-fa4-mi4-re4-do4': 'מרווחים. לאחר ביצוע מוצלח של סולפג\' זה, נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',

    'Level1_Lesson23_E01_sol4--do4'           : 'מרווחים. לאחר ביצוע מוצלח של סולפג\' זה, נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson23_E02_sol4-fa4-mi4-re4-do4': 'מרווחים. לאחר ביצוע מוצלח של סולפגי\' זה, נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson23_R01_sol4-fa4-mi4-re4-do4': 'השיר "בארבוי" - שיר ילדים רומני עממי ברבעים. ליווי תזמורתי מאת המלחין מיכאל דוליצקי. אחרי ביצוע מוצלח של שיר זה נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson23_R02_sol4-fa4-mi4-re4-do4': 'השיר "בארבוי"- שיר ילדים רומני עממי בשמיניות. אחרי ביצוע מוצלח של שיר זה נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',

    'Level1_Lesson24_E01_sol4-fa4-mi4-re4-do4': 'תבניות ריתמיות, חצאים, רבעים והפסקות. לאחר ביצוע מוצלח של סולפגי\' זה נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson24_E02_sol4--do4'           : 'תבניות ריתמיות, חצאים, רבעים והפסקות. לאחר ביצוע מוצלח של סולפג\' זה נסו לזהות ולשיר מרווחים של פרימה, סקונדה וטרצה. בהצלחה',
    'Level1_Lesson24_R01_sol4-fa4-mi4-re4-do4': 'שיר ילדים רומני "חבורת השובבים". ליווי תזמורתי מאת המלחין מיכאל דוליצקי. מרווחים של קוורטה (4 צעדים):דו-פה, פה-דו, רה-סול, סול-רה. לאחר ביצוע מוצלח של סולפג\' זה נסו לזהות ולשיר את המרווחים של קוורטה. חיזרו לשיעור 22 כדי לתרגל מרווחים ',
    'Level1_Lesson24_R02_sol4-fa4-mi4-re4-do4': 'שיר ילדים רומני "חבורת השובבים" עם רבעים ושמיניות. מרווחים של קוורטה: דו-פה, פה-דו. רה-סול, סול-רה. אחרי ביצוע מוצלח של סולפג\' זה, נסו לזהות ולשיר את המרווחים של קוורטה. חיזרו לשיעור 22 כדי לתרגל מרווחים',

    'Level1_Lesson25_E01_sol4--do4': 'תבניות ריתמיות, רבעים ושמיניות',
    'Level1_Lesson25_E02_sol4--do4': 'תבניות ריתמיות, רבעים ושמיניות, הפסקות של רבעים. קשת המחברת רבע ושמינית באותו הגובה מייצרת תו השווה לשלוש שמיניות',
    'Level1_Lesson25_R01_sol4-fa4-mi4-re4-do4'   : 'השיר "טחנת הקמח דורוחוי" - שיר ילדים עממי רומני. תבניות ריתמיות, רבעים והפסקות של רבעים. חיזרו לשיעור 22 כדי לתרגל מרווחי',
    'Level1_Lesson25_R02_sol4--do4': 'השיר "טחנת הקמח דורוחוי" - שיר ילדים עממי רומני. תבניות ריתמיות, תווים והפסקות של שמיניות. חיזרו לשיעור 22 כדי לתרגל מרווחי',

    'Level1_Lesson26_E01_sol4-fa4-mi4-re4-do4_v1': 'תבניות ריתמיות, תווים והפסקות, קשת וסינקופה',
    'Level1_Lesson26_E02_sol4-fa4-mi4-re4-do4'   : 'מרווחים של קווינטה (5 צעדים): דו-סול, סול-דו. רבעים ושמיניות. הכנות לשיר: ״כשהקדושים צועדים״. אחרי ביצוע מוצלח של סולפג\' זה, נסו לזהות ולשיר את המרווחים של קווינטה ',
    'Level1_Lesson26_R01_sol4-fa4-mi4-re4-do4_v1': 'תווים של רבעים שמיניות והפסקות. קשת בין רבע לשמינית וסינקופה עם שמיניות. ״קום בחור עצל״ - שיר ילדים עממי. אחרי ביצוע מוצלח, נסו לזהות, לשיר וללמוד מרווחים הקיימים בשיר זה. חיזרו לשיעור 22 כדי לתרגל מרווחי',
    'Level1_Lesson26_R02_sol4-fa4-mi4-re4-do4'   : 'תווים של חצאים, רבעים, שמיניות והפסקות. אקדם וסינקופה והשיר ״כשהקדושים צועדים״. אחרי ביצוע מוצלח, נסו לזהות, לשיר וללמוד מרווחים הקיימים בשיר זה. חיזרו לשיעור 22 כדי לתרגל מרווחי',

    'Level2_Lesson01_E01_sol4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התו "סול" בתבניות ריתמיות שונות',
    'Level2_Lesson01_E02_sol4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התו "סול" בתבניות ריתמיות שונות',
    'Level2_Lesson01_R01_sol4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התו "סול" בתבניות ריתמיות שונות',
    'Level2_Lesson01_R02_sol4': 'משקל של שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התו "סול" בתבניות ריתמיות שונות',

    'Level2_Lesson02_E01_sol4-mi4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התוים "סול" ו-"מי" בתבניות ריתמיות שונות',
    'Level2_Lesson02_E02_sol4-mi4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התוים "סול" ו-"מי" בתבניות ריתמיות שונות',
    'Level2_Lesson02_R01_sol4-mi4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התוים "סול" ו-"מי" בתבניות ריתמיות שונות',
    'Level2_Lesson02_R02_sol4-mi4': 'משקל שלושה רבעים: פעמה מוטעמת ולאחריה שתי פעמות לא מוטעמות. התוים "סול" ו-"מי" בתבניות ריתמיות שונות',

    'Level2_Lesson03_E01_do4_v1': 'משקל שלושה רבעים. התו "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson03_E02_do4_v1': 'משקל שלושה רבעים. התו "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson03_R01_sol4-mi4-do4': 'משקל שלושה רבעים. התוים "סול", "מי", "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson03_R02_sol4-mi4-do4': 'משקל שלושה רבעים. התוים "סול", "מי", "דו" בתבניות ריתמיות שונות',

    'Level2_Lesson04_E01_re4_v1': 'משקל שלושה רבעים. התו "רה" בתבניות ריתמיות שונות',
    'Level2_Lesson04_E02_re4_v1': 'משקל שלושה רבעים. התו "רה" בתבניות ריתמיות שונות',
    'Level2_Lesson04_R01_sol4-mi4-re4-do4': 'משקל שלושה רבעים. התוים "סול", "מי", "רה","דו" בתבניות ריתמיות שונות',
    'Level2_Lesson04_R02_sol4-mi4-re4-do4': 'משקל שלושה רבעים. התוים "סול", "מי", "רה", "דו" בתבניות ריתמיות שונות',

    'Level2_Lesson05_E01_fa4': 'משקל שלושה רבעים. התו "פה" בתבניות ריתמיות שונות',
    'Level2_Lesson05_E02_fa4': 'משקל שלושה רבעים. התו "פה" בתבניות ריתמיות שונות',
    'Level2_Lesson05_R01_sol4-fa4-mi4-re4-do4': 'משקל שלושה רבעים, התוים "סול", "פה", "מי", "רה", "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson05_R02_sol4-fa4-mi4-re4-do4': 'משקל שלושה רבעים, התוים "סול", "פה", "מי", "רה", "דו" בתבניות ריתמיות שונות',

    'Level2_Lesson06_E01_sol4-fa4-mi4-re4-do4': 'משקל שלושה רבעים, "סול", "פה", "מי", "רה", "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson06_E02_sol4-fa4-mi4-re4-do4': 'תו של חצי מנוקד שווה שלושה רבעים. התוים "סול", "פה", "מי", "רה", "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson06_R01_sol4--do4': 'תו של חצי מנוקד שווה שלושה רבעים. "סול", "פה", "מי", "רה", "דו" בתבניות ריתמיות שונות',
    'Level2_Lesson06_R02_sol4--do4': 'משקל שלושה רבעים. "סול", "פה", "מי", "רה", "דו" בתבניות ריתמיות שונות',

    'Level2_Lesson07_E01_sol4--do4': 'משקל שלושה רבעים, התוים/הצלילים בין סול ו-דו בתבניות ריתמיות שונות. הפסקה של שמינית',
    'Level2_Lesson07_E02_sol4--do4': 'משקל שלושה רבעים, התוים/הצלילים בין סול ו-דו בתבניות ריתמיות שונות',
    'Level2_Lesson07_R01_sol4--do4': 'משקל שלושה רבעים, התוים/הצלילים בין סול ו-דו בתבניות ריתמיות שונות',
    'Level2_Lesson07_R02_sol4--do4': 'משקל שלושה רבעים, התוים/הצלילים בין סול ו-דו בתבניות ריתמיות שונות. ארפג\'ו',

    'Level2_Lesson08_E01_la4--la4_v2': 'הכרת הצליל והתו "לה"; משקל של שני רבעים',
    'Level2_Lesson08_E02_la4--la4_v2': 'הכרת הצליל והתו "לה"; משקל של שלושה רבעים',
    'Level2_Lesson08_R01_la4--do4': 'השיר "אי-בי-סי". משקל של שני רבעים, התוים/הצלילים בין לה ו-דו ברבעים',
    'Level2_Lesson08_R02_la4--do4': 'השיר "אי-בי-סי". משקל של שלושה רבעים, התוים/הצלילים בין לה ו-דו בשמיניות',

    'Level2_Lesson09_E01_la4--do4': 'משקל שלושה רבעים, התוים/הצלילים בין לה ו-דו בתבניות ריתמיות שונות',
    'Level2_Lesson09_E02_la4--do4_v1': 'משקל שלושה רבעים, התוים/הצלילים בין לה ו-דו בתבניות ריתמיות שונות',
    'Level2_Lesson09_R01_la4--do4': 'השיר "אי-בי-סי". משקל שלושה רבעים, וריאציה-1',
    'Level2_Lesson09_R02_la4--do4': 'השיר "אי-בי-סי". משקל שלושה רבעים, וריאציה-2',

    'Level2_Lesson10_E01_si3_v1': 'הכרת הצליל והתו "סי"; תבניות ריתמיות שונות; משקל שלושה רבעים',
    'Level2_Lesson10_E02_si3': 'הכרת הצליל והתו "סי"; תבניות ריתמיות שונות; משקל שלושה רבעי. רבע מנוקד ושמינית',
    'Level2_Lesson10_R01_la4--si3': 'תבניות ריתמיות שונות במשקל שלושה רבעים',
    'Level2_Lesson10_R02_la4--si3': 'תבניות ריתמיות שונות במשקל שלושה רבעים',

    'Level2_Lesson11_E01_la4--si3': 'תבניות ריתמיות שונות במשקל שלושה רבעים. רבע מנוקד ושמינית',
    'Level2_Lesson11_E02_la4--do4': 'תבניות ריתמיות שונות במשקל של שלושה רבעים. רבע מנוקד ושמינית',
    'Level2_Lesson11_R01_la4--si3': 'תבניות ריתמיות שונות במשקל של שלושה רבעים. רבע מנוקד ושמינית והפסקה של שמינית',
    'Level2_Lesson11_R02_la4--si3': 'תבניות ריתמיות שונות במשקל שני רבעים. "אחינו יעקב", שיר ילדים צרפתי. שירה בקנון: חיקוי אחרי 4 תיבות',

    'Level2_Lesson12_E01_la3_v1': 'הכרת הצליל והתו "לה3"; משקל של שני רבעים',
    'Level2_Lesson12_E02_la3_v1': 'הכרת הצליל והתו "לה3"; משקל של שלושה רבעים',
    'Level2_Lesson12_R01_mi4--la3_v1': 'תבניות ריתמיות שונות במשקל של שלושה רבעים. שיר עממי רומני',
    'Level2_Lesson12_R02_mi4--la3_v1': 'תבניות ריתמיות שונות במשקל של שני רבעים. שיר עממי רומני',

    'Level2_Lesson13_E01_la4--la3': 'מרווחים (היחס בגובה בין שני תווים/צלילים): אוקטבה, ספטימה, סקסטה',
    'Level2_Lesson13_E02_la4--la3': 'מרווחים (היחס בגובה בין שני תווים/צלילים): אוקטבה, ספטימה, סקסטה',
    'Level2_Lesson13_R01_la4--la3': 'שיר עם רוסי. מרווחים - המשך. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson13_R02_la4--la3': 'שיר עם רוסי. מרווחים - המשך. נתחו, שירו וציינו שמות המרווחים',

    'Level2_Lesson14_E01_sol4--la3': 'סולמות דו מג\'ור ולה מינור. בתורת המוזיקה "סולם" הוא רצף מדורג של תווים, צלילים או מרווחים המחלקים אוקטבה',
    'Level2_Lesson14_E02_sol4--si3': 'סולמות דו מג\'ור ולה מינור. בתורת המוזיקה "סולם" הוא רצף מדורג של תווים, צלילים או מרווחים המחלקים אוקטבה',
    'Level2_Lesson14_R01_mi4--la3_v2': 'ארפג\'ים בסולם לה מינור. הארפג\'ו מורכב מלפחות שני מרווחים רצופים של טרצות. נתחו, שירו וציינו את שמות המרווחים ',
    'Level2_Lesson14_R02_la4--la3': 'ארפג\'ים בסולם לה מינור. הארפג\'יו מורכב מלפחות שני מרווחים רצופים של טרצות. שיר רומני - "אני צופה מתוך דופטנה". נתחו, שירו וציינו את שמות המרווחים',

    'Level2_Lesson15_E01_sol4--fad4--re4': 'סימני היתק. הכרת הצליל והתו "פה דיאז"; תבניות רתמיות',
    'Level2_Lesson15_E02_fad4--fa4': 'סימני היתק - הכרת הצליל והתו "פה דיאז" - המשך; "פה בקר", תבניות ריתמיות',
    'Level2_Lesson15_R01_la4-fa4-fad4-re4': 'פה בקר בסולם רה מינור ופה דיאז בסולם רה מז\'ור. תבניות ריתמיות',
    'Level2_Lesson15_R02_fad4--re4': 'פה דיאז בסולם רה מז\'ור. תבניות ריתמיות',

    'Level2_Lesson16_E01_la4--fad4--re4': 'תרגול עם התו והצליל "פה דיאז"; נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson16_E02_la4--fad4--re4': 'תרגול עם התו והצליל "פה דיאז"; נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson16_R01_la4--fad4--re4': 'תרגול עם התו והצליל "פה דיאז"; נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson16_R02_la4--fad4--re4': 'השיר "יונתן הקטן" בסולם רה מז\'ור. נתחו, שירו וציינו שמות המרווחים',

    'Level2_Lesson17_E01_la4--fad4--re4': 'תרגול עם תבניות ריתמיות שונות, כולל רבע מנוקד ושמינית',
    'Level2_Lesson17_E02_la4--fad4--re4': 'תרגול עם תבניות ריתמיות שונות, כולל סינקופה עם שמיניות',
    'Level2_Lesson17_R01_la4--fad4--re4': 'השיר "הארץ שלי" מאת המלחין הרומני דומיטרו קיריאק - וריאציה ברה מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson17_R02_la4--fad4--re4': 'תרגול עם תבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',

    'Level2_Lesson18_E01_si4_v2': 'הכרת הצליל והתו "סי4"; תבניות ריתמיות שונות',
    'Level2_Lesson18_E02_si4_v1': 'הכרת הצליל והתו "סי4"; תבניות ריתמיות שונות',
    'Level2_Lesson18_R01_si4--re4_v1': 'השיר "אי-בי-סי" בסולם רה מז\'ור ',
    'Level2_Lesson18_R02_si4--la3_v1': 'השיר "אחינו יעקב", בסולם רא מז\'ור - שיר ילדים צרפתי. שירה בקנון: חיקוי אחרי 4 תיבות ',

    'Level2_Lesson19_E01_si4--mi4': ' וריאציה לשיר עממי רומני "חצו את הגבעה לבאיוץ" - בסולם מי מינור ובמשקל שלושה רבעים. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson19_E02_si4--re4_v1': 'וריאציה לשיר "אי-בי-סי" בשלושה רבעים, בסולם רה מז\'ור',
    'Level2_Lesson19_R01_si4--re4': 'וריאציה לשיר "אי-בי-סי" בשני רבעים, בסולם רה מז\'ור',
    'Level2_Lesson19_R02_si4--mi4': 'וריאציה לשיר עממי רומני "חצו את הגבעה לבאיוץ" - בסולם מי מינור ובמשקל שני רבעים. נתחו, שירו וציינו שמות המרווחים',

    'Level2_Lesson20_E01_si4--si3': 'תרגול מרווחים בסולם מי מינור: אוקטבה, ספטימה, סקסטה, קווינטה, קוורטה, טרצה, סקונדה, פרימה. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson20_E02_si4--si3': 'תרגול מרווחים בסולם מי מינור - המשך. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson20_R01_la4--re4': 'שיר ישראלי: "אמא יקרה לי" (לחן: נחום נרדי, מילים: משה דפנא) בסולם רה מינור. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson20_R02_si4-mi4': 'שיר ישראלי: "אמא יקרה לי" (לחן: נחום נרדי, מילים: משה דפנא) בסולם מי מינור. נתחו, שירו וציינו שמות המרווחים',

    'Level2_Lesson21_E01_la4--la3': 'תרגול מרווחים בסולם רה מינור: אוקטבה, ספטימה, סקסטה, קווינטה, קוורטה, טרצה, סקונדה, פרימה. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson21_E02_la4--la3': 'תרגול מרווחים בסולם רה מז\'ור: אוקטבה, ספטימה, סקסטה, קווינטה, קוורטה, טרצה, סקונדה, פרימה. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson21_R01_si4--re4': 'השיר "כח קרמלים" בסולם רה מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson21_R02_la4--do4': 'השיר "כח קרמלים" בסולם דו מז\'ור. נתחו, שירו וציינו שמות המרווחים',

    'Level2_Lesson22_E01_la4--la3': 'תרגול מרווחים ותבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson22_E02_la4--la3': 'תרגול מרווחים ותבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson22_R01_la4--la3_v1': 'השיר "הנה מה טוב ומה נעים" - לחן עממי, מילים מתוך תהילים. קנון: חיקוי אחרי 16 תיוות',
    'Level2_Lesson22_R02_la4--la3_v1': 'השיר "הנה מה טוב ומה נעים", לחן עממי, מילים מתוך תהילים. תבניות ריתמיות עם שמיניות. קנון: חיקוי אחרי 16 תיוות',

    'Level2_Lesson23_E01_do5_v2': 'הכרת הצליל והתו "דו5"; תבניות ריתמיות שונות',
    'Level2_Lesson23_E02_do5': 'הכרת הצליל והתו "דו5"; תבניות ריתמיות שונות',
    'Level2_Lesson23_R01_do5--do4': 'השיר "דיינו" - לחן עממי, מילים  מן המקורות. תבניות ריתמיות מבוססות רבעים',
    'Level2_Lesson23_R02_do5--do4': 'השיר "דיינו" - לחן עממי, מילים  מן המקורות. תבניות ריתמיות מבוססות שמיניות',

    'Level2_Lesson24_E01_do5--do4': 'תרגול מרווחים עם תבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson24_E02_do5--do4': 'תרגול מרווחים עם תבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson24_R01_do5--mi4': 'שיר עממי. תבניות ריתמיות ברבעים',
    'Level2_Lesson24_R02_do5--mi4_v1': 'שיר עממי. תבניות ריטמיות בשמיניות. קדמה או אנקרוזיס',

    'Level2_Lesson25_E01_do5--do4': 'תרגול מרווחים עם תבניות ריתמיות שונות, בסולם דו מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson25_E02_la4--la3': 'תרגול מרווחים עם תבניות ריתמיות שונות, בסולם לה מינור. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson25_R01_do5--do4_v1': 'תבניות ריתמיות מבוססות רבעים: "קאן-קאן" מאת זיאק אופנבך',
    'Level2_Lesson25_R02_do5--do4': 'תבניות ריתמיות מבוססות שמיניות: "קאן-קאן" מאת זיאק אופנבך',

    'Level2_Lesson26_E01_la3--do5_v1': 'תרגול מרווחים עם תבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson26_E02_do5--la3_v1': 'תרגול מרווחים עם תבניות ריתמיות שונות. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson26_R01_sol4--do4_v1': 'קטע מ"האודה לשמחה" מאת לודוויג ואן בטהובן - בסולם דו מז\'ור',
    'Level2_Lesson26_R02_la4--la3_v1': 'קטע מ"האודה לשמחה" מאת לודוויג ואן בטהובן - בסולם רה מז\'ור',

    'Level2_Lesson27_E01_mi4--la3': 'הכרת הצליל והתו "דו דיאז 4"; תבניות ריתמיות בשלושה רבעים',
    'Level2_Lesson27_E02_la4--fad4-fa4--la3_v2': 'תבניות ריתמיות בשלושה רבעים עם "דו דיאז 4" ודו4 ',
    'Level2_Lesson27_R01_mi4--la3': 'ואריאציות לשיר "הארץ שלי" מאת המלחין הרומני דומיטרו קיריאק ב-2 חלקים: 1. בסולם לה מינור, 2. בסולם לה מז\'ור ',
    'Level2_Lesson27_R02_fad4--la3': 'וריאציה ריתמית לשיר "אי-בי-סי", בסולם לה מז\'ור',

    'Level2_Lesson28_E01_si4--la3': 'שיר מסורתי בצורת קאנון: "תן לנו שלום". הטקסט לקוח מהמיסה הלטינית - קול ראשון. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson28_E02_si4--re4': 'שיר מסורתי בצורת קאנון: "תן לנו שלום". הטקסט לקוח מהמיסה הלטינית - קול שני. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson28_R01_la4--la3': 'שיר מסורתי בצורת קאנון: "תן לנו שלום". הטקסט לקוח מהמיסה הלטינית - קול שלישי. נתחו, שירו וציינו שמות המרווחים',
    'Level2_Lesson28_R02_si4--la3': 'שיר מסורתי בצורת קאנון: "תן לנו שלום". הטקסט לקוח מהמיסה הלטינית - שלושתם הקולות. נתחו, שירו וציינו שמות המרווחים',

    'Level3_Lesson01_E01_la4--dod4': 'סימני היתק - פה♯ (פה דיאז) ודו♯ (דו דיאז); תבניות ריתמיות סימטריותת',
    'Level3_Lesson01_E02_fad4--dod4': 'סימני היתק - פה♯ (פה דיאז) ודו♯ (דו דיאז); תבניות ריתמיות סימטריותת',
    'Level3_Lesson01_R01_si4--la3': 'קטע-1 מ"שיר חורף" מאת המלחין ינון צור. תרגול עם תבניות ריתמיות לא סימטריות',
    'Level3_Lesson01_R02_si4--la3': 'קטע-2 מ"שיר חורף" מאת המלחין ינון צור. תרגול עם תבניות ריתמיות לא סימטריות',

    'Level3_Lesson02_E01_si4--la3': 'קטע-3 מ"שיר חורף" מאת המלחין ינון צור. תרגול תבניות ריתמיות לא סימטריות',
    'Level3_Lesson02_E02_si4--la3': 'שקטע-4 מ"שיר חורף" מאת המלחין ינון צור. תרגול תבניות ריתמיות לא סימטריות',
    'Level3_Lesson02_R01_si4--la3': 'קטע-1 ו-2 מ"שיר חורף" מאת המלחין ינון צור. תרגול תבניות ריתמיות לא סימטריות',
    'Level3_Lesson02_R02_si4--la3': 'קטע-3 ו-4 מ"שיר חורף" מאת המלחין ינון צור. תרגול תבניות ריתמיות לא סימטריות',

    'Level3_Lesson03_E01_mi4--la3': 'המשך תרגול עם דו♯ (דו דיאז). תבניות ריתמיות סימטריות',
    'Level3_Lesson03_E02_la4--re4': 'המשך תרגול עם פה♯ (פה דיאז). תבניות ריתמיות סימטריות',
    'Level3_Lesson03_R01_do5--la3': 'קטע ראשון מ"זעם השחק", מפסקול המשחק "אגדות חוף החרב" מאת המלחין ינון צור',
    'Level3_Lesson03_R02_si4--la3': 'קטע שני מ"זעם השחק", מפסקול המשחק "אגדות חוף החרב" מאת המלחין ינון צור',

    'Level3_Lesson04_E01_sib4--sib3': 'תרגול התווים והצלילים סי♭4  וסי♭3',
    'Level3_Lesson04_E02_do5--si3-sib3': 'תרגול התווים והצלילים סי♭4-סי4, ו-סי♭3-סי3',
    'Level3_Lesson04_R01_do5--la3': 'המשך תרגול עם התווים והצלילים סי♭4 וסי♭3',
    'Level3_Lesson04_R02_do5--la3': 'המשך תרגול עם התווים והצלילים סי♭4 וסי♭3',

    'Level3_Lesson05_E01_do5--do4': 'ווריאציה במשקל שלושה רבעים לשיר "אי-בי-סי" - בסולם פה מז\'ור',
    'Level3_Lesson05_E02_do5--do4': 'ווריאציה נוספת במשקל שלושה רבעים לשיר "אי-בי-סי" - בסולם פה מז\'ור',
    'Level3_Lesson05_R01_do5--do4': 'ווריאציה לשיר עממי רומני "באראבוי" בסולם פה מז\'ור ',
    'Level3_Lesson05_R02_do5--do4': 'תרגיל בסולם פה מז\'ור',

    'Level3_Lesson06_E01_do5-mib4-sib3': 'תו וצליל חדש מי♭. מרווחים: טרצה, קוורטה, קווינטה, סקסטה. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson06_E02_sib4-mib4-sib3': 'תרגול מרווחים: טרצה, קוורטה, קווינטה, סקסטה. ארפג\'ים. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson06_R01_sol4--sib3': 'השיר "אחינו יעקב", בסולם סי♭ מז\'ור - שיר ילדים צרפתי. קול ראשון של הקנון. הקול השני יחקה אותו אחרי 2 תיבות',
    'Level3_Lesson06_R02_sol4--sib3': 'השיר "אחינו יעקב", בסולם סי♭ מז\'ור - שיר ילדים צרפתי. קול שני של הקנון. הקול הראשון יתחיל מהתיבה הראשונה',

    'Level3_Lesson07_E01_fa4--sib3': 'תבניות ריתמיות בסולם סי♭ מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson07_E02_sib4--mib4': 'תבניות ריתמיות בסולם מי♭ מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson07_R01_sib4--la3': 'שיר עממי יידיש "כל האחים". תרגול סולמות רה מינור ופה מז\'ור',
    'Level3_Lesson07_R02_do5--si3': 'שיר עממי יידיש "כל האחים". תרגול סולמות מי מינור וסול מז\'ור',

    'Level3_Lesson08_E01_sib4--sib3': 'תבניות ריתמיות בסולם מי♭ מז\'ור, עם קדמה. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson08_E02_sib4--sib3': 'תבניות ריתמיות בסולם מי♭ מז\'ור, עם קדמה. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson08_R01_fa4--sib3': 'שיר עממי רומני "חבורת השובבים" בסולם סי♭ מז\'ור, בתבניות ריתמיות עם חצאים ורבעים',
    'Level3_Lesson08_R02_fa4--sib3': 'שיר עממי רומני "חבורת השובבים" בסולם סי♭ מז\'ור, בתבניות ריתמיות עם רבעים ושמיניות',

    'Level3_Lesson09_E01_do5--sib3': 'תרגיל סולפג\' בסולם סי♭ מז\'ור, מאת ניקולאי לדוכין - חלק ראשון. עיבוד, המלחין מיכאל דוליצקי',
    'Level3_Lesson09_E02_do5--do4': 'תרגיל סולפג\' בסולם דו מז\'ור, מאת ניקולאי לדוכין - חלק ראשון. עיבוד, המלחין מיכאל דוליצקי',
    'Level3_Lesson09_R01_do5--sib3': 'תרגיל סולפג\' בסולם סי♭ מז\'ור, מאת ניקולאי לדוכין - חלק ראשון וחלק שני. עיבוד, המלחין מיכאל דוליצקי',
    'Level3_Lesson09_R02_do5--do4': 'תרגיל סולפג\' בסולם דו מז\'ור, מאת ניקולאי לדוכין - חלק ראשון וחלק שני. עיבוד, המלחין מיכאל דוליצקי',

    'Level3_Lesson10_E01_do5--sib3': 'תרגיל סולפג\' בסולם סי♭ מז\'ור, מאת ניקולאי לדוכין - חלק ראשון. עיבוד, המלחין מיכאל דוליצקי',
    'Level3_Lesson10_E02_do5--do4': 'תרגיל סולפג\' בסולם דו מז\'ור, מאת ניקולאי לדוכין - חלק ראשון. עיבוד, המלחין מיכאל דוליצקי',
    'Level3_Lesson10_R01_do5--sib3': 'תרגיל סולפג\' בסולם סי♭ מז\'ור, מאת ניקולאי לדוכין - חלק ראשון וחלק שני. עיבוד, המלחין מיכאל דוליצקי',
    'Level3_Lesson10_R02_do5--do4': 'תרגיל סולפג\' בסולם דו מז\'ור, מאת ניקולאי לדוכין - חלק ראשון וחלק שני. עיבוד, המלחין מיכאל דוליצקי',

    'Level3_Lesson11_E01_sold4': '♯התו והצליל סול',
    'Level3_Lesson11_E02_si4-sold4-mi4': '♯תרגול התוים וסול♯ ופה',
    'Level3_Lesson11_R01_si4-sol4-sold4-mi4': 'סולפג\' ב-2 חלקים: חלק ראשון (8 תיבות) סולם מי מינור, וחלק שני (8 תיבות), סולם מי מז\'ור',
    'Level3_Lesson11_R02_si4--mi4': 'תרגיל בסולם מי מז\'ור',

    'Level3_Lesson12_E01_si4--mi4': 'תרגיל בסולם מי מז\'ור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson12_E02_si4--mi4': 'איזה צלילים צריכים לשיר במקום ההפסקות, כדי לקבל את השיר יונתן הקטן? - תרגיל בסולם מי מז\'ור ',
    'Level3_Lesson12_R01_si4--mi4': 'השוו עם הסולפג\' הקודם, וגלו התשובה לשאלה הנשאלת שם',
    'Level3_Lesson12_R02_si4--mi4': 'סולפג\' בסולם מי מז\'ור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',

    'Level3_Lesson13_E01_si4--si3': 'תבניות ריתמיות עם הפסקות של שמיניות בסולם מי מז\'ור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson13_E02_si4--mi4': 'תבניות ריתמיות עם הפסקות של שמיניות בסולם מי מז\'ור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson13_R01_do5--sib3': 'קטע ראשון מ"עיר המפרשים" מפסקול המשחק "אגדות חוף החרב" - מאת המלחין ינון צור',
    'Level3_Lesson13_R02_do5--do4': 'קטע שני מ"עיר המפרשים" מפסקול המשחק "אגדות חוף החרב" - מאת המלחין ינון צור',

    'Level3_Lesson14_E01_do5--sib3': 'קטעים ראשון ושני מ"עיר המפרשים" מפסקול המשחק "אגדות חוף החרב" - מאת המלחין ינון צור',
    'Level3_Lesson14_E02_do5--sib3': 'קטע שלישי מ"עיר המפרשים" מפסקול המשחק "אגדות חוף החרב" - מאת המלחין ינון צור',
    'Level3_Lesson14_R01_do5--la3': 'קטע רביעי מ"עיר המפרשים" מפסקול המשחק "אגדות חוף החרב" - מאת המלחין ינון צור',
    'Level3_Lesson14_R02_do5--la3': 'קטע חמישי מ"עיר המפרשים" מפסקול המשחק "אגדות חוף החרב" - מאת המלחין ינון צור',

    'Level3_Lesson15_E01_lab4': 'הצליל והתו לה במול',
    'Level3_Lesson15_E02_lab4-fa4': 'הצליל והתו לה♭. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson15_R01_do5--mib4': 'השיר "קח קרמלים" בסולם מי במול מז\'ור',
    'Level3_Lesson15_R02_do5--mib4': 'וריאציה ריתמית לשיר "אי-בי-סי", בסולם מי במול',

    'Level3_Lesson16_E01_do5--sib3': 'תבניות ריתמיות בסולם מי♭ מז\'ור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson16_E02_sib5--mib4': 'תבניות ריתמיות בסולם מי♭ מז\'ור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson16_R01_do5--mib4': 'וריאציה בשלושה רבעים לשיר העממי הרומני "חצו את הגבעה לבאיוץ" - בסולם פה מינור',
    'Level3_Lesson16_R02_do5--fa4': ' וריאציה בשני קבעים לשיר העממי הרומני "חצו את הגבעה לבאיוץ" - בסולם פה מינור',

    'Level3_Lesson17_E01_si4--si3': 'חזרה על מרווחים. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson17_E02_si4--si3': 'חזרה על מרווחים. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson17_R01_A-do5--sib3': 'השיר "אחינו יעקב", שיר ילדים צרפתי בסולם מי♭ מז\'ור. קול ראשון של הקנון. הקול השני יחקה אותו אחרי 4 תיבות',
    'Level3_Lesson17_R02_B-do5--sib3': 'השיר "אחינו יעקב" (או אחינו הנהג), שיר ילדים צרפתי בסולם מי במול מז\'ור. קול שני של הקנון. הקול הראשון יתחיל מהתיבה הראשונה',

    'Level3_Lesson18_E01_do5--do4': 'חזרה על מרווחים. סולם פה מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson18_E02_do5--do4': 'חזרה על מרווחים. סולם פה מז\'ור. נתחו, שירו וציינו שמות המרווחים',
    'Level3_Lesson18_R01_si4--si3': 'שיר עממי רוסי. סולם סי מינור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',
    'Level3_Lesson18_R02_do5--do4': 'שיר עממי רוסי. סולם דו מינור. החלפת ההפסקות ב"צלילי תיפוף גוף": מחיאות כפיים, אצבע צרידה, טפיחות על הברכיים',

    'Level3_Lesson19_E01_do5--sib3': 'קטע ראשון מ"אלופים", מהאלבום "לתוך הסערה" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',
    'Level3_Lesson19_E02_sib4--sib3': 'קטע שני מ"אלופים", מהאלבום "לתוך הסערה" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',
    'Level3_Lesson19_R01_do5--sib3': 'קטע שלישי מ"אלופים", מהאלבום "לתוך הסערה" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',
    'Level3_Lesson19_R02_do5--sib3': 'קטע רביעי מ"אלופים", מהאלבום "לתוך הסערה" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',

    'Level3_Lesson20_E01_do5--sib3': 'קטע ראשון מ"הסדן של לאדוגר" מפסקול המשחק "אגדות חוף החרב" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',
    'Level3_Lesson20_E02_do5--sib3': 'קטע שני מ"הסדן של לאדוגר" מפסקול המשחק "אגדות חוף החרב" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',
    'Level3_Lesson20_R01_do5--si3': 'קטע שלישי מ"הסדן של לאדוגר" מפסקול המשחק "אגדות חוף החרב" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',
    'Level3_Lesson20_R02_sib4--la3': 'קטע רביעי מ"הסדן של לאדוגר" מפסקול המשחק "אגדות חוף החרב" מאת המלחין ינון צור. תבניות ריתמיות לא סימטריות',

    'Level3_Lesson21_E01_sib4--sib3': 'שיר ערש מאת המלחין יוחנס ברהמס. סולם סי♭ מז\'ור',
    'Level3_Lesson21_E02_do5--do4': 'שיר ערש מאת המלחין יוחנס ברהמס. סולם דו מז\'ור',
    'Level3_Lesson21_R01_sib4--la3': 'חלק ראשון ממינואט מאת יוהאן סבסטיאן באך. מינואט זה יצירה מוזיקלית במשקל 3/4. סולם סי במול מז\'ור',
    'Level3_Lesson21_R02_sib4--la3': 'חלק שני ממינואט מאת יוהאן סבסטיאן באך. מינואט זה יצירה מוזיקלית במשקל 3/4. סולם סי במול מז\'ור',

    'Level3_Lesson22_E01_la4--la3': 'שיר ערש מאת המלחין פרנץ שוברט. סולם רה מז\'ור',
    'Level3_Lesson22_E02_sib4--sib3': 'שיר ערש מאת המלחין פרנץ שוברט. סולם מי♭ מז\'ור',
    'Level3_Lesson22_R01_sib4--la3': 'מינואט מאת יוהאן סבסטיאן באך. מינואט זה יצירה מוזיקלית במשקל שלושה רבעים ומורכבת משני חלקים. סולם סי♭ מז\'ור',
    'Level3_Lesson22_R02_do5--sib3': 'מינואט מאת יוהאן סבסטיאן באך. מינואט זה יצירה מוזיקלית במשקל שלושה רבעים ומורכבת משני חלקים. סולם דו מז\'ור',

    'Level3_Lesson23_E01_la4--la3': 'השיר "טוניקה" מאת איליה חרובארו. סולם לה מז\'ור. "טוניקה" היא הצליל המרכזי, הראשון בסולם מסוים',
    'Level3_Lesson23_E02_la4--la3': 'השיר "טוניקה" מאת איליה חרובארו. סולם לה מז\'ור. "טוניקה" היא הצליל המרכזי, הראשון בסולם מסוים. וריאציה ריתמית',
    'Level3_Lesson23_R01_sib4--sib3': 'השיר "טוניקה" מאת איליה חרובארו. סולם סי♭ מז\'ור. "טוניקה" היא הצליל המרכזי, הראשון בסולם מסוים. וריאציה טונלית וריתמית',
    'Level3_Lesson23_R02_do5--do4': 'השיר "טוניקה" מאת איליה חרובארו. סולם דו מז\'ור. "טוניקה" היא הצליל המרכזי, הראשון בסולם מסוים. וריאציה טונלית וריתמית',

    'Level3_Lesson24_E01_la4--la3': 'השיר "חידה" מאת איליה חרובארו. סולם לה מז\'ור',
    'Level3_Lesson24_E02_la4--la3': 'השיר "חידה" מאת איליה חרובארו. סולם לה מז\'ור. וריאציה ריתמית',
    'Level3_Lesson24_R01_sib4--sib3': 'השיר "חידה" מאת איליה חרובארו. סולם סי♭ מז\'ור. וריאציה טונלית וריתמית',
    'Level3_Lesson24_R02_do5--do4': 'השיר "חידה" מאת איליה חרובארו. סולם דו מז\'ור. וריאציה טונלית וריתמית',

    'Level3_Lesson25_E01_sib4--mib3': 'יוהאן סבסטיאן באך, מוזט בסולם מי♭ מז\'ור - חלק ראשון',
    'Level3_Lesson25_E02_sib4--sib3': 'יוהאן סבסטיאן באך, מוזט בסולם מי♭ מז\'ור - חלק ראשון וחלק שני',
    'Level3_Lesson25_R01_sib4--sib3': 'שיר "במול, בקר, דיאז" מאת איליה חרוברו. סולם סי♭ מז\'ור ',
    'Level3_Lesson25_R02_do5--do4': 'שיר "במול, בקר, דיאז" מאת איליה חרוברו. סולם דו מז\'ור',

    'Level3_Lesson26_E01_sib--la3-rem': 'שיר צרפתי ישן (מתוך אלבום לצעירים), אופ. 39, מס 16 מאת פיוטר איליץ\' צ\'ייקובסקי. חלק ראשון, בסולם רה מינור',
    'Level3_Lesson26_E02_do5--la3-rem': 'שיר צרפתי ישן (מתוך אלבום לצעירים), אופ. 39, מס 16 מאת פיוטר איליץ\' צ\'ייקובסקי. חלק שני, בסולם רה מינור',
    'Level3_Lesson26_R01_do5--la3-rem': 'שיר צרפתי ישן (מתוך אלבום לצעירים), אופ. 39, מס 16 מאת פיוטר איליץ\' צ\'ייקובסקי. חלקים ראשון ושני, בסולם רה מינור',
    'Level3_Lesson26_R02_do5--si3-mim': 'שיר צרפתי ישן (מתוך אלבום לצעירים), אופ. 39, מס 16 מאת פיוטר איליץ\' צ\'ייקובסקי. חלקים ראשון ושני, בסולם מי מינור',

    'Level3_Lesson27_E01_la4--la3-Re': 'שיר מסורתי פורטוגזי "בדרך לוויסאו" בסולם רה מז\'ור - ברבעים',
    'Level3_Lesson27_E02_sib4--sib3-Mib': 'שיר מסורתי פורטוגזי "בדרך לוויסאו" בסולם מי♭ מז\'ור - ברבעיעם',
    'Level3_Lesson27_R01_la4--la3-Re': 'שיר מסורתי פורטוגזי "בדרך לוויסאו" בסולם רה מז\'ור - בשמיניות',
    'Level3_Lesson27_R02_sib4--la3-ReMib': 'מודולציה: תהליך שינוי המרכז הטונלי במהלך היצירה. שיר מסורתי פורטוגזי "בדרך לוויסאו" בסולם רה מז\'ור ומודולציה לסולם מי♭ מז\'ור - בשמיניות',

    'Level3_Lesson28_E01_la4--la3-re': 'מודולציה: תהליך שינוי המרכז הטונלי במהלך היצירה. שיר מסורתי פורטוגזי "היונה" בסולם רה מינור - ברבעים וחצאים ',
    'Level3_Lesson28_E02_si4--si3-mi': 'מודולציה: תהליך שינוי המרכז הטונלי במהלך היצירה. שיר מסורתי פורטוגזי "היונה" בסולם מי מינור  - ברבעים וחצאים',
    'Level3_Lesson28_R01_si4--la3-remi': 'מודולציה: תהליך שינוי המרכז הטונלי במהלך היצירה. שיר מסורתי פורטוגזי "היונה" בסולם רה מינור ומודולציה למי מינור - בשמיניות',
    'Level3_Lesson28_R02_do5--si3-mifa': 'מודולציה: תהליך שינוי המרכז הטונלי במהלך היצירה. שיר מסורתי פורטוגזי "היונה" בסולם מי מינור ומודולציה לפה מינור - בשמיניות',

};

export default ExerciseDescriptions_he;