import React from 'react'
import { useState, useEffect } from "react";
import VerticalHeader from './vertical_header';
import Dashboard from './dashboard';
import Exercise from './exercise';
import Games from './games/games';
import StudentDetails from './student_details';
import MyStudents from './mystudents';
import User from './user';
import {StopAnimation} from './exercise_utils'
import './styles/extras.css';

function Home(props) {
    const [content, setContent] = useState(null);
    const [studentUser, setStudentUser] = useState(null);
    const [teacherReview, setTeacherReview] = useState(null);
    const [scale, setScale] = useState(1);

    const setScaleCallback = (val) => {
        setScale(val);
    };

    const setContentCallback= (val,username) => {
        setContent(val);
        if (val !== 'solfege' && val !== 'theory' && val !== 'songs')
            StopAnimation();
        if (val=='myprogress') {
            setStudentUser(props.headerContext.username);
            setTeacherReview(false)
        }
        if (val !== 'solfege' && val !== 'theory' && val !== 'songs' && document.fullscreenElement) {
            document.exitFullscreen().catch(err => {
                console.warn('Error exiting full-screen mode:', err);
            });
        }
    };
    const [asStudent, setAsStudent] = useState(props.headerContext.as_student);
    const setAsStudentCallback= (val) => {
        setAsStudent(val);
    };
    const [passedRectest, setPassedRectest] = useState(null);
    const setPassedRectestCallback= (val) => {
        setPassedRectest(val);
    };
    const [user_fullname, setUserFullname] = useState(null);
    const setUserFullnameCallback= (val1,val2) => {
        setUserFullname([val1,val2]);
    };
    useEffect(() => {
        setUserFullname([props.headerContext.user_firstname,props.headerContext.user_lastname])
        setPassedRectest(props.headerContext.passed_recording_test)
        setContent('dashboard')
        setAsStudent(props.headerContext.as_student);
    }, [props.headerContext]);

    const [windowSize, setWindowSize] = useState([window.innerWidth/scale, window.innerHeight/scale]);
    useEffect(() => {
        setWindowSize([window.innerWidth / scale, window.innerHeight / scale]);
        const handleResize = () => {
            setWindowSize([window.innerWidth / scale, window.innerHeight / scale]);
            const newBaseFontSize = Math.min(window.innerWidth / 100, 16); // Adjust as needed
            document.documentElement.style.setProperty('--base-font-size', `${newBaseFontSize}px`);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [scale]);

    if (content==null)
        return null;

    return (
        <div className='homePage' style={{
            transform: `scale(${scale})`, width: `${100 / scale}%`, height: `${100 / scale}%`, transformOrigin: 'top left',}}>
            <VerticalHeader
                authenticated={props.authenticated} headerContext={props.headerContext}
                langChangeCallback={props.langChangeCallback} lang={props.lang}
                asStudent={asStudent} setAsStudentCallback={setAsStudentCallback}
                enablePractice={passedRectest}
                content={content} setContentCallback = {setContentCallback}
                user_fullname={user_fullname}
                />
            {content === 'dashboard' &&
                <Dashboard windowSize={windowSize}
                    langChangeCallback={props.langChangeCallback} lang={props.lang}
                    asStudent={asStudent} setAsStudentCallback={setAsStudentCallback}
                    headerContext={props.headerContext}
                    TeacherAttr={props.headerContext.TeacherAttr}
                    passedRectest={passedRectest} setPassedRectestCallback={setPassedRectestCallback}
                    content={content} setContentCallback = {setContentCallback}
                    display={content === 'dashboard'}
                    scale={scale} setScaleCallback={setScaleCallback}
                />}
            <Exercise content={content} lang={props.lang} windowSize={windowSize}
                SolfegeLevels={props.SolfegeLevels} TheoryCourses={props.TheoryCourses} SongClasses={props.SongClasses}
                asStudent={asStudent}
                pitchTolerance={props.headerContext.pitch_tolerance}
                pitchToleranceRange={props.headerContext.pitch_tolerance_range}
                passedRectest={passedRectest}
                display={(content === 'solfege' || content === 'theory' || content === 'songs')}
                scale={scale} setScaleCallback={setScaleCallback}
            />
            {content === 'games' &&
                <Games content={content} lang={props.lang} windowSize={windowSize}
                    display={content === 'games'}
                />
            }
            {content==='myprogress' && props.authenticated &&
                <StudentDetails lang={props.lang} windowSize={windowSize}
                    SolfegeLevels={props.SolfegeLevels} SongClasses={props.SongClasses}
                    username={studentUser} group={null}
                    teacherReview = {teacherReview}
                    scale={scale} setScaleCallback={setScaleCallback}
                    />}
            {props.authenticated && props.headerContext.TeacherAttr &&
                <MyStudents context={props.headerContext} lang={props.lang} windowSize={windowSize}
                SolfegeLevels={props.SolfegeLevels} TheoryCourses={props.TheoryCourses} SongClasses={props.SongClasses}
                    pitchToleranceRange={props.headerContext.pitch_tolerance_range}
                    display={content === 'mystudents'}
                    scale={scale} setScaleCallback={setScaleCallback}
            />}
            <User lang={props.lang} windowSize={windowSize}
                setAuthenticatedCallback = {props.setAuthenticatedCallback}
                setContentCallback = {setContentCallback}
                setUserFullnameCallback={setUserFullnameCallback}
                asStudent={asStudent} setAsStudentCallback={setAsStudentCallback}
                TeacherAttr={props.headerContext.TeacherAttr}
                display={content==='user'}
                scale={scale} setScaleCallback={setScaleCallback}
                user_firstname={props.headerContext.user_firstname} 
                allGroupIds={props.headerContext.allGroupIds}
        />
        </div>
    )
}

function ZoomSelection(props) {

    const handleZoomChange = (event) => {
        props.setScaleCallback(parseFloat(event.target.value));
    };

    return (
        <FormControl variant="outlined" className="dropdown">
            <InputLabel id="zoom-level-label">Zoom Level</InputLabel>
            <Select
                labelId="zoom-level-label"
                id="zoom-level"
                value={props.scale}
                onChange={handleZoomChange}
                label="Zoom Level"
            >
                <MenuItem value={0.8}>80%</MenuItem>
                <MenuItem value={0.9}>90%</MenuItem>
                <MenuItem value={1.}>100%</MenuItem>
                <MenuItem value={1.1}>110%</MenuItem>
                <MenuItem value={1.2}>120%</MenuItem>
            </Select>
        </FormControl>
    )

}
export default Home;
