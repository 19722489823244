import React from 'react'
import { useState, useEffect } from "react";
import MyGroups from './mygroups';
import GroupDetails from './group_details';
import StudentDetails from './student_details';
import ExerciseDetails from './exercise_details';


function MyStudents(props) {
    const [page, setPage] = useState('myGroups');
    const [pageParams, setPageParams] = useState({});

    const setPageCallback = (newPage, params) => {
        setPage(newPage);
        setPageParams(prevParams => ({
            ...prevParams,
            ...params
        }));
    };
    return (
        <>
            <MyGroups lang={props.lang} setPageCallback={setPageCallback} windowSize={props.windowSize}
                display={props.display && page === 'myGroups'}
            />
            <GroupDetails lang={props.lang} group={pageParams.group} setPageCallback={setPageCallback} windowSize={props.windowSize}
                pitchToleranceRange={props.pitchToleranceRange}
                display={props.display && page === 'groupDetails'}
            />
            {page === 'studentDetails' && props.display &&
                <StudentDetails lang={props.lang}
                    username={pageParams.username} group={pageParams.group} setPageCallback={setPageCallback} windowSize={props.windowSize}
                    SolfegeLevels={props.SolfegeLevels} TheoryCourses={props.TheoryCourses} SongClasses={props.SongClasses}
                    teacherReview = {true}
                category={pageParams.category}/>}
            {page === 'exerciseDetails' && props.display &&
                <ExerciseDetails lang={props.lang}
                    pk={pageParams.exe_pk} username={pageParams.username} group={pageParams.group} setPageCallback={setPageCallback}
                        category={pageParams.category} windowSize={props.windowSize}
                        pitchToleranceRange={props.pitchToleranceRange}
                        scale={props.scale} setScaleCallback={props.setScaleCallback}
                        />}
        </>
    );
}

export default MyStudents;

