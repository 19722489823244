

export function parseDataNotes(DataNotes, clefSplit, keySignatures, measureDurations, accNoteTime, durationmodify, scoretempo) {

    let parsedNotes = [];
    let measureEndFlag = Array(DataNotes.length).fill(false);
    let FirstNoteCntInMeasures = [0];
    let accNoteDurationInMeas = 0;
    let measureCnt = 0;
    let note;
    let duration;
    let dot;
    let accidental;
    let slash;
    let measureDuration = measureDurations[0];
    let accMeasureDurations = 0;
    let lastScale = keySignatures[0];
    let acclist = KeySignatureAccDict[lastScale];
    let natlist = KeySignatureNaturalDict[lastScale];
    let vartempo = scoretempo['0'];
    let j_lastnote = -1;
    let slashPresent = false;

    accNoteTime[0] = 0;

    for (let noteCnt = 0; noteCnt < DataNotes.length; noteCnt++) {
        [note, duration, dot, accidental, slash] = parseDataNote(DataNotes[noteCnt], clefSplit);
        let notelength = getNoteLength(duration);
        if (durationmodify && Object.keys(durationmodify).includes(noteCnt.toString())) {
            notelength /= durationmodify[noteCnt.toString()];
        }
        if (Object.keys(scoretempo).includes(noteCnt.toString())) {
            vartempo = scoretempo[noteCnt.toString()];
        }

        let notetime = notelength * 240 / vartempo; // [sec]
        accNoteTime[noteCnt + 1] = accNoteTime[noteCnt] + notetime;

        accNoteDurationInMeas += notelength;
        accNoteDurationInMeas = Math.round(accNoteDurationInMeas * 128 * 3) / (128 * 3);

        if (slash)
            slashPresent = true;

        if (accNoteDurationInMeas >= measureDuration || noteCnt == DataNotes.length - 1) {
            measureEndFlag[noteCnt] = true;
            accMeasureDurations += accNoteDurationInMeas;
            accNoteDurationInMeas = 0;
            FirstNoteCntInMeasures.push(noteCnt);

            if (measureCnt.toString() in keySignatures) {
                lastScale = keySignatures[measureCnt];
                acclist = KeySignatureAccDict[lastScale];
                natlist = KeySignatureNaturalDict[lastScale];
            }
            j_lastnote = -1;
            measureCnt += 1;
            if (measureCnt.toString() in measureDurations) {
                measureDuration = measureDurations[measureCnt];
            }
        }

        if (!duration.includes('r')) {
            let notesplit = note.split('/');
            if (accidental !== '') {
                let j = acclist.findIndex((e) => e == notesplit[0] + accidental);
                if (j >= 0 && j_lastnote != j) {
                    accidental = '';
                }
            } else {
                let j = natlist.findIndex((e) => e == notesplit[0]);
                if (j >= 0) {
                    accidental = 'n';
                    j_lastnote = j;
                } else {
                    j_lastnote = -1;
                }
            }
        }

        parsedNotes.push([note, duration, dot, accidental, slash]);
    }

    removePersistentAccidentals(parsedNotes, measureEndFlag, acclist, natlist);

    var measureEndNotes = measureEndFlag
        .map((value, index) => value ? index : -1) // Map to indexes or -1 for false values
        .filter(index => index !== -1); // Filter out the -1 values

    return [parsedNotes, measureEndFlag, measureCnt, measureEndNotes];
}

export function determineLeadingMeasures(MusicDataLeadingMeasures, measureEndFlag, text, textColor) {
    if (MusicDataLeadingMeasures !== undefined) {
        let numLeadingMeasures = MusicDataLeadingMeasures;
        return numLeadingMeasures;
    }
    else {
        let numLeadingMeasures = 2;
        if (text && !textColor) {
            let nmeas = 0;
            for (var i = 0; i < measureEndFlag.length; i++) {
                if (text[i]) {
                    break;
                }
                if (measureEndFlag[i]) {
                    nmeas++;
                }
            }
            if (i > 0 && !measureEndFlag[i - 1]) {
                nmeas++;
            }
            numLeadingMeasures = nmeas;
        }
        return numLeadingMeasures;
    }
}

export function removePersistentAccidentals(parsedNotes, measureEndFlag, acclist, natlist) {
  let diazlist = [];
  let bemollist = [];
  let becarlist = [];
  let nonelist = [];
  let noteplus;
  let duration;
  let dot;
  let accidental;
  let slash;

  for (let noteCnt = 0; noteCnt < parsedNotes.length; noteCnt++) {
    [noteplus, duration, dot, accidental, slash] = parsedNotes[noteCnt];
    if (noteCnt == 0 || measureEndFlag[noteCnt - 1]) {
      diazlist = [];
      bemollist = [];
      becarlist = [];
      nonelist = [];
    }
    if (!duration.includes('r')) {
      let noteChar = noteplus.split('/')[0];
      let noteChar_in_diazlist = diazlist.some((e) => e == noteChar);
      let noteChar_in_bemollist = bemollist.some((e) => e == noteChar);
      let noteChar_in_becarlist = becarlist.some((e) => e == noteChar);
      let noteChar_in_nonelist = nonelist.some((e) => e == noteChar);
      let resetlists = 0;

      if (noteChar_in_diazlist || noteChar_in_bemollist || noteChar_in_becarlist) {
        if (
          (noteChar_in_bemollist && accidental == 'b') ||
          (noteChar_in_diazlist && accidental == '#') ||
          (noteChar_in_becarlist && accidental == 'n')
        ) {
          accidental = '';
        } else if (accidental == '' && nonelist.length == 0) {
          let noteInNatList = natlist.some((e) => e == noteChar);
          if (noteInNatList) {
            accidental = acclist[natlist.findIndex((e) => e == noteChar)].slice(-1);
          } else {
            accidental = 'n';
          }
          nonelist.push(noteChar);
          resetlists = 1;
        }
        parsedNotes[noteCnt] = [noteplus, duration, dot, accidental, slash];
      }
      if (accidental == '#') diazlist.push(noteChar);
      else if (accidental == 'b') bemollist.push(noteChar);
      else if (accidental == 'n') becarlist.push(noteChar);
    }
  }
}

export function ParseBeamsForMeasure(beams) {
  let beamsindex = [];
  for (let i = 0; i < beams.length; i++) {
    if (beams[i] !== 0) beamsindex.push(i);
  }
  return beamsindex;
}

export function getNoteLength(duration) {
  let noteLength;
  switch (duration) {
    case 'wd':
    case 'wdr':
      noteLength = 1.5;
      break;
    case 'w':
    case 'wr':
      noteLength = 1;
      break;
    case 'h':
    case 'hr':
      noteLength = 0.5;
      break;
    case 'hd':
    case 'hdr':
      noteLength = 0.5 * 1.5;
      break;
    case 'q':
    case 'qr':
      noteLength = 0.25;
      break;
    case 'qd':
    case 'qdr':
      noteLength = 0.25 * 1.5;
      break;
    case '8d':
    case '8dr':
      noteLength = 0.125 * 1.5;
      break;
    case '16d':
    case '16dr':
      noteLength = 0.0625 * 1.5;
      break;
    case '32d':
    case '32dr':
      noteLength = 0.03125 * 1.5;
      break;
    case '64d':
    case '64dr':
      noteLength = 0.015625 * 1.5;
      break;
    case '8':
    case '16':
    case '32':
    case '64':
    case '8r':
    case '16r':
    case '32r':
    case '64r':
      noteLength = 1 / parseInt(duration);
      break;
    case '8s':
      noteLength = 0;
      break;
  }
  return noteLength;
}

export function getNoteDuration(length) {
  let noteDuration;
  switch (length) {
    case 1.5:
      noteDuration = 'wd';
      break;
    case 1:
      noteDuration = 'w';
      break;
    case 0.75:
      noteDuration = 'hd';
      break;
    case 0.5:
      noteDuration = 'h';
      break;
    case 0.375:
      noteDuration = 'qd';
      break;
    case 0.375:
      noteDuration = 'qd';
      break;
    case 0.25:
      noteDuration = 'q';
      break;
    case 0.1875:
      noteDuration = '8d';
      break;
    case 0.125:
      noteDuration = '8';
      break;
    case 0.09375:
      noteDuration = '16d';
      break;
    case 0.0625:
      noteDuration = '16';
      break;
    case 0.046875:
      noteDuration = '32d';
      break;
    case 0.03125:
      noteDuration = '32';
      break;
    case 0.0234375:
      noteDuration = '64d';
      break;
    case 0.015625:
      noteDuration = '64';
      break;
    default:
      noteDuration = null;
  }
  return noteDuration;
}

export function getNearestNoteLength(x) {
    let lengths = [1.5,1,0.75,0.5,0.375,0.25,0.1875,0.125,0.09375,0.0625,0.046875,0.03125,0.0234375,0.015625];
    let L = 0.015625;
    for (let i=1; i<lengths.length; i++) {
        if(x > lengths[i]) {
            L = (x - lengths[i] > lengths[i - 1] - x) ? lengths[i - 1] : lengths[i];
            break;
        }
    }
    return L;
}

export function stripRest(str) {
  let n = str.search("r");
  if (n > -1) {
    let str1 = str.slice(0, n);
    let str2 = str1.concat(str.slice(n + 1, -1));
    return str2;
  } else {
    return str;
  }
}

// notes density = maximal number of notes in a whole
export function findNoteDensity(vfnotes) {
  let density = 1;
  if (vfnotes) {
    for (let i = 0; i < vfnotes.length; i++) {
      if (vfnotes[i].includes('.16')) {
        density = 16;
        break;
      } else if (vfnotes[i].includes('.8')) {
        density = 8;
      } else if (vfnotes[i].includes('.q') && density < 8) {
        density = 4;
      } else if (vfnotes[i].includes('.h') && density < 4) {
        density = 2;
      }
    }
  }
  return density;
}

export function findMaxDensityPerMeasure(measureEndNotes, startMeasure, endMeasures) {
    let arr = measureEndNotes.slice(Math.max(0,startMeasure-1), endMeasures)
    let maxdiff = 0;
    for (let i = 1; i < arr.length; i++) {
        maxdiff = Math.max(maxdiff, arr[i] - arr[i - 1]);            
    }
    return maxdiff;
}
export function findWeightedLongestPair(vfnotes, text, measureEndNotes) {
    const hebrewNikudRegex = /[\u0591-\u05C7]/;
    const factor = hebrewNikudRegex.test(text) ? 0.5 : 1;
    var LongestPair = 0;
    for (let i = 0; i < text.length - 1; i++) {
        if (!measureEndNotes.includes(i)) {
            let notesInWhole = getVfNoteDuration(vfnotes[i]);
            let pair = ((text[i].length + text[i + 1].length) * factor + 1) * notesInWhole;  // +1 to allow space
            LongestPair = Math.max(LongestPair, pair);
        }
    }
    return LongestPair / 2; // divide by 2 because only half the words pair lies in between the notes
}

export function findWholeNotePixelsForTextedNotes(notes, text, measureEndNotes, LyricFontSize, adhocFactor) {
    if (text && text.some(str => str.trim() !== "")) {
        const WeightedLongestPair = findWeightedLongestPair(notes, text, measureEndNotes);
        const WholeNotePixels = LyricFontSize * WeightedLongestPair * adhocFactor;
        return WholeNotePixels;
    }
    else
        return 0;
}

export function getVfNoteDuration(note) {
    if (note.includes('.q'))
        return (4);
    else if (note.includes('.8'))
        return (8);
    else if (note.includes('.w'))
        return (1);
    else if (note.includes('.16'))
        return (16);
    else if (note.includes('.h'))
        return (2);
    else if (note.includes('.32'))
        return (32);
    else if (note.includes('.64'))
        return (64);
    else
        return null; // catch an error
}

export function parseDataNote(datanote, clefSplit) {
    let notename = datanote.split('.')[0];
    let duration = datanote.split('.')[1];

    if (clefSplit.length == 2) {
        let noteSplit = notename.split('/');
        if (clefSplit[1] == '8vb') {
            // decrement the octave
            noteSplit[1] = parseInt(noteSplit[1]) + 1;
            notename = noteSplit[0] + '/' + noteSplit[1].toString();
        } else if (clefSplit[1] == '8va') {
            // increment the octave
            noteSplit[1] = parseInt(noteSplit[1]) - 1;
            notename = noteSplit[0] + '/' + noteSplit[1].toString();
        }
    }
    if (duration.slice(-1) == 'd' || duration.slice(-2) == 'dr') {
        var dot = true;
    } else {
        var dot = false;
    }

    if (duration.slice(-1) == 's') {
        var slash = true;
    } else {
        var slash = false;
    }


  let remove = false;
  if (notename.includes('#')) {
    var accidental = '#';
    remove = true;
  } else if (notename.includes('b')) {
    var accidental = 'b';
    remove = true;
  } else if (notename.includes('n')) {
    var accidental = 'n';
    remove = true;
  } else {
    var accidental = '';
  }

  if (remove) {
    let split = notename.split(accidental);
    notename = split[0] + split[1];
  }

  return [notename, duration, dot, accidental, slash];
}

export function determineStaveHeightAdd(notes, clefSplit, text, dynamics, FdbCode) {
  const lowerOctave = clefSplit[0] === 'bass' ? 2 : 3;
  let textline = 10;
  let textadd = 2 * (FdbCode[0] === 1);
  let dynadd = FdbCode[1]; // FdbCode[1]=1 if the ref score had dynamics
  let stemsadd = 0;
  let finaladd = 0;
  const regex = /[\u0591-\u05BD\u05BF-\u05C7\u05F4]/; // nikkud

  const N = notes.length;
  for (let n = 0; n < N; n++) {
    const note = notes[n][0];
    const duration = notes[n][1];
    if (duration[0] !== 'w' || duration[0] !== 'h' || duration[0] !== 'q') {
      stemsadd = 2;
    }
    if (text && text[n] !== '') {
        textadd = regex.test(text[n]) ?  2.5 : 2;
    }
    if (dynamics && dynamics[n] !== '') dynadd = 1;

    const octave = parseInt(note[note.length - 1]);
    const key = note[0];
    let add = 0;
    if (octave === lowerOctave) {
      if (clefSplit[0] === 'treble' && (key === 'G' || key === 'F')) add += 5;
      if (clefSplit[0] === 'bass' && (key === 'B' || key === 'A')) add += 5;
    }
    finaladd = Math.max(finaladd, add);
  }
  textline += (finaladd + stemsadd) / 5;

  if (FdbCode[0] === -1) textadd = 0;
  finaladd += stemsadd + dynadd + textadd;
  finaladd = Math.ceil(finaladd);
  textline = Math.ceil(textline);

  return [finaladd, textline];
}

export function DetermineDurationAndDash(text, note_duration, nextText, fontsize, WholeNotePixels) {
    const hebrewNikudRegex = /[\u0591-\u05C7]/;
    const factor = hebrewNikudRegex.test(text) ? 0.5 : 1;
    const n = text.search("-");
    if (n <= -1) {
        return [text, note_duration, '', '']; // no dash
    }
    // found a dash:
    let len,L1,L2;
    text = text.slice(0, n); // cut off the dash
    let note_length = getNoteLength(note_duration);
    let text_pixels = text.length * factor * fontsize/2; // text pixels to the right of the note
    let next_text_pixels = nextText.length * factor * fontsize / 2; // next text pixels to the left of the note
    let note_pixels = note_length*WholeNotePixels; // total available pixels
    let dash_spacer = Math.max(0, (note_pixels - next_text_pixels - text_pixels)); // available space for dash plus extra spaces to position it in the center
    if (dash_spacer > fontsize) { // there is room for extra spaces
        // split the note into two text-notes with text_duration and dash_duration
        text_pixels += dash_spacer*0.75; // this would be the duration of the dashless text
        let dash_pixels = Math.max(0, note_pixels - text_pixels); // the remaining goes to the dash
        if (dash_pixels < text_pixels) {
            len = text_pixels / WholeNotePixels;
            L1 = getNearestNoteLength(len);
            L2 = note_length - L1;
        }
        else {
            len = dash_pixels / WholeNotePixels;
            L2 = getNearestNoteLength(len);
            L1 = note_length - L2;
        }
        if (L1 == null || L2 == null || L1 <= 0 || L2 <= 0) {
            return [text, note_duration, '', ''];
        }
        else {
            let text_duration = getNoteDuration(L1);
            let dash_duration = getNoteDuration(L2);
            return [text, text_duration, dash_duration, '-'];
        }
    }
    else { // no room for extra spaces
        return [text+'-', note_duration, '', ''];
    }
            
}

// using major-scale names
const KeySignatureAccDict={
  'C':[],
  'F' :['Bb'],
  'Bb':['Bb','Eb'],
  'Eb':['Bb','Eb','Ab'],
  'Ab':['Bb','Eb','Ab','Db'],
  'Db':['Bb','Eb','Ab','Db','Gb'],
  'Gb':['Bb','Eb','Ab','Db','Gb','Cb'],
  'Cb':['Bb','Eb','Ab','Db','Gb','Cb','Fb'],
  'G': ['F#'],
  'D': ['F#','C#'],
  'A': ['F#','C#','G#'],
  'E': ['F#','C#','G#','D#'],
  'B': ['F#','C#','G#','D#','A#'],
  'F#':['F#','C#','G#','D#','A#','E#'],
  'C#':['F#','C#','G#','D#','A#','E#','B#'],
}

const KeySignatureNaturalDict={
  'C':[],
  'F' :['B'],
  'Bb':['B','E'],
  'Eb':['B','E','A'],
  'Ab':['B','E','A','D'],
  'Db':['B','E','A','D','G'],
  'Gb':['B','E','A','D','G','C'],
  'Cb':['B','E','A','D','G','C','F'],
  'G': ['F'],
  'D': ['F','C'],
  'A': ['F','C','G'],
  'E': ['F','C','G','D'],
  'B': ['F','C','G','D','A'],
  'F#':['F','C','G','D','A','E'],
  'C#':['F','C','G','D','A','E','B'],
}

export function cumulativeSum(arr) {
    return arr.reduce((acc, currentValue, index) => {
        if (index === 0) {
            acc.push(currentValue);
        } else {
            acc.push(currentValue + acc[index - 1]);
        }
        return acc;
    }, []);
}