import React from 'react';
import Fragment from './Fragment';

function Stash({ stash, onDropFragment, playSegment }) {
  const onDragStart = (e, fragment) => {
    e.dataTransfer.setData('fragment', JSON.stringify(fragment)); // Store the fragment being dragged as string
  };

  const onDrop = (e) => {
    e.preventDefault(); // Prevent default behavior
    const fragment = JSON.parse(e.dataTransfer.getData('fragment')); // Retrieve and parse the fragment
    onDropFragment(fragment); // Call the handler to move fragment to the stash
  };

  const onDragOver = (e) => {
    e.preventDefault(); // Allow fragment to be dropped into the stash
  };

  return (
    <div className="stash" onDrop={onDrop} onDragOver={onDragOver}>
      <h3></h3>
      {stash.map((fragment, index) => (
        <Fragment
            key={index}
            fragment={fragment}
            onDragStart={onDragStart}
            playSegment={playSegment}
            isDraggable={true}
        />
      ))}
    </div>
  );
}

export default Stash;
