import React from 'react'
import { useState,  } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import RecordingTest from './recording_test';
//import UserProfile from './user_profile';
import { ContainedButton, OutlinedButton, } from './customized_components';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';
import { Paper, Typography } from '@mui/material';

function Onboarding(props) {
    const lang = props.lang;

    const [view, setView] = useState('intro');
    const [passed, setPassed] = useState(false);

    const resetViewCallback = (passed) => {
        setView('intro');
        if (passed) {
            axiosInstance.post('/api/user_settings/',{passed_recording_test: 1}).then();
            setPassed(true);
        }
    };

    const lang_dir = lang=='he' ? 'rtl' : 'ltr';

    return (
    <div style={{flex:1, }}>
        { view =='intro' &&
            <div  dir={lang_dir} style={{margin:'40px'}} >
                <Typography variant='h3' gutterBottom >
                        {Tabstext.WelcomeTab[lang]} {props.user_firstname}
                </Typography>
                 <div className='paleBlueBg' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', marginTop: '20px' }} >
                    <Typography variant='h6' gutterBottom >
                        {!passed ? Tabstext.BeforeYouStart[lang]: Tabstext.AllSet[lang]}
                    </Typography>
                </div>
                {!passed &&
                    <Paper className='shortPaper' >
                        <Typography  style={{padding:'20px'}} >{Tabstext.RecordingQualityExplained[lang]}</Typography>
                        <OutlinedButton startIcon={<MicIcon />} onClick={() => setView('recording_test')} >
                            {Tabstext.RecordingTest[lang]}
                        </OutlinedButton>
                    </Paper>
                }
                {passed &&
                        <Paper className='shortPaper' style={{ padding: '20px' }} >
                            <img src='/static/icons/bird1.svg' alt="Image" width="35" height="35" />
                            <ContainedButton onClick={() => props.setPassedRectestCallback(1)} >
                                {Tabstext.GotoDashboardTab[lang]}
                            </ContainedButton>
                    </Paper>
                }

            </div>
        }
        {view =='recording_test' &&
            <RecordingTest lang={props.lang} windowSize={props.windowSize}
                setPassedRectestCallback={props.setPassedRectestCallback}
                resetViewCallback={resetViewCallback}
            />
        }
    </div>
    );
}

export default Onboarding;