import React from 'react'

const Tabstext = {

    SignInTab : {
        'en': 'Login',
        'he': 'כניסה',
        'ro': 'Conectare',
    },
    SignOutTab : {
        'en': 'Logout',
        'he': 'יציאה',
        'ro': 'Deconectare',
    },
    ProfileUpdate : {
        'en': 'Update your profile (optional)',
        'he': "עדכן/י את הפרופיל שלך (אופציונלי) ",
        'ro': 'Actualizează profilul (opțional)',
    },
    ProfileTab : {
        'en': 'Profile',
        'he': "פרופיל  ",
        'ro': 'Profilul',
    },
    AsStudentTab : {
        'en': 'Student Mode',
        'he': "מצב תלמיד",
        'ro': 'Modul Student',
    },
    AsTeacherTab : {
        'en': 'Teacher Mode',
        'he': "מצב מורה",
        'ro': 'Modul profesor',
    },
    AsStudentTooltipConditional : {
        'en': 'Switch on to experience Solfy like a student (you need to pass Recording Test first)',
        'he': "הפעל/י כדי להתנסות בסולפי כמו תלמיד/ה (יש לעבור בדיקת הקלטה תחילה)",
        'ro': 'Comutați pentru a experimenta Solfy ca un student (mai întâi trebuie să treceți testul de înregistrare)',
    },
    AsStudentTooltip : {
        'en': 'Switch on to experience Solfy like a student',
        'he': "הפעל/י כדי להתנסות בסולפי כמו תלמיד/ה ",
        'ro': 'Comutați pentru a experimenta Solfy ca un student ',
    },
    ExitStudentTooltip : {
        'en': 'Disable to exit student mode', //  'Return to Teacher Mode'
        'he': "הפסק/י כדי לצאת ממצב תלמיד/ה",
        'ro': 'Comutați pentru a ieși din modul student', // 'Reveniți la Modul Profesor '
    },
    SelectLyricTab : {
        'en': 'Please select the exercise type: ',
        'he': "אנא בחרו את סוג התרגילים: ",
        'ro': 'Selectarea tipului de activitate: ',
    },
    RegisterTab : {
        'en': 'Register',
        'he': "הרשמה",
        'ro': 'Înscriere',
    },
    GetStartedTab : {
        'en': 'Get Started for Free',
        'he': "התחל/י ללא תשלום",
        'ro': 'Să începem, gratuit',
    },
    RegisteredProceed : {
        'en': 'Registered users Login',
        'he': "כניסה למשתמשים רשומים",
        'ro': 'Conectare, pentru utilizatorii înscriși',
    },
    GetStartedTeacher : {
        'en': 'Get Started as a Teacher',
        'he': "התחל/י כמורה",
        'ro': 'Începeți ca profesor',
    },
    ForTeachersTab : {
        'en': 'For Teachers',
        'he': "מורים/ות",
        'ro': 'Pentru Profesori',
    },
    ForStudentsTab : {
        'en': 'For Students',
        'he': "תלמידים/ות",
        'ro': 'Pentru Elevi',
    },
    UsernameTab : {
        'en': 'Username',
        'he': "שם משתמש",
        'ro': 'Nume de utilizator',
    },
    EmailAsUsernameTab : {
        'en': 'Your email (will serve as your username)',
        'he': " אימייל (יהיה גם שם המשתמש שלך)",
        'ro': 'E-mailul dvs. (va servi ca nume de utilizator)',
    },
    YourEmailTab : {
        'en': 'Your email',
        'he': " האימייל שלך",
        'ro': 'E-mailul dvs.',
    },
    EmailTab : {
        'en': 'email',
        'he': 'אימייל',
        'ro': 'e-mail',
    },
    FirstNameTab : {
        'en': 'Your first name',
        'he': 'שם פרטי',
        'ro': 'Nume propriu',
    },
    LastNameTab : {
        'en': 'Your last name',
        'he': 'שם משפחה',
        'ro': 'Nume de familie',
    },
    IamIndependent : {
        'en': 'I am an independent user',
        'he': 'אני משתמש/ת עצמאי/ת',
        'ro': 'Utilizator independent',
    },
    IamSchoolar : {
        'en': 'I am a pupil in a class or group',
        'he': 'אני תלמיד/ה בכיתה או בקבוצה ',
        'ro': 'Elev într-o clasă sau un grup',
    },
    IamTeacher : {
        'en': 'I am a school teacher',
        'he': 'אני מורה בבית ספר ',
        'ro': 'Cadru didactic',
    },
    PasswordTab : {
        'en': 'Password',
        'he': 'סיסמא',
        'ro': 'Parola',
    },
    OldPasswordTab : {
        'en': 'Old password',
        'he': 'סיסמא נוכחית',
        'ro': 'Parola veche',
    },
    NewPasswordTab : {
        'en': 'New password',
        'he': 'סיסמא חדשה',
        'ro': 'Parola nouă',
    },
    RepeatPasswordTab : {
        'en': 'Repeat Password',
        'he': 'הקלד/י שוב את סיסמא',
        'ro': 'Repetă parola',
    },
    VoiceRangeTab : {
        'en': 'Your voice range',
        'he': 'המנעד הקולי שלך',
        'ro': 'Ambitusul tău',
    },
    NativeLangTab : {
        'en': 'Your native language',
        'he': 'שפת האם שלך',
        'ro': 'Limba maternă',
    },
    YourGroupTab : {
        'en': 'Your group number',
        'he': 'מספר הקבוצה שלך',
        'ro': 'Numărul grupului dvs',
    },
    SubmitTab : {
        'en': 'Submit',
        'he': 'שלח',
        'ro': 'Trimite',
    },
    SaveTab : {
        'en': 'Save',
        'he': 'שמור',
        'ro': 'Salvează',
    },
    LetsStartTab : {
        'en': "Let's start",
        'he': 'בואו נתחיל',
        'ro': 'Să începem',
    },
    AgreeTab : {
        'en': 'I agree that my recordings will be used for improving Solfy',
        'he': 'אני מסכים/ה שההקלטות שלי ישמשו לשיפורה של סולפי',
        'ro': 'Sunt de acord ca înregistrările mele să fie folosite pentru îmbunătățirea programului Solfy',
    },
    DontHaveAnAccountTab : {
        'en': "Don't have an account?",
        'he': 'אין לך חשבון?',
        'ro': 'Nu aveți cont?',
    },
    HaveAnAccountTab : {
        'en': 'Already have an account?',
        'he': 'יש לך חשבון?',
        'ro': 'Aveți deja cont?',
    },
    SkipTab : {
        'en': 'Skip',
        'he': 'דלג',
        'ro': 'Renunț',
    },
    ForgotPasswordTab : {
        'en': 'Forgot my password',
        'he': 'שכחתי את הסיסמא',
        'ro': 'Am uitat parola',
    },
    UserCredentialsErrorTab : {
        'en': "Your username or password don't match",
        'he': 'שם המשתמש או הסיסמא לא תואמים',
        'ro': 'Numele de utilizator sau parola nepotrivite',
    },
    ChangeProfeDetailsTab : {
        'en': "Update your profile",
        'he': 'עדכן/י את הפרופיל שלך',
        'ro': 'Actualizarea Profilul',
    },
    ChangePasswordTab : {
        'en': "Select a new password:",
        'he': 'בחר/י סיסמא חדשה:',
        'ro': 'Alegeți o nouă Parola:',
    },
    PasswordExpiredTab : {
        'en': "Your password has expired. Please choose a new one:",
        'he': 'תוקף הסיסמה שלך פג. אנא בחר סיסמא חדשה:',
        'ro': 'Parola a expirat. Alegeți una nouă:',
    },
    
    HomeTab : {
        'en': "Home",
        'he': 'בית',
        'ro': 'Acasă',
    },
    DashboardTab : {
        'en': "Dashboard",
        'he': 'לוח ',
        'ro': 'Bord',
    },
    GotoDashboardTab : {
        'en': "Go to your dashboard",
        'he': 'לך/י ללוח הבקרה ',
        'ro': 'Accesați tabloul de bord',
    },
    ContentsTab : {
        'en': "Contents",
        'he': 'תֹּכֶן',
        'ro': 'Conținut',
    },
    SolfegeTab : {
        'en': "Solfege",
        'he': 'סולפג',
        'ro': 'Solfegii',
    },
    TheoryTab: {
        'en': "Theory Drills",
        'he': 'תרגילים בתאוריה',
        'ro': 'Exerciții de teorie',
    },
    SongsTab : {
        'en': "Songs",
        'he': 'שירים',
        'ro': 'Cântece',
    },
    GamesTab: {
        'en': "Games",
        'he': 'משחקים',
        'ro': 'Games',
    },
    PracticeSolfegeTab : {
        'en': "Practice Solfege",
        'he': "תרגל/י סולפג",
        'ro': 'Exersează Solfegii',
    },
    PracticeSongsTab : {
        'en': "Practice Songs",
        'he': 'תרגל/י שירים',
        'ro': 'Exersează Cântece',
    },
    SolfegeContentTab : {
        'en': "Our Solfeges",
        'he': "הסולפג'ים שלנו",
        'ro': 'Solfegiile noastre',
    },
    SongsContentTab : {
        'en': "Our Songs",
        'he': 'השירים שלנו',
        'ro': 'Cântecele noastre',
    },
    MyStudentsTab : {
        'en': "Create and Track your Groups",
        'he': 'צור/י ועקוב/י אחרי הקבוצות שלך',
        'ro': 'Creați și urmăriți grupurile dvs.',
    },
    MyProgressTab : {
        'en': "Your Progress",
        'he': 'ההתקדמות שלך',
        'ro': 'Progresul tău',
    },
    ViewYourProgressTab : {
        'en': "View your Progress",
        'he': 'צפה/י בהתקדמות שלך',
        'ro': 'Urmărește Progresul tău',
    },
    PerformanceScoreRulesTab : {
        'en': "How you earn points",
        'he': 'איך צוברים נקודות',
        'ro': 'Cum câștigi puncte',
    },
    SolfegeModeName : {
        'en': "Solfeges",
        'he': "סולפג'ים",
        'ro': 'Solfegii',
    },
    SingingModeName: {
        'en': "Songs",
        'he': 'שירים',
        'ro': 'Cântece',
    },
    YourSchoolTab : {
        'en': "Your school name",
        'he': 'שם בית הספר בו את/ה מלמד/ת',
        'ro': 'Numele școlii dvs.',
    },
    YourLocationTab : {
        'en': "Your school location (county or city)",
        'he': 'מקום בית הספר (מדינה או עיר)',
        'ro': 'Locația școlii (județ sau oraș)',
    },
    CreateYourGroupsTab : {
        'en': "Create your groups",
        'he': 'צרו קבוצות',
        'ro': 'Creați-vă grupurile de elevi',
    },
    CreateGroupTabShort : {
        'en': "Class name",
        'he': 'שם הכיתה:',
        'ro': 'Numele clasei',
    },
    CreateGroupTabLong : {
        'en': "Create a group in the below format",
        'he': 'צרו קבוצה בפורמט להלן ',
        'ro': 'Creați un grup în formatul de mai jos',
    },
    GroupFormatTabLong : {
        'en': "location.school.class",
        'he': 'כיתה.בית-ספר.מקום',
        'ro': 'locația.școala.clasa',
    },
    GroupFormatTabShort : {
        'en': "Example: 3B",
        'he': 'לדוגמא: ג1',
        'ro': 'Exemplu: 3B',
    },
    SelectAgeGroupTab : {
        'en': "Please select an age group",
        'he': 'בחר/י קבוצת גיל',
        'ro': 'Vă rugăm să selectați o grupă de vârstă',
    },
    GroupWithtNameExists : {
        'en': 'A group with this name already exists. Please use another name',
        'he': 'קבוצה בשם זה כבר קיימת. אנא בחר/י שם אחר',
        'ro': 'Un grup cu acest nume există deja. Încercați cu un alt nume',
    },
    group_invalid_message : {
        'en': 'The group in the link you provided does not exist. Please select a group',
        'he': 'הקבוצה הרשומה בכתובת שהכנת לא קיימת. אנא בחר/י קבוצה מהרשימה.',
        'ro': 'Grupul din linkul pe care l-ați furnizat nu există. Vă rugăm să selectați un grup',
    },
    email_invalid_message : {
        'en': 'The E-mail address does not exist',
        'he': 'כתובת האימייל הזו לא קיימת',
        'ro': 'Această adresă de e-mail nu există',
    },
    email_exists_message : {
        'en': 'A user with that Email already exists',
        'he': 'משתמש עם האימייל הזה כבר קיים',
        'ro': 'Un utilizator cu acest e-mail deja există',
    },
    username_exists_message : {
        'en': 'A user with that username already exists',
        'he': 'משתמש בשם זה כבר קיים',
        'ro': 'Un utilizator cu acest nume există deja',
    },
    incorrect_password_message : {
        'en': 'Password is incorrect',
        'he': 'הסיסמא לא נכונה',
        'ro': 'Parola incorectă ',

    },
    unequal_password_message : {
        'en': 'The two passwords are not equal',
        'he': 'שתי הסיסמאות לא זהות',
        'ro': 'Parolele nu sunt identice',
    },
    password_invalid_message : {
        'en': 'Password must contain at least one digit, one lowercase and one uppercase letter, and be at least 8 characters long',
        'he': 'סיסמא צריכה להכיל לפחות 8 תוים עם לפחות ספרה אחת, אות אנגלית קטנה אחת ואות גדולה אחת',
        'ro': 'Parola trebuie să conțină cel puțin o cifră, o literă mică și una mare și să aibă cel puțin 8 caractere',
    },
    last3_passwords_message : {
        'en': 'A new password should not be one of your last 3 passwords',
        'he': 'סיסמא חדשה לא יכולה להיות אחת משלוש הסיסמאות האחרונות שלכם',
        'ro': 'O nouă parolă nu poate fi una dintre ultimele 3 parole folosite',
    },
    Under12Tab : {
        'en': "Under 12",
        'he': 'עד 12',
        'ro': 'Sub 12',
    },
    Above16Tab : {
        'en': "Above 16",
        'he': 'מעל 16',
        'ro': 'Peste 16',
    },
    StudentTab : {
        'en': "Username",
        'he': 'שם משתמש',
        'ro': 'Nume de utilizator',
    },
    FirstLastNameTab : {
        'en': "Student name",
        'he': 'שם התלמיד/ה',
        'ro': 'Numele elevului',
    },
    NumRecsTab : {
        'en': "Total Recordings",
        'he': 'סה"כ הקלטות',
        'ro': 'Total Înregistrări',
    },
    RecordingsTab : {
        'en': "Recordings",
        'he': 'הקלטות',
        'ro': 'Înregistrări',
    },
    ScoreTab : {
        'en': "Score",
        'he': 'ציון',
        'ro': 'Punctaj',
    },
    RecsLastMonthTab : {
        'en': "Last month",
        'he': 'בחודש האחרון',
        'ro': 'Luna trecuta',
    },
    FailedLastMonthTab : {
        'en': "Failed",
        'he': 'נכשלו',
        'ro': 'Eșuate',
    },
    CreatedOnTab : {
        'en': "Last recording on",
        'he': 'הקלטה אחרונה ב',
        'ro': 'Ultima înregistrare în',
    },
    CumScoreTab : {
        'en': "Cumulated score",
        'he': 'ציון מצטבר',
        'ro': 'Punctajul acumulat',
    },
    ExerciseTab : {
        'en': "Exercise",
        'he': 'תרגיל',
        'ro': 'exercițiu',
    },
    LastExerciseTab : {
        'en': "Last Exercise",
        'he': 'תרגיל אחרון',
        'ro': 'Ultimul exercițiu',
    },
    BackToGroupsTab : {
        'en': "View all your groups",
        'he': 'צפייה בכל הקבוצות',
        'ro': 'Accesați lista grupurilor dvs.',
    },
    BackToGroupTab : {
        'en': "View all your students",
        'he': 'צפייה בכל התלמידים',
        'ro': 'Accesați lista elevilor dvs.',
    },
    BackToStudentTab : {
        'en': "View all exercises",
        'he': 'צפייה בכל התרגילים',
        'ro': 'Accesați lista exercițiilor',
    },
    AboutUsTab : {
        'en': "About us",
        'he': 'אודותנו',
        'ro': 'Despre',
    },
    ContactUsTab: {
        'en': "Contact us",
        'he': 'צרו קשר',
        'ro': 'Contactaţi-ne',
    },
    ContactUsText: {
        'en': "Send to us email at: ",
        'he': 'שילחו לנו מייל בכתובת: ',
        'ro': 'Trimite-ne e-mail la: ',
    },
    TutorialTab: {
        'en': "Tutorial",
        'he': 'הדרכה',
        'ro': 'Tutorial',
    },
    LyricsModeTab : {
        0: {
        'en': "Solfeges",
        'he': "סולפג'ים",
        'ro': 'Solfegii',
        },
        1: {
        'en': "Songs",
        'he': 'שירים',
        'ro': 'Cântece',
        },
    },
    DemoTab : {
        'en': "Solfege practicing Demo",
        'he': 'הדגמת תירגול סולפג',
		'ro': 'Practicând solfegii - exemplu',
    },
    StudentGuideTab : {
        'en': "Student guide",
        'he': 'מדריך לתלמיד',
        'ro': 'Ghid pentru elev',
    },
    TeacherGuideTab : {
        'en': "Teacher guide",
        'he': 'מדריך למורה',
        'ro': 'Ghid pentru profesor',
    },
    ContactTab : {
        'en': "Contact us: 4solfy@gmail.com",
        'he': '4solfy@gmail.com :צרו קשר',
		'ro': 'Adresa de contact: 4solfy@gmail.com ',
    },
    CancelTab : {
        'en': "Cancel",
        'he': 'בטל',
        'ro': 'Anulare',
    },
    BackToUserTab : {
        'en': "Back to your profile",
        'he': 'חזרה לפרופיל שלך',
        'ro': 'Înapoi la profilul tău',
    },
    MyRecordingGood : {
        'en': "My recording is good",
        'he': 'ההקלטה שלי טובה',
        'ro': 'Înregistrarea mea este bună',
    },
    PlayAgain : {
        'en': "I want to listen again",
        'he': 'אני רוצה להקשיב שוב',
        'ro': 'Vreau să ascult din nou',
    },
    MyRecordingBad : {
        'en': "Not good - record again",
        'he': 'לא טובה - הקלט/י שוב',
        'ro': 'Nesatisfăcător - inregistreaza din nou',
    },
    RecordingTestInstructions2 : {
        'en': "Check the below boxes or click on the yellow or red buttons to repeat listening or recording",
        'he': 'סמן את התיבות למטה או לחץ על הכפתור הצהוב או האדום כדי לחזור על ההאזנה או ההקלטה',
        'ro': 'Selectați căsuțele de mai jos sau faceți clic pe butoanele galbene sau roșii pentru a repeta ascultarea sau înregistrarea.',
    },
    ExerciseLastRecording : {
        'en': "Exercise (latest practice)",
        'he': 'תרגיל (תירגול אחרון)',
        'ro': 'Exercițiu (ultimul exercițiu)',
    },
    DateInTable : {
        'en': "Recorded on",
        'he': 'הוקלט בתאריך',
        'ro': 'Data înregistrării',
    },
    RecordingNumber : {
        'en': "Recording #",
        'he': '# הקלטה',
        'ro': 'Înregistrarea #',
    },
    GuideInTable : {
        'en': "Guide",
        'he': 'הנחיה קולית',
        'ro': 'Ghid sonor',
    },
    TempoInTable : {
        'en': "Tempo",
        'he': 'מהירות',
        'ro': 'Tempo',
    },
    ScoreInTable : {
        'en': "Score",
        'he': 'ציון',
        'ro': 'Punctaj',
    },
    PitchToleranceInTable : {
        'en': "Tolerance",
        'he': 'סטייה מותרת',
        'ro': 'Toleranță',
    },
    TimingInTable : {
        'en': "Timing Errors",
        'he': 'שגיאות במשך הצליל',
        'ro': 'Erori de durată',
    },
    SolfegeInTable : {
        'en': "Solfege Errors",
        'he': 'שגיאות בשמות התווים',
        'ro': 'Erori de nume de notă',
    },
    DynamicsInTable : {
        'en': "Dynamics Errors",
        'he': 'שגיאות בעוצמת הצליל',
        'ro': 'Erori de intensitate',
    },
    YourCurrentGroupsTab: {
        'en': "Your Groups",
        'he': 'הקבוצות שלך',
        'ro': 'Grupurile dvs.',
    },
    GroupTab: {
        'en': "Group",
        'he': 'קבוצה',
        'ro': 'Grup',
    },
    NumStudentsTab: {
        'en': "Registered students",
        'he': 'תלמידים רשומים',
        'ro': 'Elevi înscriși',
    },
    ActiveStudentsTab: {
        'en': "Active students",
        'he': 'תלמידים פעילים',
        'ro': 'Elevi activi',
    },
    ActiveLastMonthTab: {
        'en': "Active last month",
        'he': 'פעילים בחודש האחרון',
        'ro': 'Activi luna trecută',
    },
    GroupHoverTab: {
        'en': "Click to view your students",
        'he': 'לחץ/י לצפייה בתלמידים',
        'ro': 'Click pentru a vedea lista de elevi',
    },
    CopyLinkTab: {
        'en': "link",
        'he': 'קישור',
        'ro': 'Link',
    },
    GroupActionsTab: {
        'en': "Actions",
        'he': 'פעולות',
        'ro': 'Acțiuni',
    },
    inviteYourStudentsHover : {
        'en': 'Invite your students to register by sending them a link',
        'he': 'הזמנ/י את תלמידיך להירשם ע"י שליחת קישור',
        'ro': 'Invitați elevii dvs. să se înregistreze trimițându-le un link',
    },
    JoinGroupText1 : {
        'en': 'I invite you to join my group in Solfy:\n',
        'he': 'אני מזמין אותך להצטרף לקבוצה שלי בסולפי:\n',
        'ro': 'Te invit la grupul din Solfy:\n',
    },
    JoinGroupText2: {
        'en': '\nGroup number:\n',
        'he': '\nמספר הקבוצה:\n',
        'ro': '\nNumăr de grup:\n',
    },
    JoinGroupText3 : {
        'en': '\nClick on the following link to register:\n',
        'he': '\nלחצו על הקישור הבא להרשמה:\n',
        'ro': '\nFaceți clic pe următorul link pentru a vă înregistra:\n',
    },
    GroupDeleteHover : {
        'en': 'You may delete while the group is empty',
        'he': 'ניתן למחוק כל עוד הקבוצה ריקה',
        'ro': 'Puteți șterge grupul cât timp este gol',
    },
    GroupNameTab: {
        'en': "Group name",
        'he': 'שם הקבוצה',
        'ro': 'Numele Grupului',
    },
    NoSuchGroupTab: {
        'en': "No such group - recheck with your group owner",
        'he': 'אין קבוצה כזו - בדוק שוב עם המורה',
        'ro': 'Nu există un astfel de grup- verificați din nou cu proprietarul grupului dvs',
    },
    CreateGroupsTab: {
        'en': "Add a Group",
        'he': 'צרו קבוצה',
        'ro': 'Adăugați un grup',
    },
    UseTheFollowingFormatTab: {
        'en': "Please use the format 'teacher-name.location.school.class.year'",
        'he': '"אנא השתמש בתבנית "שם-המורה.מקום.בית-ספר.כיתה.שנה',
        'ro': 'Vă rugăm să utilizați formatul "nume-profesor.locația.școala.clasa.anul"',
    },
    AddTab : {
        'en': "Add",
        'he': 'הוסף/י',
        'ro': 'Adăuga',
    },
    DeleteTab : {
        'en': "Delete",
        'he': 'מחק/י',
        'ro': 'Șterge',
    },
    ScoresHistogram: {
        'en': "Scores Histogram for ",
        'he': 'היסטוגרמה של ציונים עבור ',
        'ro': 'Histograma Punctajelor pentru ',
    },
    AllYourGroups: {
        'en': "all your groups",
        'he': 'כל הקבוצות שלך',
        'ro': 'toate grupurile tale',
    },
    GroupsOfYear: {
        'en': "your groups of ",
        'he': ' הקבוצות שלך לשנת ',
        'ro': 'grupurile tale din ',
    },
    AgeGroupSelectTab : {
        'en': "Select an age group",
        'he': 'בחר את קבוצת הגיל המתאימה',
        'ro': 'Selectați o grupă de vârstă',
    },
    HistogramYlabel : {
        'en': "No. exercises",
        'he': 'מספר התרגילים',
        'ro': 'Nr. de exerciții',
    },
    PassedExercises : {
        'en': " recordings with passing scores",
        'he': ' הקלטות עם ציון עובר ומעלה',
        'ro': ' înregistrări cu punctaj de trecere',
    },
    NotPassedRecordings : {
        'en': " recordings with non-passing scores",
        'he': '  הקלטות עם ציון לא עובר',
        'ro': ' înregistrări cu punctaj sub limita de trecere',
    },
    BadRecordings : {
        'en': " rejected recordings due to quality",
        'he': '  הקלטות שנדחו בגלל איכות הקלטה',
        'ro': ' înregistrări respinse din cauza calității',
    },
    ActiveUsers : {
        'en': " active students",
        'he': '  תלמידים פעילים',
        'ro': ' elevi activi',
    },
    TopGroups : {
        'en': "Top Groups in past ",
        'he': '  קבוצות שהשיגו את ההתקדמות הרבה ביותר ב',
        'ro': 'Cele mai bune grupuri din ultimele ',
    },
    TopPerformers : {
        'en': "Top Performers in the past ",
        'he': 'תלמידים שהשיגו את ההתקדמות הרבה ביותר ב ',
        'ro': 'Cei mai buni performeri din ultimele ',
    },
    Days : {
        'en': " days",
        'he': '  הימים האחרונים',
        'ro': ' de zile',
    },
    Attention : {
        'en': "Bringing to your Attention in the past ",
        'he': 'לתשומת לבך ב ',
        'ro': 'Atenție la rezultate din ultimele ',
    },
    TeacherNameTab : {
        'en': "Teacher name",
        'he': 'שם המורה',
        'ro': 'Numele profesorului',
    },
    LocationTab : {
        'en': "Location",
        'he': 'מקום',
        'ro': 'Locația',
    },
    SchoolNameTab : {
        'en': "School name",
        'he': 'שם בית הספר',
        'ro': 'Numele școlii',
    },
    ClassNameTab : {
        'en': "Class name",
        'he': 'שם הכיתה',
        'ro': 'Numele clasei',
    },
    YearTab : {
        'en': "Year",
        'he': 'שנה',
        'ro': 'An',
    },
    SetTolerance : {
        'en': 'Tolerance',
        'he': 'סטייה מותרת',
        'ro': 'Toleranță',
    },
    ReanalyzeTab : {
        'en': 'Recompute the errors',
        'he': 'חישוב השגיאות מחדש',
        'ro': 'Recalculați erorile',
    },
    SettingsToleranceTooltip : {
        'en': 'Adjust the tolerated pitch deviation from now on',
        'he': 'כוונן/י את הסטייה המותרת בגובה הצליל מעכשיו והלאה ',
        'ro': 'Modificați devierea de înălțime tolerată de acum înainte',
    },
    SettingsToleranceForGroupTooltip : {
        'en': 'Adjust the tolerated pitch deviation for this group from now on',
        'he': 'כוונן/י את הסטייה המותרת בגובה הצליל לקבוצה זו מעכשיו והלאה ',
        'ro': 'Modificați devierea de înălțime tolerată pentru acest grup - de acum înainte',
    },
    FeedbackToleranceTooltip : {
        'en': 'Alter the tolerated pitch deviation and examine the effect on the feedback',
        'he': ' שנה/י את הסטייה המותרת בגובה הצליל ובחן את ההשפעה על המשוב ',
        'ro': 'Modificați devierea de înălțime tolerată și examinați efectul asupra feedback-ului',
    },
    PlayTab : {
        'en': "Listen",
        'he': 'הקשב/י',
        'ro': 'Ascultă',
    },
    PauseTab : {
        'en': "Pause",
        'he': 'הפסק/י',
        'ro': 'Pauză',
    },
    StopTab : {
        'en': "Stop",
        'he': 'עצור/י',
        'ro': 'Stop',
    },
    RecordTab : {
        'en': "Record",
        'he': 'הקלט/י',
        'ro': 'Înregistrează',
    },
    PlayWithAcc : {
        'en': "Play with Accompaniment",
        'he': 'נגנו עם ליווי',
        'ro': 'Redă cu Acompaniament',
    },
    PlayWithoutAcc : {
        'en': "Play without Accompaniment",
        'he': 'נגנו ללא ליווי',
        'ro': 'Redă fără Acompaniament',
    },
    AccRef : {
        'en': "Accompaniment",
        'he': 'ליווי',
        'ro': 'Acompaniament',
    },
    InstrumentRef : {
        'en': "MIDI Instrument",
        'he': 'נגן מידי',
        'ro': 'Instrument MIDI ',
    },
    VocalRef : {
        'en': "Vocal",
        'he': "קולי",
        'ro': 'Vocal',
    },
    score : {
        'en': "score",
        'he': 'תווים',
        'ro': 'Partitură',
    },
    lyrics : {
        'en': "lyrics",
        'he': 'מילים',
        'ro': 'Versuri',
    },
    InClassMode : {
        'en': "In Class",
        'he': 'בכיתה',
        'ro': 'In Clasă',
    },
    ClassToHomeTooltip : {
        'en': "Switch off to enable feedback (you need to pass Recording Test first)",
        'he': 'בטל כדי לקבל משוב (יש לעבור בדיקת הקלטה תחילה)',
        'ro': 'Anulați pentru a activa feedback-ul (mai întâi trebuie să treceți testul de înregistrare)',
    },
    HomeToClassTooltip : {
        'en': "Switch on to disable feedback",
        'he': 'הפעל כדי לא לקבל משוב',
        'ro': 'Confirmați dezactivarea feedback-ului',
    },
    LyricsScoreToggleTooltip: {
        'en': "Toggle between music score and lyrics",
        'he': 'החלף בין תווים ומילים',
        'ro': 'Comutați între partitura muzicală și versuri',
    },
    AtHomeMode : {
        'en': "At home",
        'he': 'בבית',
        'ro': 'Acasă',
    },
    WelcomeTab : {
        'en': "Welcome, ",
        'he': 'ברוכים הבאים, ',
        'ro': 'Bine ați venit, ',
    },
    BeforeYouStart : {
        'en': "Quick step before you can start using Solfy:",
        'he': 'צעד קטן לפני שאת/ה יכול/ה להתחיל להשתמש בסולפי:',
        'ro': 'Ultimul pas înaintea începerii practicii cu Solfy:',
    },
    AllSet : {
        'en': "All set!:",
        'he': 'הכל מוכן!',
        'ro': 'Suntem gata!',
    },
    RecordingQualityExplained : {
        'en': "Verify the recording quality with your device and headset to ensure reliable feedback. The headset is required to prevent the audio guide from entering the microphone and for your voice to sound clear.",
        'he': 'וודא/י את איכות ההקלטה עם האזניות והמיקרופון שלך להבטיח משוב אמין. האזניות דרושות כדי למנוע את כניסת האות המוביל לתוך המיקרופון וכדאי שהמיקרופון יהיה קרוב לפה כדי שקולך ייקלט באיכות טובה.',
        'ro': 'Verificați calitatea înregistrării cu casca pentru a asigura un feedback fiabil. Headset-ul este necesar pentru ca înregistrarea vocii să fie „curată,” nepermițând captarea ghidului audio în microfon.'
    },
    RecordingTest : {
        'en': "Recording Test",
        'he': 'בדיקת הקלטה',
        'ro': 'Test de înregistrare',
    },
    recordingTestWelcomePopover : {
        'en': 'Record yourself singing "do re mi fa sol ...", listen to your recording, and confirm it sounds good',
        'he': 'הקליט/י את עצמך שר/ה "דו רה מי פה סול ...", הקשיב/י להקלטה, וודא/י שהיא נשמעת באיכות טובה',
        'ro': 'Înregistrați-vă cântând „do re mi fa sol ...”, ascultați înregistrarea și confirmați că sună bine ',
    },
    recordingGuide1: {
        'en': 'Put on your headset and click on "Listen" to hear how this music score sounds',
        'he': 'הרכיבו אזניות עם מיקרופון ולחצו על "הקשב/י" כדי לשמוע איך נשמעים התווים האלה',
        'ro': 'Puneți-vă căștile și faceți clic pe „Ascultă” pentru a auzi cum sună acest exercițiu',
    },
    recordingGuide2: {
        'en': 'Click on "Record" and sing this music score along the audio guide',
        'he': 'לחצו על "הקלט/י" ושירו את התווים יחד עם הצליל המנחה',
        'ro': 'Faceți clic pe „Înregistrează” și cântați exercițiul împreună cu ghidul audio',
    },
    recordingGuide3: {
        'en': 'Listen to your recording and follow the instructions.',
        'he': 'הקשיבו להקלטה ועקבו אחרי ההוראות.',
        'ro': 'Ascultați înregistrarea dvs. și urmați instrucțiunile.',
    },
    next: {
        'en': 'next',
        'he': 'הבא',
        'ro': 'Continuă',
    },
    ready: {
        'en': 'I am ready to start',
        'he': 'אני מוכן/ה להתחיל',
        'ro': 'Sunt gata să încep',
    },
    recordingVerifyMessage_1: {
        'en': 'My voice sounds loud and clear through my headset',
        'he': 'קולי נשמע צלול וברור דרך האוזניות',
        'ro': 'Vocea mea sună tare şi clar prin căştile audio.',
    },
    recordingVerifyMessage_2 : {
        'en': 'The silence periods are quiet',
        'he': 'לא נשמע רעש בקטעי השקט',
        'ro': 'Nu se aud zgomote în timpul pauzelor muzicale.',
    },
    recordingVerifyMessage_3 : {
        'en': 'The sounds of the audio guide are not heard in the recording',
        'he': 'צלילי הנחיית האודיו לא נשמעים בהקלטה',
        'ro': 'Sunetele ghidului audio nu se aud în înregistrare.',
    },
    your_recordings_tab : {
        'en': "Your recordings",
        'he': 'ההקלטות שלך',
        'ro': 'Înregistrările efectuate',
    },
    feedback_tab : {
        'en': "Feedback",
        'he': 'משוב',
        'ro': 'Feedback',
    },
    reference_score_tab : {
        'en': "Reference score",
        'he': 'תווי המקור',
        'ro': 'Partitura de referință',
    },
    practice_tab : {
        'en': "Practice",
        'he': 'תירגול',
        'ro': 'Practică',
    },
    all_recordings_tab : {
        'en': "To all recordings",
        'he': 'לכל ההקלטות',
        'ro': 'Toate înregistrările',
    },
    FastTrackTab : {
        'en': "Fast track to ",
        'he': 'מסלול מהיר ל',
        'ro': 'Calea rapidă către ',
    },
    LastLessonTooltip: {
        'en': "You can unlock next level and all exercises on this level by passing all exercises in this lesson",
        'he': 'אתם יכולים לשחרר את הנעילה של הרמה הבאה ושל כל התרגילים ברמה הנוכחית ע"י השלמה מוצלחת של התרגילים בשיעור הזה',
        'ro': 'Poți debloca următorul nivel și toate exercițiile de la acest nivel trecând toate exercițiile din această lecție',
    },
    FullScreenTooltip: {
        'en': "Full screen",
        'he': 'מסך מלא',
        'ro': 'Ecran complet',
    },
    ExitFullScreenTooltip: {
        'en': "Exit full screen",
        'he': 'צאו ממסך מלא',
        'ro': 'Ieșiți din ecranul complet',
    },
    named_guide_tab: {
        'en': "Record with ",
        'he': ' הקלט/י עם ',
        'ro': 'Înregistrează cu ',
    },
    midi_guide_tab : {
        'en': "Record with a tonal guide",
        'he': 'הקלט/י עם צליל מנחה',
        'ro': 'Înregistrează cu un ghid tonal',
    },
    beats_guide_tab : {
        'en': "Record with beats",
        'he': 'הקלט/י עם מטרונום',
        'ro': 'Înregistrează cu metronom',
    },
    //midi_beats_guide_tab: {
    //    'en': "Record with a tonal guide & beats",
    //    'he': 'הקלט/י עם צליל מנחה ומטרונום',
    //    'ro': 'Înregistrează cu un ghid tonal și metronom',
    //},
    mute_guide_tab : {
        'en': "Record with no guide",
        'he': 'הקלט/י ללא הנחייה',
        'ro': 'Înregistrează fără ghid sonor',
    },
    orchestra_guide_tab : {
        'en': "Record with accompaniment",
        'he': 'הקלט/י עם ליווי',
        'ro': 'Înregistrează cu acompaniament',
    },
    vocal_guide_tab: {
        'en': "Record with vocal guide",
        'he': 'הקלט/י עם הנחייה קולית',
        'ro': 'Înregistrează cu un ghid vocal',
    },
    midi_guide : {
        'en': "Tonal",
        'he': 'צליל',
        'ro': 'Tonal',
    },
    beats_guide : {
        'en': "Metronome",
        'he': 'מֶטרוֹנוֹם',
        'ro': 'Metronom',
    },
    mute_guide : {
        'en': "No sound",
        'he': 'ללא הנחייה',
        'ro': 'Fără ghid sonor',
    },
    orchestra_guide : {
        'en': "Accompaniment",
        'he': 'ליווי',
        'ro': 'Acompaniament',
    },
    MicrophoneNotAllowedPopover : {
        'en': "You need to allow Solfy to use your microphone in the browser settings.",
        'he': 'יש לאפשר לסולפי שימוש במיקרופון בהגדרות של סייר האינטרנט',
        'ro': 'Solfy solicită setărilor browserului permisiunea de a accesa microfonul.',
    },
    Language_flag_pairs : {
        'en': 'gb',
        'he': 'il',
        'ro': 'ro',
    },
    LanguageName : {
        'en': {'en':'English','ro':'Romanian','he':'Hebrew'},
        'he': {'en':'אנגלית','ro':'רומנית','he':'עברית'},
        'ro': {'en':'Engleză','ro':'Română','he':'Ebraică'},
    },
    SessionExpired : {
        'en': 'Session expired.You will be redirected to the landing page.',
        'he': 'פג תוקף החיבור האחרון. תופנה/י לדף הכניסה.',
        'ro': 'Sesiunea a expirat. Veți fi redirecționat către pagina de destinație.',
    },


};

export default Tabstext;