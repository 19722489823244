import { createTheme } from '@mui/material/styles';


export const SolfyTheme = createTheme({
    typography: {
        // Override typography settings here
        fontFamily: 'CircularXX-Regular, sans-serif',
        fontSize:15,
        h1: {
            fontSize: 48,
            fontWeight: 700,
        },
        h2: {
            fontSize: 40,
            fontWeight: 400,
        },
        h3: {
            fontSize: 32,
            fontWeight: 400,
        },
        h4: {
            fontSize: 24,
            fontWeight: 450
        },
        h5: {
            fontSize: 16,
            fontWeight: 600,
        },
        h6: {
            fontSize: 15,
            fontWeight: 500,
        },
        body1: {
            fontSize: 14,
            fontWeight: 400
        },
        subtitle: {
            fontSize: 12,
            fontWeight: 400
        },
    },
});

