import React, { useState, useEffect, } from "react";
import { Popover, } from '@mui/material';
import Tabstext from './tabstext';
import { CellLink, SmallTitle } from './customized_components';


function RecordingInstructions(props) {

    const [popoverText, setPopoverText] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const handleClose = () => {
        props.closeHelpCallback();
    };
    const handleNext = (anchor) => {
        if (anchor == props.anchorRef1.current && buttonText == Tabstext.next[props.lang]) {
            setAnchorEl(props.anchorRef2.current);
            setPopoverText(Tabstext.recordingGuide2[props.lang]);
        }
        else if (buttonText == Tabstext.next[props.lang]) {
            setAnchorEl(props.anchorRef1.current);
            setPopoverText(Tabstext.recordingGuide3[props.lang]);
            setButtonText(Tabstext.ready[props.lang])
        }
        else
            handleClose();
    };

    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        if (props.anchorRef1.current && props.openHelp) {
            setAnchorEl(props.anchorRef1.current);
            setPopoverText(Tabstext.recordingGuide1[props.lang]);
            setButtonText(Tabstext.next[props.lang])
        }
    }, [props.anchorRef1, props.openHelp]);

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <Popover
            open={props.openHelp && !!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            PaperProps={{
                style: { borderRadius: '12px', margin: '12px', maxWidth:'240px' }
            }}
            >
            <SmallTitle className='popOver' dir={lang_dir} >
                <div style={{display: 'flex', flexDirection:'column'}} >
                    {popoverText}
                    <CellLink
                        onClick={() => handleNext(anchorEl)}
                        style={{ color:'var(--blue-7)', fontWeight:'550'}}
                        >
                        {buttonText}
                    </CellLink>
                </div>
            </SmallTitle>
        </Popover>
    )
}

export default RecordingInstructions;

