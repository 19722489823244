import React from 'react'

const Messages = {

    mute_message : {
        'en': 'Your microphone seems to be muted - check your device sound settings.',
        'he': 'נראה שהמיקרופון נמצא במצב השתקה - בדוק את הגדרות השמע במחשב',
        'ro': 'Microfonul dumneavoastră pare să fie dezactivat - verificați setările de sunet ale dispozitivului.',
    },
    no_voice_message : {
        'en': 'It seems no voice was captured by your device - check your device sound settings or your headset.',
        'he': 'נראה שקולך לא נקלט במחשב - בדוק את הגדרות השמע במחשב או את האזניות והמיקרופון',
        'ro': 'Se pare că dispozitivul dumneavoastră nu a capturat nicio voce - verificați setările de sunet ale dispozitivului sau ale căștilor.',
    },
    rectest_echo_message: {
        'en': 'The audio guide may have entered into the recording and confused the feedback. It is recommended to use a headset. You can also try reducing the speaker volume and getting closer to the microphone.',
        'he': ' יתכן שהאות המוביל נכנס להקלטה ובלבל את המשוב. מומלץ להשתמש באוזניות עם מיקרופון. אפשר גם לנסות להנמיך את הרמקול ולהתקרב למיקרופון.',
        'ro': 'Este posibil ca ghidul audio să fi intrat în înregistrare și să fi stânjenit feedbackul. Se recomandă utilizarea unei căști audio cu microfon. De asemenea, puteți încerca să reduceți volumul difuzorului și să vă apropiați de microfon.',
    },
    snr_message: {
        'en': 'There is significant noise in the background or the audio guide entered into the recording. It is recommended to use a headset and record in a quiet room.',
        'he': 'יש הרבה רעשי רקע או שהאות המוביל נכנס להקלטה. מומלץ להשתמש באוזניות עם מיקרופון ולהקליט בחדר שקט',
        'ro': 'Se aude mult zgomot de fundal sau ca ghidul audio să fi intrat în înregistrare. Se recomandă utilizarea unei căști audio cu microfon și să înregistrați într-o cameră liniștită.',
    },
    echo_message: {
        'en': 'The audio guide may have entered into the recording and confused the feedback. It is recommended to use a headset or select "beats" from the record menu.',
        'he': ' יתכן שהאות המוביל נכנס להקלטה ובלבל את המשוב. מומלץ להשתמש באוזניות עם מיקרופון או לבחור "מטרונום" בתפריט ההקלטה',
        'ro': 'Este posibil ca ghidul audio să fi intrat în înregistrare și să fi stânjenit feedbackul. Se recomandă utilizarea unei căști audio cu microfon sau selectați „metronom” din meniul de înregistrare',
    },
    echo_or_noise_message: {
        'en': 'The audio guide may have entered into the recording and confused the feedback, or perhaps you started singing too early. It is recommended to use a headset.',
        'he': 'יתכן שהאות המוביל נכנס להקלטה ובלבל את המשוב, או שהתחלת לשיר מוקדם מדי. מומלץ להשתמש באוזניות עם מיקרופון.',
        'ro': 'Este posibil ca ghidul audio să fi intrat în înregistrare și să fi stânjenit feedbackul, sau poate ai început să cânți prea devreme. Se recomandă utilizarea unei căști audio cu microfon.',
    },
    too_many_errors_message : {
        'en': 'Too many errors were found in your recording for the feedback to be reliably displayed. You possibly made those mistakes, but it is also possible that the recording was distrupted in some way. Please play your recording and verify that your voice sounds clear and without any interference in the background',
        'he': 'יותר מדי שגיאות נמצאו בהקלטה מכדי להציג את המשוב באופן אמין. יתכן שאלו שגיאות שלכם, אבל גם יתכן שההקלטה הופרעה בדרך כלשהי. אנא נגנו את הקלטתכם וודאו שקולכם נשמע ברור וללא הפרעות כלשהן ברקע',
        'ro': 'Au fost găsite prea multe erori în înregistrarea ta pentru ca feedback-ul să fie afișat în mod fiabil. Este posibil să fi făcut aceste greșeli, dar este de asemenea posibil ca înregistrarea să fi fost întreruptă într-un fel. Te rugăm să redai înregistrarea și să verifici dacă vocea ta sună clar și fără interferențe în fundal.',
    },
    too_short_to_analyze : {
        'en': 'Too short to give feedback.',
        'he': 'קצר מדי למתן משוב.',
        'ro': 'Prea scurt pentru a da feedback.',
    },
    recording_was_interrupted : {
        'en': 'To obtain credit you should not interrupt the recording.',
        'he': 'כדי שהתרגיל יחשב, אל תפסיק/י את ההקלטה באמצע.',
        'ro': 'Pentru a obține credit, nu întrerupeți înregistrarea.',
    },
    recording_test_instructions_message : {
        'en': 'Please listen to your recording until the end, then approve its quality or click on "Practice" and record again.',
        'he': 'בבקשה הקשיבו להקלטה עד הסוף ואז אשרו שהיא באיכות טובה או לחצו על "תירגול" והקליטו שוב.',
        'ro': 'Ascultați vă rog înregistrarea până la final și aprobați-i calitatea sau faceți clic pe „Practică” și înregistrați din nou.',
    },
    low_score_message : {
        'en': 'You need 60 to pass to the next exercise. You scored',
        'he': 'נדרש ציון 60 למעבר לתרגיל הבא. השגת ציון',
        'ro': 'Ai nevoie de 60 pentru a trece la următorul exercițiu. Ai obținut',
    },
    pass_offtunehigh_message : {
        'en': 'You passed, but you are consistently singing a little too high. You scored',
        'he': 'עברת, אבל את/ה שר/ה בעקביות קצת גבוה מדי. השגת ציון',
        'ro': 'Ați reușit, dar cântați în mod constant un pic prea sus. Ai obținut',
    },
    pass_offtunelow_message : {
        'en': 'You passed, but you are consistently singing a little too low. You scored',
        'he': 'עברת, אבל את/ה שר/ה בעקביות קצת נמוך מדי.השגת ציון',
        'ro': 'Ați reușit, dar cântați în mod constant un pic prea jos. Ai obținut',
    },
    pass_message : {
        'en': 'Congratulations, you passed. You scored',
        'he': 'כל הכבוד, עברת. השגת ציון',
        'ro': 'Felicitări, ați reușit. Ai obținut',
    },
    outstanding_message : {
        'en': 'Outstanding performance! You scored',
        'he': 'ביצוע יוצא מן הכלל! השגת ציון',
        'ro': 'Performanță remarcabilă! Ai obținut',
    },
    accwin_message : {
        'en': 'Very good! You scored',
        'he': 'טוב מאוד! השגת ציון',
        'ro': 'Foarte bun! Ai obținut',
    },
    no_feedback_inclass : {
        'en': 'No feedback in class mode',
        'he': 'אין משוב במצב כיתה',
        'ro': 'Fără feedback în modul În Clasă',
    },
    no_feedback_this_exercise: {
        'en': 'No feedback for this exercise',
        'he': 'אין משוב לתרגיל זה',
        'ro': 'Fără feedback pentru acest exercițiu',
    },
   bug_message : {
        'en': "Sorry, an error has been encountered. Solfy's team has been notified. Please repeat the exercise.",
        'he': 'מצטערים, אירעה שגיאה. נשלחה הודעה לצוות סולפי. אנא חזרו על התרגיל',
        'ro': 'Ne pare rău, a apărut o eroare. Echipajul Solfy a fost sesizat. Vă rugăm să repetați exercițiul.',
    },
    bad_recording_message : {
        'en': "Sorry, there was a problem with the recording and Solfy's team has been notified. Please note that Solfy operates properly under Chrome, Edge, and Firefox only.",
        'he': "מצטערים, אירעה תקלה בהקלטה ונשלחה הודעה לצוות סולפי. לידיעתך, סולפי פועלת באופן תקין עם כרום, אדג' ופיירפוקס בלבד",
        'ro': 'Ne pare rău, a apărut o problemă cu înregistrarea și echipajul Solfy a fost sesizat. Vă rugăm să rețineți că funcționează corect numai în Chrome, Edge și Firefox.',
    },
    solfege_progress_scheme: {
        'en': 'The Solfege exercises are organized in a progressively increasing order of difficulty, with most lessons remaining locked until their predecessors are completed. While it is advisable to begin practicing from the first lesson, if you have prior proficiency you may choose to start with any of the initially unlocked lessons and progress from there.',
        'he': "תרגילי הסולפז' מסודרים ברמת קושי עולה, כאשר רוב השיעורים נשארים נעולים עד להשלמת השיעורים הקודמים להם. מומלץ להתחיל לתרגל מהשיעור הראשון, אך אם יש לכם כבר רמה מסוימת של מיומנות, תוכלו לנסות להתחיל מאחד מהשיעורים הלא נעולים ולהתקדם משם.",
        'ro': 'Exercițiile de solfegiu sunt organizate într-o ordine progresiv crescătoare de dificultate, majoritatea lecțiilor rămânând blocate până când lecțiile anterioare sunt completate. Deși este recomandabil să începeți să exersați cu prima lecție, dacă aveți deja o competență prealabilă, puteți alege să începeți cu oricare dintre lecțiile deblocate inițial și să progresați de acolo..',
    },
    solfege_progress_theachers: {
        'en': 'As a teacher, you have the freedom to access and practice any exercise of your choice. Students, however, are required to progress in a predefined order. Switch to "Student Mode" from the dashboard to view the lessons progress scheme for students.',
        'he': 'כמורה, יש לך את החופש לגשת לכל תרגיל שתרצה/י ולתרגל אותו. התלמידים, לעומת זאת, נדרשים להתקדם בסדר מוגדר מראש. עבור/י ל"מצב תלמיד" מלוח הבקרה כדי לצפות בתוכנית ההתקדמות של השיעורים עבור תלמידים.',
        'ro': 'Ca profesor, aveți libertatea de a accesa și exersa orice exercițiu doriți. Elevii, însă, sunt obligați să progreseze într-o ordine prestabilită. Comutați la "Modul Elev" din tabloul de bord pentru a vizualiza schema de progres a lecțiilor pentru elevi.',
    }

};

export default Messages