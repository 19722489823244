import React, { useState, useEffect } from "react";
import { useNavigate, } from 'react-router-dom';
import Tabstext from './tabstext';
import LangSelect from './lang_select';
import { Tabs, } from '@mui/material';
import { VerticalTab, LinkButton, DynLockIcon } from './customized_components';
import { ReactComponent as SolfyLogo } from '/static/icons/solfyLogo.svg';
import { ReactComponent as HomeIcon } from '/static/icons/home.svg';
import { ReactComponent as SolfegeIcon } from '/static/icons/solfege.svg';
import { ReactComponent as SongsIcon } from '/static/icons/songs.svg';
import { ReactComponent as TheoryIcon } from '/static/icons/theory.svg';
import { ReactComponent as UserIcon } from '/static/icons/user.svg';
import { ReactComponent as BooknoteIcon } from '/static/icons/booknote.svg';
import { ReactComponent as StudentsIcon } from '/static/icons/students.svg';

import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';


function VerticalHeader(props) {
    const [value, setValue] = useState('dashboard');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.setContentCallback(newValue);
    };
    useEffect(() => {
        if (props.content )
            setValue(props.content);
     }, [props.content]);

    const solfegeIcon = props.enableTabs ? <SolfegeIcon /> : <SolfegeIcon />;
    const songsIcon = props.enableTabs ? <SongsIcon /> : <SongsIcon /> ;
    const theoryIcon = props.enableTabs ? <TheoryIcon /> : <TheoryIcon />;

    if (!props.authenticated && value=='user')
        return null;

    var user_firstname = null
    if (props.userFullname)
        user_firstname = props.userFullname[0];

    return (
        <div className='verticalHeader' >
            <LinkButton onClick={() => navigate(`/`)} >
                <SolfyLogo />
            </LinkButton>
            <div style={{ display:'flex', flex:1, flexDirection: 'column', justifyContent: 'space-between' }} >
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    indicatorColor='transparent'
                    style={{ borderRight: 'none', marginTop:'20px',}}
                    >
                    <VerticalTab label={Tabstext.DashboardTab[props.lang]} value="dashboard" icon=<HomeIcon/> />
                    <VerticalTab label={Tabstext.SolfegeTab[props.lang]} value="solfege" icon={solfegeIcon}
                        disabled={!props.enableTabs}
                    />
                    <VerticalTab label={Tabstext.TheoryTab[props.lang]} value="theory" icon={theoryIcon}
                        disabled={!props.enableTabs}
                    />
                    <VerticalTab label={Tabstext.SongsTab[props.lang]} value="songs" icon={songsIcon}
                        disabled={!props.enableTabs}
                    />
                    {props.asStudent &&
                        <VerticalTab label={Tabstext.MyProgressTab[props.lang]} value="my_progress" icon={<BooknoteIcon />}
                            disabled={!props.enableTabs}
                        />}
                    {!props.asStudent &&
                        <VerticalTab label={Tabstext.MyStudentsTab[props.lang]} value="my_groups" icon={<StudentsIcon />}
                            disabled={!props.enableTabs}
                        />}
                    <VerticalTab label={Tabstext.GamesTab[props.lang]} value="games" icon={<CasinoOutlinedIcon />}
                        disabled={!props.enableTabs}
                    />
                    <VerticalTab label={user_firstname} value="user" icon=<UserIcon />
                        style={{marginTop:'auto'}}
                    />
                </Tabs>
                <div mt="auto" style={{ textAlign: 'center', width: 'inherit' }}>
                    <LangSelect
                        context = {props.headerContext}
                        lang = {props.lang} langChangeCallback={props.langChangeCallback}
                        />
                </div>
            </div>
        </div>
    );
}

export default VerticalHeader;
