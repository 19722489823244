import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import ToleranceSetting from './tolerance_setting';
import './styles/extras.css';
import { HeadTableCell, BodyTableCell, CellLink, PageTitle, BackArrowButton } from './customized_components';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper,  } from '@mui/material';
import { Typography, } from '@mui/material';


function GroupDetails(props) {
    const tableHeight = props.windowHeight*0.75

    const [myStudents, setMyStudents] = useState([]);

    const handleStudentSelect = (student) => {
        props.setPageCallback('studentDetails', {username: student.username, group: props.group})
    };
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.group) {
            setLoading(true);
            axiosInstance.get('/api/group_details/', { 'params': { 'group': props.group } })
                .then((response) => {
                    setMyStudents(Object.values(response.data.studentsInfo));
                    setPitchTolerance(response.data.groupInfo['pitch_tolerance']);
                    setLoading(false);
                });
        }
    }, [props.group,pitchTolerance]); // end useEffect

    const [pitchTolerance, setPitchTolerance] = useState(null);
    const setPitchToleranceCallback = (val) => {
        axiosInstance.post('/api/group_settings/',{group:props.group, pitch_tolerance: val}).then();
        setPitchTolerance(val);
    };

    if (loading || props.display == false || myStudents==null || pitchTolerance==null) {
        return <div></div>;
    }

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
        <div style={{ overflowY: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center", margin:'10px' }} >
                <BackToAllGroups lang={props.lang} setPageCallback={props.setPageCallback} />
                <PageTitle >
                    {props.group}
                </PageTitle>
                <ToleranceSetting
                    lang={props.lang}
                    setPitchToleranceCallback={setPitchToleranceCallback}
                    feedbackDisplay={false} groupDisplay={true}
                    pitchTolerance={pitchTolerance}
                    pitchToleranceRange={props.pitchToleranceRange}
                    tooltipText={Tabstext.SettingsToleranceForGroupTooltip[props.lang]}
                    display={true}
                />
            </div>
            <div className='tableWrapper'>
                <TableContainer component={Paper} style={{ boxShadow: 'none', maxHeight: `${tableHeight}px`}}>
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow >
                                <HeadTableCell style={{ borderRadius:'6px 0 0 6px'}} >
                                    {Tabstext.StudentTab[props.lang]}
                                </HeadTableCell>
                                <HeadTableCell >{Tabstext.FirstLastNameTab[props.lang]}</HeadTableCell>
                                <HeadTableCell >{Tabstext.NumRecsTab[props.lang]}</HeadTableCell>
                                <HeadTableCell >{Tabstext.RecsLastMonthTab[props.lang]}</HeadTableCell>
                                <HeadTableCell >{Tabstext.FailedLastMonthTab[props.lang]}</HeadTableCell>
                                <HeadTableCell >{Tabstext.CreatedOnTab[props.lang]}</HeadTableCell>
                                <HeadTableCell >{Tabstext.LastExerciseTab[props.lang]}</HeadTableCell>
                                <HeadTableCell style={{ borderRadius:'0 6px 6px 0', }}>
                                    {Tabstext.CumScoreTab[props.lang]}
                                </HeadTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myStudents.map(([pk,student], index) => (
                                <TableRow key={index}>
                                    <BodyTableCell >
                                        {student.total_recordings>0 &&
                                            <CellLink onClick={() => handleStudentSelect(student)} >
                                                <Typography style={{maxWidth:'10vw'}} className="ellipsis-text" > {student.username} </Typography>
                                            </CellLink>
                                        }
                                        {student.total_recordings==0 &&
                                            <Typography style={{maxWidth:'10vw'}} className="ellipsis-text" > {student.username} </Typography>
                                        }
                                    </BodyTableCell>
                                    <BodyTableCell style={{maxWidth:'10vw'}} className="ellipsis-text">{student.fullname}</BodyTableCell>
                                    <BodyTableCell >{student.total_recordings}</BodyTableCell>
                                    <BodyTableCell >{student.recordings_lastmonth}</BodyTableCell>
                                    <BodyTableCell style={{color: (student.failed_lastmonth>10?'red':'')}} >
                                        {student.failed_lastmonth>10?'>10':student.failed_lastmonth}
                                    </BodyTableCell>
                                    <BodyTableCell style={{maxWidth:'10vw'}} className="ellipsis-text">{student.created}</BodyTableCell>
                                    <BodyTableCell style={{maxWidth:'15vw'}} className="ellipsis-text">{student.exercise}</BodyTableCell>
                                    <BodyTableCell >{student.cum_score}</BodyTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}


function BackToAllGroups(props) {
    const lang = props.lang;
    const handleButtonClick = () => {
        props.setPageCallback('myGroups');
    };
    return  (
        <BackArrowButton onClick={handleButtonClick} >
            {Tabstext.YourCurrentGroupsTab[lang]}
        </BackArrowButton>
    )
}


export default GroupDetails;

