// axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
});

axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken';

const setupAxiosInterceptors = (onSessionExpired) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        onSessionExpired();
      }
      return Promise.reject(error);
    }
  );
};

export { axiosInstance, setupAxiosInterceptors };

