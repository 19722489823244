import React, {useRef} from 'react';

function Fragment({ fragment, onDragStart, playSegment, isDraggable }) {

  const handleDragStart = (e) => {
    console.log(fragment.startTime, fragment.dur);
    playSegment(fragment.startTime, fragment.dur)
    onDragStart(e, fragment);
  };



  return (
      <img
          src={`/media/games/${fragment.fragPath}`}
          alt={fragment.fragPath}
          draggable={isDraggable}
          onDragStart={(e) =>  handleDragStart(e)}
          className="fragment"
      />
  );
}

export default Fragment;
