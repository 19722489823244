import React from 'react'
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { axiosInstance, } from './axios_instance';
import ScorePage from './score_page';
import PracticeRecordingsSelect from './practice_recordings_select';
import { ExerciseContainer, } from './customized_components';


function RecordingForGuest(props) {
    const [context, setContext] = useState({});
    const [exe, setExe] = useState([]);
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const setFeedback = (data) => {
        setFeedbackData(data);
        setMusicScore(data.score);
    };
    const { '*': runid } = useParams();
    const run_pk = parseInt(runid);

    useEffect(() => {
        axiosInstance.get('/api/recording_for_guest/', { 'params': { 'run_pk': run_pk } })
            .then((response) => {
                if (response.data.context) {
                    setContext(response.data.context);
                    setExe(response.data.exe);
                    setFeedback(response.data.run_data)
                }
            })
            .catch((error) => {
                console.log('get exercise error:', error);
            });
    }, []);

    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const setFeedbackDisplayCallback = (val) => {
        setFeedbackDisplay(val);
        if (val)
            setMusicScore(feedbackData.score);
        else
            setMusicScore(exe.score);
   };
    const setMusicScoreCallback = (data) => {
        setMusicScore(data.score);
    }

    if (context==null || Object.keys(context).length === 0) {
        return null;
    }

    return (
        <ExerciseContainer >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <PracticeRecordingsSelect context={context} lang={props.lang} reviewMode={true}
                    feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                />
            </div>
            <ScorePage context={context} lang={props.lang}
                exe={exe} musicScore={musicScore}
                inExercisePage={false} feedbackData={feedbackData}
                inClass = {false}
                feedbackPresent={true} setFeedbackPresentCallback={[]}
                feedbackDisplay={true} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                setExerciseViewCallback={null}
                pitchToleranceRange={null}
                setMusicScoreCallback={setMusicScoreCallback}
                sidebarWidth = {0}
                windowSize={[window.innerWidth, window.innerHeight]}
                display={true}
                />
        </ExerciseContainer>
    );
}

export default RecordingForGuest;

