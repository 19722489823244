import React from 'react'
import { useState, } from "react";
import { axiosInstance, setupAxiosInterceptors } from './axios_instance';
import Tabstext from './tabstext';
import Onboarding from './onboarding';
import RecordingTest from './recording_test';
import StudentDashboard from './student_dashboard';
import TeacherDashboard from './teacher_dashboard';
import ZoomSelection from './zoom_selection';
import FullScreenButton from './fullscreen';
import { LinkButton } from './customized_components';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';
import { Typography, Switch, Tooltip } from '@mui/material';


function Dashboard(props) {
    const lang = props.lang;

    const [view, setView] = useState('dashboard');
    const resetViewCallback = (passed) => {
        setView('dashboard');
        if (passed) {
            axiosInstance.post('/api/user_settings/',{passed_recording_test: 1}).then();
            props.setPassedRectestCallback(1);
        }
    };

    const [selected, setSelected] = useState(props.asStudent);
    const handleAsStudent = () => {
        setSelected(!selected); // Invert the selected state
        axiosInstance.post('/api/user_settings/',{as_student: !selected}).then();
        props.setAsStudentCallback(!selected);
    };

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
        <>
        {view =='dashboard' &&
            <div style={{ flex:1,}}  >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {props.passedRectest==1 &&
                    <>
                        <LinkButton startIcon={<MicIcon />} onClick={() => setView('recording_test')} >
                            <div style={{marginRight:'10px'}}> {Tabstext.RecordingTest[lang]} </div>
                        </LinkButton>
                        {props.TeacherAttr &&
                            <div style={{ flex: 1, textAlign: 'center' }} dir='ltr' >
                                <Switch
                                    size='small'
                                    checked={selected}
                                    onChange={handleAsStudent}
                                    />
                                <Tooltip title=
                                    {props.asStudent? Tabstext.ExitStudentTooltip[lang]:
                                        (props.passedRectest==0? Tabstext.AsStudentTooltipConditional[lang]:Tabstext.AsStudentTooltip[lang])}
                                        placement="top">
                                        <Typography className='linkButtonLike' variant='h6' style={{ display: 'inline-block' }}>
                                        {Tabstext.AsStudentTab[lang]}
                                    </Typography>
                                </Tooltip>
                            </div>
                        }
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }} >
                                <ZoomSelection scale={props.scale} setScaleCallback={props.setScaleCallback} />
                                {/*<FullScreenButton lang={props.lang} />*/}
                            </div>
                    </>
                    }
                </div>
                {props.passedRectest==0 &&
                    <Onboarding
                        user_firstname={props.headerContext.user_firstname} lang={props.lang}
                        windowSize={props.windowSize}
                        asStudent={props.asStudent} setAsStudentCallback={props.setAsStudentCallback}
                        passedRectest={props.passedRectest} setPassedRectestCallback={props.setPassedRectestCallback}
                        TeacherAttr={props.headerContext.TeacherAttr}
                        />
                }
                {props.passedRectest==1 && props.asStudent &&
                    <StudentDashboard lang={props.lang}
                        setContentCallback = {props.setContentCallback}
                    />
                }
                {props.passedRectest==1 && props.TeacherAttr && !props.asStudent &&
                    <TeacherDashboard lang={props.lang}
                        setContentCallback = {props.setContentCallback}
                    />
                }
            </div>
        }
        {view =='recording_test' &&
             <RecordingTest lang={props.lang} windowSize={props.windowSize}
                setPassedRectestCallback={props.setPassedRectestCallback}
                resetViewCallback={resetViewCallback}
            />
       }
       </>
    );
}


export default Dashboard;
