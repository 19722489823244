import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReactCountryFlag from 'react-country-flag';

function SongsContent(props) {
    const SongClasses = props.SongClasses;
    const lang_select = props.headerContext.lang_select;
    if (SongClasses === null) {
        return null;
    }
    const countryCodes = ['IL','GB','SK','RO'];
    const courseIndexByLang = {'he':0,'en':1,'ro':3}
    const course_dir = ['rtl', 'ltr', 'ltr', 'ltr'];


    const Images = [
        [ // IL
            '/static/art/IL_01txt.svg',
            '/static/art/IL_02txt.svg',
            '/static/art/IL_03txt.svg',
            '/static/art/IL_04txt.svg',
            '/static/art/IL_05txt.svg',
            '/static/art/IL_06txt.svg',
            '/static/art/IL_07txt.svg',
            '/static/art/IL_08txt.svg',
        ],
        [ // EN
            '/static/art/EN_01txt.svg',
            '/static/art/Step_One.jpg',
            '/static/art/Step_Two.svg',
        ],
        [ // SK
            '/static/art/SK_01txt.svg',
        ],
        [ // RO
            '/static/art/RO_01txt.svg',
            '/static/art/RO_02txt.svg',
            '/static/art/RO_03txt.svg',
            '/static/art/RO_04txt.svg',
        ],
    ];

    const [i, setValue] = useState(courseIndexByLang[props.lang]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const numberOfMenus = Images[0].length;

    const initialAnchors = Array(numberOfMenus).fill(null);
    const initialOpenStates = Array(numberOfMenus).fill(false);

    const [anchors, setAnchors] = useState(initialAnchors);
    const [openStates, setOpenStates] = useState(initialOpenStates);

    const handleClick = (index, event) => {
        const newAnchors = [...anchors];
        newAnchors[index] = event.currentTarget;
        setAnchors(newAnchors);

        const newOpenStates = [...openStates];
        newOpenStates[index] = !openStates[index]; // Toggle the open state
        setOpenStates(newOpenStates);
    };

    const handleClose = (index, event) => {
        if(event.currentTarget.contains(event.target)) {
        const newOpenStates = [...openStates];
        newOpenStates[index] = false;
        setOpenStates(newOpenStates);
        }
    };

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    if (!SongClasses[0])
        return null;

    return (
        <div style={{ display: 'flex', overflowY: 'auto', overflowX: 'auto', padding: '20px', minHeight:'89vh'}}
            className='creamBgrd' dir = {lang_dir} >
            <div
                style={{
                    flex: 1, display: "flex", flexDirection: 'column', alignItems: 'center',
                    flex: '0 0 80px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}
                >
                <Tabs
                    orientation="vertical"
                    value={i}
                    onChange={handleChange}
                    style={{ borderRight: 'none', marginTop: '40px', }}
                    indicatorColor='transparent'
                >
                    {SongClasses.map((course, i) => (
                        <Tab value={i} key={`${i}a`}
                            style={{ marginBottom: '20px' }}
                            label={<ReactCountryFlag countryCode={countryCodes[i]} svg style={{ height: '48px', width: 'auto', }} />}
                        />
                    ))
                    }
                </Tabs>
            </div>
            <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'flex-start', marginTop: '40px' }} >
                {SongClasses[i].lesson_set.map((lesson, j_index) => (
                    <React.Fragment key={j_index}>
                        <div
                            style={{ margin: '0 4px' }}
                            >
                            <img src={Images[i][j_index]} alt="Image"
                                style={{ height: '150px', width: 'auto', cursor: 'pointer', backgroundColor:'transparent' }}
                                onClick={(event) => handleClick(j_index, event)}
                            />
                        </div>
                        <Menu
                            anchorEl={anchors[j_index]}
                            open={openStates[j_index]}
                            onClose={(event) => handleClose(j_index, event)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                            {lesson.songlesson_set.map((song, k) => (
                                <MenuItem key={`${i * 100 + j_index * 1000 + k}e`} dir={course_dir[i]}>
                                    {song.title}
                                </MenuItem>
                            ))}
                        </Menu>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default SongsContent;
