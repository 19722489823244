import React from 'react'
import ExerciseDescriptions_en from './exercise_descriptions_en'
import ExerciseDescriptions_he from './exercise_descriptions_he'
import ExerciseDescriptions_ro from './exercise_descriptions_ro'

const ExerciseDescriptions = {
    en : ExerciseDescriptions_en,
    he : ExerciseDescriptions_he,
    ro : ExerciseDescriptions_ro,
};

export default ExerciseDescriptions;