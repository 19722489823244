import React from 'react'

const ExerciseDescriptions_en = {
    'Level1_Lesson01_E01_sol4': 'Introducing the sound and note “sol”; quarter notes and quarter rests;',
    'Level1_Lesson01_E02_sol4': 'Quarters: sounds, notes, and rests; rhythmical patterns; ',
    'Level1_Lesson01_R01_sol4': 'Quarters: sounds, notes, and rests; rhythmical patterns; ',
    'Level1_Lesson01_R02_sol4_v1': 'The musical Staff: it is comprised of 5 lines (creating 4 spaces); ',

    'Level1_Lesson02_E01_mi4': 'Introducing the sound and note “mi”; quarter notes and rests;',
    'Level1_Lesson02_E02_mi4': 'Quarters: sounds, notes and rests; rhythmical patterns;',
    'Level1_Lesson02_R01_mi4': 'Quarters: sounds, notes, and rests; rhythmical patterns;',
    'Level1_Lesson02_R02_mi4': 'The “Sol” clef symbol: the Staff line which the clef wraps around is the place of the note “sol”. Sol is on the second line, and mi4 in the first line;',

    'Level1_Lesson03_E01_sol4-mi4': 'Combining “sol” and “mi” in different rhythmical patterns of quarter notes and rests; different pitches: sol and mi',
    'Level1_Lesson03_E02_sol4-mi4': 'Quarters: sounds, notes, and rests; rhythmical patterns, pitches: sol and mi. The music notation symbols are written in a Score;',
    'Level1_Lesson03_R01_sol4-mi4': 'Quarters: sounds, notes, and rests; rhythmical patterns, pitches: sol and mi;',
    'Level1_Lesson03_R02_sol4-mi4': 'Between each pair of bars are two quarters. This is the Time Signature of the solfege: the measure of two quarters "2/4";',

    'Level1_Lesson04_E01_sol4-mi4': 'Dynamics, or intensities: forte- f , for loud or strong sounds, and piano - p, for quiet, or soft sounds;',
    'Level1_Lesson04_E02_sol4-mi4': 'Quarters: sounds, notes, and rests; rhythmical patterns, pitches: sol and mi, dynamics: f- p;',
    'Level1_Lesson04_R01_sol4-mi4_v1': 'Quarters: sounds, notes, and rests; rhythmical patterns, pitches: sol and mi, dynamics: f- p;',
    'Level1_Lesson04_R02_sol4-mi4_v1': 'Quarters: sounds, notes, and rests; rhythmical patterns, pitches: sol and mi, dynamics: f- p;',

    'Level1_Lesson05_E01_sol4-mi4_v1': 'Ties is a symbol that connects the time-value of two notes of identical pitch; two quarters connected with a tie equal a half (of a whole note) that looks as an “empty” quarter note; f- p;',
    'Level1_Lesson05_E02_sol4-mi4_v1': 'Quarters: sounds, notes, and rests; ties forming half notes, f- p;',
    'Level1_Lesson05_R01_sol4-mi4_v1': 'Quarters: sounds, notes, and rests; half notes; f- p;',
    'Level1_Lesson05_R02_sol4-mi4_v1': 'Quarters: sounds, notes, and rests; half notes; f- p;',

    'Level1_Lesson06_E01_sol4-mi4': 'The symbol of a half note rest or a half rest. It is equal with two quarters, the whole value of a 2/4 measure;',
    'Level1_Lesson06_E02_sol4-mi4': 'Quarters and halves: sounds, notes, and rests; f- p;',
    'Level1_Lesson06_R01_sol4-mi4': 'Quarters: sounds, notes, and rests; half notes; f- p;',
    'Level1_Lesson06_R02_sol4-mi4': 'A solfege with 32 measures of two quarters each; quarter sounds, notes, and rests; half notes; sol and mi; f- p;',

    'Level1_Lesson07_E01_sol4-mi4': 'Review: quarters sounds, notes, and rests; rhythmical patterns; f - p. Practicing solfege in different modes: \
    1. Replacing rests with “body percussion sounds” (clapping, snipping, slapping hand/s against the thigh);\
    2. Singing in “canon”: create two groups of singers; one group, “the leader” begin singing until it will reach the measure nr. 3 (with the rests); \
       then, “the follower” group, begins from measure nr. 1, imitating the leader after two measures, until the end.',
    'Level1_Lesson07_E02_sol4-mi4': 'Review: quarters sounds, notes, and rests; rhythmical patterns; f - p. Practicing solfege in different modes: \
    1. Replacing rests with “body percussion sounds” (clapping, snipping, slapping hand/s against the thigh); \
    2. Singing in "canon": create two groups of singers; one group, “the leader” begin singing until it will reach the measure nr. 3 (with mi, sol); \
       then, “the follower” group, begins from measure nr. 1, imitating the leader after two measures, until the end.',
    'Level1_Lesson07_R01_sol4-mi4': 'Review: quarters sounds, notes, and rests; rhythmical patterns; f - p. Practicing solfege in different modes: \
    1. Replacing rests with “body percussion sounds” (clapping, snipping, slapping hand/s against the thigh);\
    2. Singing in “canon”: create two groups of singers; one group, “the leader” begin singing until it will reach the measure nr. 2 (with sol and a rest); \
    then, “the follower” begins from measure nr. 1, imitating the leader after one measure, until the end.',
    'Level1_Lesson07_R02_sol4-mi4': 'Review: quarters sounds, notes, and rests; rhythmical patterns; f - p. Practicing solfege in different modes:\
    1. Replacing rests with “body percussion sounds” (clapping, snipping, slapping hand/s against the thigh);\
    2. Singing in “canon”: create two groups of singers; one group, “the leader” begin singing until it will reach the measure nr. 4 (with mi and a rest); \
    then, “the follower” begins from measure nr. 1, imitating the leader after three measures, until the end.',

    'Level1_Lesson08_E01_mi4': 'Creating a syncope: changing the regular rhythmical flow by a tie, that connect the second quarter of a measure, with the first quarter from the following measure.',
    'Level1_Lesson08_E02_mi4': 'Practicing syncope. New: two adjacent ties connecting 3 quarters with the same pitch, create a new value, equal with three quarters, or a half-and-a-quarter value;',
    'Level1_Lesson08_R01_sol4-mi4': 'Quarters and halves: sounds, notes, and quarter rests; f – p; tie, syncope, and half-and-a-quarter value;',
    'Level1_Lesson08_R02_sol4-mi4': 'Quarters and halves: sounds, notes, and quarter rests; f – p; tie, syncope, and half-and-a-quarter value;',

    'Level1_Lesson09_E01_sol4-mi4': 'Quarters sounds, notes, and rests; f - p;',
    'Level1_Lesson09_E02_sol4-mi4': 'Quarters sounds, notes, and rests; f – p; tie; syncope;',
    'Level1_Lesson09_R01_sol4-mi4': 'Quarters sounds, notes, and rests; f – p; a downbeat (strong beat) and a rest;',
    'Level1_Lesson09_R02_sol4-mi4': 'A quarter rest and a upbeat (weak beat), create an offbeat (counter-time), and the upbeat become a strong beat',

    'Level1_Lesson10_E01_do4_v1': 'A new pitch: do; quarters sounds, notes, and rests; half notes;',
    'Level1_Lesson10_E02_do4': 'Quarters and halves sounds, notes, and rests; f – p;',
    'Level1_Lesson10_R01_mi4-do4': 'Rhythmical patterns with mi and do; f – p;',
    'Level1_Lesson10_R02_mi4-do4': 'Rhythmical patterns with mi and do; f – p;',

    'Level1_Lesson11_E01_sol4-mi4': 'Rhythmical patterns with sol and mi; f – p;',
    'Level1_Lesson11_E02_mi4-do4': 'Rhythmical patterns with mi and do; f – p;',
    'Level1_Lesson11_R01_sol4-mi4-do4': 'Rhythmical patterns with sol, mi and do; f – p; Singing in “canon”: create two groups of singers; \
  “the leader” begin singing until it will reach the measure nr. 5 (mi, a half note); \
  then, “the follower” begins from measure nr. 1, imitating the leader after four measures, until the end.',
    'Level1_Lesson11_R02_sol4-mi4-do4': 'Arpeggios: descendant (sol, mi, do) and ascendant (do, mi, sol)\
   Singing in “canon“: imitating “the leader“ after four measures;',

    'Level1_Lesson12_E01_sol4-mi4-do4': 'Rhythmical patterns; f – p;',
    'Level1_Lesson12_E02_sol4-mi4-do4': 'Rhythmical patterns; f – p;',
    'Level1_Lesson12_R01_sol4-mi4-do4': 'Rhythmical patterns; f – p; ties connecting a half and a quarter; \
    Singing in canon: imitating the leader after eight measures',
    'Level1_Lesson12_R02_sol4-mi4-do4': 'Rhythmical patterns; f – p; ties connecting a half and a quarter; \
    Singing in canon: imitating the leader after 16 measures;',

    'Level1_Lesson13_E01_sol4-mi4-do4': 'Rhythmical patterns; f – p; \
    Singing in canon: imitating “the leader“ after eight measures;',
    'Level1_Lesson13_E02_sol4-mi4-do4': 'Three connected quarters are equivalent to a half tied to a quarter',
    'Level1_Lesson13_R01_sol4-mi4-do4': 'Rhythmical patterns; f – p; ties connecting a half and a quarter; \
    Singing in canon: imitating the leader after eight measures;',
    'Level1_Lesson13_R02_sol4-mi4-do4': 'Rhythmical patterns; f – p; \
    Singing in canon: imitating the leader after eight measures;',

    'Level1_Lesson14_E01_re4_v2': 'New sound and note: “re“; rhythmical patterns; f – p; ',
    'Level1_Lesson14_E02_mi4-re4-do4_v1': 'Rhythmical patterns; f – p;',
    'Level1_Lesson14_R01_mi4-re4-do4_v1': 'Rhythmical patterns; f – p; anacrusis: a note which precedes the first (downbeat) quarter of a measure, in a musical phrase; ',
    'Level1_Lesson14_R02_mi4-re4-do4': 'Rhythmical patterns; f – p; anacrusis and syncope; ',

    'Level1_Lesson15_E01_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; syncope; ',
    'Level1_Lesson15_E02_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; syncope; ',
    'Level1_Lesson15_R01_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; ',
    'Level1_Lesson15_R02_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; ',

    'Level1_Lesson16_E01_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; ',
    'Level1_Lesson16_E02_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; ',
    'Level1_Lesson16_R01_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; ',
    'Level1_Lesson16_R02_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p; offbeat; ',

    'Level1_Lesson17_E01_sol4-mi4-re4-do4': 'Rhythmical patterns; f – p;',
    'Level1_Lesson17_E02_sol4-mi4-re4-do4': 'New durations: eighth notes; two eighth notes are equal with a quarter note; the eighth notes appears as beamed pair.\
     Rhythmical patterns; f – p;',
    'Level1_Lesson17_R01_sol4-mi4-re4-do4': 'Halves, quarters, eighths notes and quarter rests, and different rhythmic patterns; f – p;',
    'Level1_Lesson17_R02_sol4-mi4-re4-do4': 'Halves, quarters, eighths notes, qarter and eights rests, and different rhythmic patterns; f – p;',

    'Level1_Lesson18_E01_fa4_v2': 'New sound and note: “fa”; rhythmical patterns; f – p;',
    'Level1_Lesson18_E02_fa4_v1'	: 'New sound and note: fa; rhythmical patterns; f – p;',
    'Level1_Lesson18_R01_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns with halves and quarters; f – p;',
    'Level1_Lesson18_R02_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns with quarters and eighths; f – p;',

    'Level1_Lesson19_E01_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns with quarters; f – p;',
    'Level1_Lesson19_E02_sol4--do4'           :	'Rhythmical patterns with eighths; f – p;',
    'Level1_Lesson19_R01_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns with quarters and eighths; f – p;',
    'Level1_Lesson19_R02_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns with quarters and eighths; f – p;',

    'Level1_Lesson20_E01_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns, halves and quarters; syncope and tie; f – p;',
    'Level1_Lesson20_E02_sol4-fa4-mi4-re4-do4':	'Ascending scales; rhythmical patterns with quarters; f – p;',
    'Level1_Lesson20_R01_sol4-fa4-mi4-re4-do4':	'Rhythmical patterns, halves and quarters; f – p; preparing for singing Little Jonathan - children folk song;',
    'Level1_Lesson20_R02_sol4-fa4-mi4-re4-do4':	'Little Jonathan - children song; rhythmical patterns, halves and quarters; f – p;',

    'Level1_Lesson21_E01_sol4-fa4-mi4-re4-do4': 'Intervals: an interval is the difference in pitch between two notes and sounds. \
    Examples: from a note with the pitch do, to another note with the pitch do, the interval is called prima, or unison; the same for other pair of notes as re-re, mi-mi, fa-fa, sol-sol;',
    'Level1_Lesson21_E02_sol4-fa4-mi4-re4-do4': 'Intervals of prima, or unison: do-do, re-re, mi-mi, fa-fa, sol-sol. \
    After a successful performance of this solfege, try to identify and sing all “unison” intervals. Good luck!',
    'Level1_Lesson21_R01_sol4-fa4-mi4-re4-do4': 'Interval of “second” is the difference in pitch between two adjacent notes. Examples: do-re, re-do; re-mi, mi-re; mi-fa; fa-mi; fa-sol, sol-fa;',
    'Level1_Lesson21_R02_sol4-fa4-mi4-re4-do4': 'Intervals of second: do-re, re-do; re-mi, mi-re; mi-fa; fa-mi; fa-sol, sol-fa. \
    After a successful performance of this solfege, try to identify and sing all intervals of “second”. Good luck!',

    'Level1_Lesson22_E01_sol4-fa4-mi4-re4-do4': 'Intervals of thirds: do-mi, mi-do; re-fa, fa-re, mi-sol, sol-mi;',
    'Level1_Lesson22_E02_sol4-fa4-mi4-re4-do4': 'Intervals. \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson22_R01_sol4-fa4-mi4-re4-do4': 'Intervals. \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson22_R02_sol4-fa4-mi4-re4-do4': 'Intervals. \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',

    'Level1_Lesson23_E01_sol4--do4'           : 'Intervals. \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson23_E02_sol4-fa4-mi4-re4-do4': 'Intervals. \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson23_R01_sol4-fa4-mi4-re4-do4': '"Baraboi" – Romanian children’s folk song in quarters. Orchestra accompaniment, by composer Michael Dulitsky.\
    Intervals. After a successful performance of this song, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson23_R02_sol4-fa4-mi4-re4-do4': '"Baraboi" – Romanian children’s folk song in eighths. \
    After a successful performance of this song, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',

    'Level1_Lesson24_E01_sol4-fa4-mi4-re4-do4'   : 'Rhythmical patterns, halves and quarters notes and rests; f – p; \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson24_E02_sol4--do4'              : 'Rhythmical patterns, quarters and eighths; f – p. \
    After a successful performance of this solfege, try to identify and sing the intervals of prima (unison), second, and third. Good luck!',
    'Level1_Lesson24_R01_sol4-fa4-mi4-re4-do4'   : '"Ceata lui Pițigoi" – Romanian children’s folk song. The Orchestra accompaniment, composer Michael Dulitsky. \
    Intervals of fourth: do-fa, fa-do; re-sol, sol-re. After a successful performance of this solfege, try to identify and sing the intervals of fourth. \
    To reinforce the practice of intervals, return to Lesson 22, R01, and R02 ',
    'Level1_Lesson24_R02_sol4-fa4-mi4-re4-do4'   : '"Ceata lui Pițigoi" – Romanian children’s folk song, with quarters and eights. \
    Intervals of fourth: do-fa, fa-do; re-sol, sol-re. After a successful performance of this solfege, try to identify and sing the intervals of fourth. \
    To reinforce the practice of intervals, return to Lesson 22, R01, and R02 ',

    'Level1_Lesson25_E01_sol4--do4': 'Rhythmical patterns, quarters and eights; f – p;',
    'Level1_Lesson25_E02_sol4--do4': 'Rhythmical patterns, quarters and eighths notes, quarters rests;\
    Tie between a quarter and an eighth with the same pitch is equal with 3 eighths;',
    'Level1_Lesson25_R01_sol4-fa4-mi4-re4-do4'   : 'At the Mill at Dorohoi" – Romanian children’s folk song.\
    Rhythmical patterns, quarters and quarters rests;\
    To reinforce the practice of intervals, return to Lesson 22, R01, and R02 ',
    'Level1_Lesson25_R02_sol4--do4': 'At the Mill at Dorohoi – Romanian children’s folk song.\
    Rhythmical patterns, eighths notes and rests; f – p;\
    To reinforce the practice of intervals, return to Lesson 22, R01, and R02 ',

    'Level1_Lesson26_E01_sol4-fa4-mi4-re4-do4_v1': 'Rhythmical patterns, notes and rests; tie and syncope;',
    'Level1_Lesson26_E02_sol4-fa4-mi4-re4-do4'   : 'Interval of fifths: do-sol, sol-do. Quarters and eighths; preparations for the song “When the Saints Are Marching In”. \
    After a successful performance of this solfege, try to identify and sing the intervals of fifths (the pairs: do-sol and sol-do).',
    'Level1_Lesson26_R01_sol4-fa4-mi4-re4-do4_v1': 'Quarters and eighths notes and rests; tie between a quarter and an eighth and syncope with eighths.”Get up lazy guy” - children’s folk song. \
    After a successful performance, try to identify and sing the learned intervals in this song.\
    To reinforce the practice of intervals, return to Lesson 22, R01, and R02 ',
    'Level1_Lesson26_R02_sol4-fa4-mi4-re4-do4'   : 'Halves, quarters and eighths notes and rests; anacrusis, syncope.\
    Song: ”When the Saints Go Marching In”. After a successful performance, try to identify and sing the learned intervals in this song.\
    To reinforce the practice of intervals, return to Lesson 22, R01, and R02 ',

    'Level2_Lesson01_E01_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
  "Sol" note and sound in various rhythmic patterns',
    'Level2_Lesson01_E02_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
  "Sol" note and sound in various rhythmic patterns',
    'Level2_Lesson01_R01_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
  "Sol" note and sound in various rhythmic patterns',
    'Level2_Lesson01_R02_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
  "Sol" note and sound in various rhythmic patterns',

    'Level2_Lesson02_E01_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats; \
  "sol" and "mi" notes and sounds',
    'Level2_Lesson02_E02_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats; \
  "sol" and "mi" notes and sounds',
    'Level2_Lesson02_R01_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats; \
  "sol" and "mi" notes and sounds',
    'Level2_Lesson02_R02_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats;\
  "sol" and "mi" notes and sounds',

    'Level2_Lesson03_E01_do4_v1': 'Time signature 3/4; "do" note and sound in various rhythmic patterns',
    'Level2_Lesson03_E02_do4_v1': 'Time signature 3/4; "do" note and sound in various rhythmic patterns',
    'Level2_Lesson03_R01_sol4-mi4-do4': 'Time signature 3/4; "sol" "mi" and "do", \
  notes and sounds in various rhythmic patterns',
    'Level2_Lesson03_R02_sol4-mi4-do4': 'Time signature 3/4; "sol" "mi" and "do", \
  notes and sounds in various rhythmic patterns',

    'Level2_Lesson04_E01_re4_v1': 'Time signature 3/4; "re" note and sound in various rhythmic patterns',
    'Level2_Lesson04_E02_re4_v1': 'Time signature 3/4; "re" note and sound in various rhythmic patterns',
    'Level2_Lesson04_R01_sol4-mi4-re4-do4': 'Time signature 3/4; "sol" "mi" "re" and "do", \
  notes and sounds in various rhythmic patterns',
    'Level2_Lesson04_R02_sol4-mi4-re4-do4': 'Time signature 3/4; "sol" "mi" "re" and "do", \
  notes and sounds in various rhythmic patterns',

    'Level2_Lesson05_E01_fa4': 'Time signature 3/4; "fa" note and sound in various rhythmic patterns',
    'Level2_Lesson05_E02_fa4': 'Time signature 3/4; "fa" note and sound in various rhythmic patterns',
    'Level2_Lesson05_R01_sol4-fa4-mi4-re4-do4': 'Time signature 3/4; "sol" "fa" "mi" "re" and "do", \
  notes and sounds in various rhythmic patterns',
    'Level2_Lesson05_R02_sol4-fa4-mi4-re4-do4': 'Time signature 3/4; "sol" "fa" "mi" "re" and "do", \
  notes and sounds in various rhythmic patterns',

    'Level2_Lesson06_E01_sol4-fa4-mi4-re4-do4': 'Time signature 3/4; "sol" "fa" "mi" "re" and "do", \
  notes and sounds in various rhythmic patterns',
    'Level2_Lesson06_E02_sol4-fa4-mi4-re4-do4': 'Three-quarters connected are equal with a dotted half. \
  Time signature 3/4; "sol" "fa" "mi" "re" "do", notes and sounds in various rhythmic patterns',
    'Level2_Lesson06_R01_sol4--do4': 'Three-quarters connected are equal with a dotted half. \
  Time signature 3/4; "sol" "fa" "mi" "re" "do", notes and sounds in various rhythmic patterns',
    'Level2_Lesson06_R02_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
  notes and sounds in various rhythmic patterns',

    'Level2_Lesson07_E01_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
  notes and sounds. Rests of eighths',
    'Level2_Lesson07_E02_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
  notes and sounds',
    'Level2_Lesson07_R01_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
  notes and sounds',
    'Level2_Lesson07_R02_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
  notes and sounds',

    'Level2_Lesson08_E01_la4--la4_v2': 'Time signature 2/4; "la4" note and sound in various rhythmic patterns',
    'Level2_Lesson08_E02_la4--la4_v2': 'Time signature 3/4; "la4" note and sound in various rhythmic patterns',
    'Level2_Lesson08_R01_la4--do4': 'Time signature 2/4; the notes and sounds between la4 and do4. \
  "A-B-C" song',
    'Level2_Lesson08_R02_la4--do4': 'Time signature 2/4; the notes and sounds between la4 and do4. \
  "A-B-C" song, with rhythmic variation',

    'Level2_Lesson09_E01_la4--do4': 'Time signature 3/4; the notes and sounds between la4 and do4 in various rhythmic patterns',
    'Level2_Lesson09_E02_la4--do4_v1': 'Time signature 3/4; the notes and sounds between la4 and do4 in various rhythmic patterns',
    'Level2_Lesson09_R01_la4--do4': '"A-B-C" song, rhythmic variation 1',
    'Level2_Lesson09_R02_la4--do4': '"A-B-C" song, rhythmic variation 2',

    'Level2_Lesson10_E01_si3_v1': 'The note and sound si3 in various rhythmic patterns',
    'Level2_Lesson10_E02_si3':    'The note and sound si3 in various rhythmic patterns. Dotted quarter & eighth note rhythm',
    'Level2_Lesson10_R01_la4--si3': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',
    'Level2_Lesson10_R02_la4--si3': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',

    'Level2_Lesson11_E01_la4--si3': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',
    'Level2_Lesson11_E02_la4--do4': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',
    'Level2_Lesson11_R01_la4--si3': 'Various rhythmic patterns including dotted quarter + eighth, and rest of eighth note in 3/4',
    'Level2_Lesson11_R02_la4--si3': 'Various rhythmic patterns in 2/4. "Brother John" (traditional French song for children) \
  in Canon: imitating the leading voice after 4 measures',

    'Level2_Lesson12_E01_la3_v1': 'The note and sound la3, time signature 2/4, various rhythmic patterns',
    'Level2_Lesson12_E02_la3_v1': 'The note and sound la3, time signature 3/4, various rhythmic patterns',
    'Level2_Lesson12_R01_mi4--la3_v1': 'Various rhythmic patterns in 3/4. Romanian folk song "Crossing the hill to Baiutz"',
    'Level2_Lesson12_R02_mi4--la3_v1': 'Various rhythmic patterns in 2/4. Romanian folk song "Crossing the hill to Baiutz"',

    'Level2_Lesson13_E01_la4--la3': 'Intervals (the difference in pitch between two sounds): octave, seventh, sixth',
    'Level2_Lesson13_E02_la4--la3': 'Intervals (the difference in pitch between two sounds): octave, seventh, sixth',
    'Level2_Lesson13_R01_la4--la3': 'Russian folk song Liulinki, intervals. Analyze, name, and sing the intervals',
    'Level2_Lesson13_R02_la4--la3': 'Russian folk song Korobeiniki, intervals. Analyze, name, and sing the intervals',

    'Level2_Lesson14_E01_sol4--la3': 'Various rhythmic patterns and arpeggios in Do Major and la minor scale. In music theory,\
  "scale" is any graduated sequence of notes, tones, or intervals dividing an octave',
    'Level2_Lesson14_E02_sol4--si3': 'Various rhythmic patterns and arpeggios in Do Major and Sol Major scales. In music theory,\
  "scale" is any graduated sequence of notes, tones, or intervals dividing an octave',
    'Level2_Lesson14_R01_mi4--la3_v2': 'Arpeggio consists of at least two consecutive intervals of thirds. A variation in la minor of \
  "My country" song, by the Romanian composer Dumitru Chiriac. Analyze, name, and sing the intervals',
    'Level2_Lesson14_R02_la4--la3': 'Arpeggio consists of at least two consecutive intervals of thirds. "I look out from Doftana", \
  a Romanian folk song in la minor. Analyze, name, and sing the intervals',

    'Level2_Lesson15_E01_sol4--fad4--re4': 'Accidental: the note and sound fa#4 in various rhythmic patterns',
    'Level2_Lesson15_E02_fad4--fa4': 'Accidental: the note and sound fa#4 - continuation; fa♮ (becar, or natural), in various rhythmic patterns',
    'Level2_Lesson15_R01_la4-fa4-fad4-re4': 'Fa♮ (becar, or natural) in re minor scale, and fa#4 in Re Major scale, in various rhythmic patterns',
    'Level2_Lesson15_R02_fad4--re4': 'Fa#4 in Re Major scale - in various rhythmic patterns',

    'Level2_Lesson16_E01_la4--fad4--re4': 'Practicing Fa#4 in various rhythmic patterns. Analyze, name, and sing the intervals',
    'Level2_Lesson16_E02_la4--fad4--re4': 'Practicing Fa#4 in various rhythmic patterns. Analyze, name, and sing the intervals',
    'Level2_Lesson16_R01_la4--fad4--re4': 'Practicing Fa#4 in various rhythmic patterns. Analyze, name, and sing the intervals',
    'Level2_Lesson16_R02_la4--fad4--re4':  'The song "Little Jonathan" in Re Major scale. Analyze, name, and sing the intervals',

    'Level2_Lesson17_E01_la4--fad4--re4': 'Practice with various rhythmic patterns, including a dotted quarter and an eighth',
    'Level2_Lesson17_E02_la4--fad4--re4': 'Practice with various rhythmic patterns, including syncope',
    'Level2_Lesson17_R01_la4--fad4--re4': 'A variation of the song "My country" by the Romanian composer Dumitru Chiriac, in Re Major',
    'Level2_Lesson17_R02_la4--fad4--re4': 'Practice with various rhythmic patterns. Analyze, name, and sing the intervals ',

    'Level2_Lesson18_E01_si4_v2': 'The note and sound si4 in various rhythmic patterns',
    'Level2_Lesson18_E02_si4_v1': 'The note and sound si4 in various rhythmic patterns',
    'Level2_Lesson18_R01_si4--re4_v1': '"A-B-C" song and various rhythmic patterns in Re Major',
    'Level2_Lesson18_R02_si4--la3_v1': 'The song "Brother John" (traditional French song for children), \
  in Re Major. Canon: imitating to leading voice after 4 measures ',

    'Level2_Lesson19_E01_si4--mi4': 'Variation of the Romanian folk song "Crossing the hill to Baiutz" in 3/4 and mi minor. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson19_E02_si4--re4_v1': 'Variation 1 of the "A-B-C" song, in 2/4, in Re Major',
    'Level2_Lesson19_R01_si4--re4': 'Variation 2 of the "A-B-C" song, in 2/4, in Re Major',
    'Level2_Lesson19_R02_si4--mi4': 'Variation of the Romanian folk song "Crossing the hill to Baiutz" in 2/4 and mi minor',

    'Level2_Lesson20_E01_si4--si3': 'Practice intervals in mi minor: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
   Analyze, name, and sing the intervals',
    'Level2_Lesson20_E02_si4--si3': 'Practice intervals in mi minor: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
   Analyze, name, and sing the intervals',
    'Level2_Lesson20_R01_la4--re4': '"My dear mother" (music: Nahum Nardi, lyrics: Moshe Dafna) in re minor. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson20_R02_si4-mi4': '"My dear mother" (music: Nahum Nardi, lyrics: Moshe Dafna) in mi minor. \
  Analyze, name, and sing the intervals',

    'Level2_Lesson21_E01_la4--la3': 'Practice intervals on re minor: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
   Analyze, name, and sing the intervals',
    'Level2_Lesson21_E02_la4--la3': 'Practice intervals in Re Major: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
   Analyze, name, and sing the intervals',
    'Level2_Lesson21_R01_si4--re4': 'The song "Take Caramels" in Re Major. Analyze, name, and sing the intervals',
    'Level2_Lesson21_R02_la4--do4': 'The song "Take Caramels" in Mi Major. Analyze, name, and sing the intervals',

    'Level2_Lesson22_E01_la4--la3': 'Practice intervals in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson22_E02_la4--la3': 'Practice intervals in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson22_R01_la4--la3_v1': '"What good and pleasant it is" - Israeli folk song, words from Psalms. \
  Canon: imitating the leading voice after 16 measures',
    'Level2_Lesson22_R02_la4--la3_v1': 'What good and pleasant it is" - Israeli folk song, words from Psalms. \
  Canon: imitating the leading voice after 16 measures. Different rhythmic patterns',

    'Level2_Lesson23_E01_do5_v2': 'The note and sound do5. Various rhythmic patterns',
    'Level2_Lesson23_E02_do5': 'The note and sound do5. Various rhythmic patterns',
    'Level2_Lesson23_R01_do5--do4': '"Dayenu", Passover Song. Quarters-based rhythmic patterns',
    'Level2_Lesson23_R02_do5--do4': '"Dayenu", Passover Song. Eighths-based rhythmic patterns',

    'Level2_Lesson24_E01_do5--do4': 'Practice intervals in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson24_E02_do5--do4': 'Practice intervals in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson24_R01_do5--mi4': 'Romanian folk song with halves and quarters',
    'Level2_Lesson24_R02_do5--mi4_v1': 'Romanian folk song with quarters and eighths. \
   Anacrusis: a note (or more), which precedes the first beat of the first complete measure',

    'Level2_Lesson25_E01_do5--do4': 'Practice intervals in Do Major, in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson25_E02_la4--la3': 'Practice intervals in la minor, in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson25_R01_do5--do4_v1': 'Quarter-based rhythmic patterns: "Can-Can" by Jacques Offenbach',
    'Level2_Lesson25_R02_do5--do4': 'Eights-based rhythmic patterns: "Can-Can" by Jacques Offenbach',

    'Level2_Lesson26_E01_la3--do5_v1': 'Practice intervals in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson26_E02_do5--la3_v1': 'Practice intervals in different rhythmic patterns. \
  Analyze, name, and sing the intervals',
    'Level2_Lesson26_R01_sol4--do4_v1': 'Excerpt from "Ode to Joy" by Ludwig van Beethoven, in Do Major',
    'Level2_Lesson26_R02_la4--la3_v1': 'Excerpt from "Ode to Joy" by Ludwig van Beethoven, in Re Major',

    'Level2_Lesson27_E01_mi4--la3': 'The sound and the note "do#4"; Rhythmic patterns in 3/4',
    'Level2_Lesson27_E02_la4--fad4-fa4--la3_v2': 'Rhythmic patterns in 3/4 with do#4 and do4',
    'Level2_Lesson27_R01_mi4--la3': 'Variations of the song "My country" by the Romanian composer Dumitru Chiriac \
  in 2 parts: 1. in la minor, 2. in La Major',
    'Level2_Lesson27_R02_fad4--la3': 'A rhythmic variation of the song "A-B-C", in La Major',

    'Level2_Lesson28_E01_si4--la3': 'A traditional canon-shaped song: "Give us peace." \
  Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',
    'Level2_Lesson28_E02_si4--re4': 'A traditional canon-shaped song: "Give us peace." \
  Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',
    'Level2_Lesson28_R01_la4--la3': 'A traditional canon-shaped song: "Give us peace." \
  Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',
    'Level2_Lesson28_R02_si4--la3': 'A traditional canon-shaped song: "Give us peace." \
  Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',

    'Level3_Lesson01_E01_la4--dod4': 'The sharp (♯), flat (♭), and natural (♮) symbols are called accidentals. Fa# and do#, symmetrical rhythmic patterns',
    'Level3_Lesson01_E02_fad4--dod4': 'The sharp (♯), flat (♭), and natural (♮) symbols are called accidentals. Fa# and do#, symmetrical rhythmic patterns',
    'Level3_Lesson01_R01_si4--la3': 'Excerpt 1 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
    'Level3_Lesson01_R02_si4--la3': 'Excerpt 2 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',

    'Level3_Lesson02_E01_si4--la3': 'Excerpt 3 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
    'Level3_Lesson02_E02_si4--la3': 'Excerpt 4 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
    'Level3_Lesson02_R01_si4--la3': 'Excerpt 1 & 2 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
    'Level3_Lesson02_R02_si4--la3': 'Excerpt 3 & 4 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',

    'Level3_Lesson03_E01_mi4--la3': 'Practicing with "Do#". Symmetrical rhythmic patterns',
    'Level3_Lesson03_E02_la4--re4': 'Practicing with "Fa#". Symmetrical rhythmic patterns',
    'Level3_Lesson03_R01_do5--la3': 'First excerpt from "Furry of Dawn" by the composer Yinon Tzur. Practice Fa sharp and natural. Asymmetrical rhythmic patterns',
    'Level3_Lesson03_R02_si4--la3': 'Second excerpt from "Furry of Dawn" by the composer Yinon Tzur. Practice Fa sharp and natural. Asymmetrical rhythmic patterns',

    'Level3_Lesson04_E01_sib4--sib3': 'Practicing the notes and sounds si♭4 and si♭3',
    'Level3_Lesson04_E02_do5--si3-sib3': 'Practicing the notes and sounds si♭4-si4 and si♭3-si3',
    'Level3_Lesson04_R01_do5--la3': 'Practicing the notes and sounds si♭4 and si♭3. Rhythmic patterns in 3/4',
    'Level3_Lesson04_R02_do5--la3': 'Practicing the notes and sounds si♭4 and si♭3. Rhythmic patterns in 2/4; syncope',

    'Level3_Lesson05_E01_do5--do4': 'Variation on 3/4 of the song "A-B-C" - in Fa Major',
    'Level3_Lesson05_E02_do5--do4': 'A new variation on 3/4 of the song "A-B-C" - in Fa Major',
    'Level3_Lesson05_R01_do5--do4': 'Variation on 3/4 of the romanian popular song "Baraboi" - in Fa Major ',
    'Level3_Lesson05_R02_do5--do4': 'Practicing in Fa Major ',

    'Level3_Lesson06_E01_do5-mib4-sib3': 'A new note and sound: mi♭. Intervals (defined as a distance in pitch between any two notes): \
  third, fourth, fifth, sixth. Analyze, sing and note the names of the intervals',
    'Level3_Lesson06_E02_sib4-mib4-sib3': 'Interval practice: third, fourth, fifth, sixth. Arpeggios \
  Analyze, sing and note the names of the intervals',
    'Level3_Lesson06_R01_sol4--sib3': 'The song "Brother Jacob" in Si♭ Major (French children song) \
  Singing in canon: imitating the leading group, after 2 measures',
    'Level3_Lesson06_R02_sol4--sib3': 'The song "Brother Jacob" in Si♭ Major (French children song) \
  Canon: the "second voice", that imitate the leading voice after 2 measures ',

    'Level3_Lesson07_E01_fa4--sib3': 'Rhythmic patterns in Si♭ Major. Analyze, sing and note the names of the intervals',
    'Level3_Lesson07_E02_sib4--mib4': 'Rhythmic patterns in Mi♭ Major. Analyze, sing and note the names of the intervals',
    'Level3_Lesson07_R01_sib4--la3': 'A folk song. Practice in re minor and Fa Major scales,',
    'Level3_Lesson07_R02_do5--si3': 'A folk song. Practice in mi minor and Sol Major scales',

    'Level3_Lesson08_E01_sib4--sib3': 'Rhythmic patterns in Mi♭ Major. Exercise with anacrusis and syncopes \
  Analyze, sing and note the names of the intervals',
    'Level3_Lesson08_E02_sib4--sib3': 'Rhythmic patterns in Mi♭ Major. Exercise with anacrusis and syncopes \
  Analyze, sing and note the names of the intervals',
    'Level3_Lesson08_R01_fa4--sib3': 'Romanian folk song "The Naughty Bunch" in Si♭ Major; rhythmic patterns with halves and quarters',
    'Level3_Lesson08_R02_fa4--sib3': 'Romanian folk song "The Naughty Bunch" in Si♭ Major; rhythmic patterns with quarters and eighths',

    'Level3_Lesson09_E01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
    'Level3_Lesson09_E02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
    'Level3_Lesson09_R01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',
    'Level3_Lesson09_R02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',

    'Level3_Lesson10_E01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
    'Level3_Lesson10_E02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
    'Level3_Lesson10_R01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',
    'Level3_Lesson10_R02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',

    'Level3_Lesson11_E01_sold4': 'The note and sound sol♯',
    'Level3_Lesson11_E02_si4-sold4-mi4': 'The note and sound sol♯',
    'Level3_Lesson11_R01_si4-sol4-sold4-mi4': 'Two-parts solfege: the first part (eight measures) in mi minor scale \
   and the second part (the next 8 measures) in Mi Major scale',
    'Level3_Lesson11_R02_si4--mi4': 'Practicing solfege in Mi Major',

    'Level3_Lesson12_E01_si4--mi4': 'Exercise in Mi Major. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson12_E02_si4--mi4': 'What sounds do you have to sing instead of the rests, to get the "Little Jonathan" song? Exercise in Mi Major ',
    'Level3_Lesson12_R01_si4--mi4': 'Compare with the previous solfege, and find out the answer to the question asked at E02',
    'Level3_Lesson12_R02_si4--mi4': 'Exercise in Mi Major. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',

    'Level3_Lesson13_E01_si4--si3': 'Rhythmic patterns with rests of eighths - in Mi Major \
  Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson13_E02_si4--mi4': 'Rhythmic patterns with rests of eighths in Mi Major \
  Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson13_R01_do5--sib3': 'Excerpt 1 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
    'Level3_Lesson13_R02_do5--do4': 'Excerpt 2 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',

    'Level3_Lesson14_E01_do5--sib3': 'Excerpt 1 + 2 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
    'Level3_Lesson14_E02_do5--sib3': 'Excerpt 3 "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
    'Level3_Lesson14_R01_do5--la3': 'Excerpt 4 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
    'Level3_Lesson14_R02_do5--la3': 'Excerpt 5 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',

    'Level3_Lesson15_E01_lab4': 'The note and sound la♭4',
    'Level3_Lesson15_E02_lab4-fa4': 'The note and sound la♭4. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson15_R01_do5--mib4' :'The song "Take caramels" in Mi♭ Major',
    'Level3_Lesson15_R02_do5--mib4': 'Rhythmic variation of the song "A-B-C", in Mi♭ Major',

    'Level3_Lesson16_E01_do5--sib3': 'Rhythmic patterns in Mi♭ Major. Replace the rests with \
   "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson16_E02_sib5--mib4': 'Rhythmic patterns in Mi♭ Major. Replace the rests with \
   "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson16_R01_do5--mib4': 'Variation in 3/4 of the Romanian folk song "Cross the hill to Baiutz", in fa minor scale',
    'Level3_Lesson16_R02_do5--fa4': 'Variation in 3/4 of the Romanian folk song "Cross the hill to Baiutz", in fa minor scale',

    'Level3_Lesson17_E01_si4--si3': 'Intervals in Mi♭ Major. Analyze, sing and note the names of the intervals',
    'Level3_Lesson17_E02_si4--si3': 'Intervals in Mi♭ Major. Analyze, sing and note the names of the intervals',
    'Level3_Lesson17_R01_A-do5--sib3' :'The song "Brother Jacques" (French children song) in Mi♭ Major \
  Singing in canon: the second voice will imitate the leading voice after 4 measures',
    'Level3_Lesson17_R02_B-do5--sib3': 'The song "Brother Jacques" (a French children song) in Mi♭ Major \
  Singing in canon: the second voice imitates the leading voice after 4 measures',

    'Level3_Lesson18_E01_do5--do4': 'Intervals in Fa Major. Analyze, sing and note the names of the intervals',
    'Level3_Lesson18_E02_do5--do4': 'Intervals in Fa Major. Analyze, sing and note the names of the intervals',
    'Level3_Lesson18_R01_si4--si3': '"Liulinki", Russian folk song in si minor. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
    'Level3_Lesson18_R02_do5--do4': '"Liulinki", Russian folk song in do minor. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',

    'Level3_Lesson19_E01_do5--sib3': 'Excerpt 1 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',
    'Level3_Lesson19_E02_sib4--sib3' :'Excerpt 2 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',
    'Level3_Lesson19_R01_do5--sib3': 'Excerpt 3 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',
    'Level3_Lesson19_R02_do5--sib3': 'Excerpt 4 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',

    'Level3_Lesson20_E01_do5--sib3': 'Excerpt 1 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
  composer Ynon Tzur. Asymmetrical rhythmic patterns',
    'Level3_Lesson20_E02_do5--sib3': 'Excerpt 2 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
  composer Ynon Tzur. Asymmetrical rhythmic patterns',
    'Level3_Lesson20_R01_do5--si3': 'Excerpt 3 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
  composer Ynon Tzur. Asymmetrical rhythmic patterns',
    'Level3_Lesson20_R02_sib4--la3': 'Excerpt 4 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
  composer Ynon Tzur. Asymmetrical rhythmic patterns',

    'Level3_Lesson21_E01_sib4--sib3': 'A "Lullaby" by Johannes Brahms, in Si♭ Major',
    'Level3_Lesson21_E02_do5--do4': 'A "Lullaby" by Johannes Brahms, in Do Major',
    'Level3_Lesson21_R01_sib4--la3': 'Part one of a "Minuete" by Johann Sebastian Bach (in Si♭ Major). "Minuet" is a musical work in 3/4',
    'Level3_Lesson21_R02_sib4--la3': 'Part two of a "Minuete" by Johann Sebastian Bach (in Si♭ Major). "Minuet" is a musical work in 3/4',

    'Level3_Lesson22_E01_la4--la3': '"Lullaby" by Franz Schubert in Re Major',
    'Level3_Lesson22_E02_sib4--sib3': '"Lullaby" by Franz Schubert in Mi♭ Major',
    'Level3_Lesson22_R01_sib4--la3': '"Minuete" by Johann Sebastian Bach, in Si♭ Major. "Minuet" is a musical work in 3/4 and two parts',
    'Level3_Lesson22_R02_do5--sib3': '"Minuete" by Johann Sebastian Bach, in Do Major. "Minuet" is a musical work in 3/4 and two parts',

    'Level3_Lesson23_E01_la4--la3': 'The song "Tonic" by Ilie Harubaru in La Major scale. Tonic, also called keynote, \
  is the first note (degree) of any diatonic (e.g., major or minor) scale.',
    'Level3_Lesson23_E02_la4--la3': 'The song "Tonic" by Ilie Hrubaru in La Major scale. Tonic, also called keynote, \
  is the first note (degree) of any diatonic (e.g., major or minor) scale. Rhythmic variation',
    'Level3_Lesson23_R01_sib4--sib3': 'The song "Tonic" by Ilie Hrubaru in Si♭ Major scale. Tonic, also called keynote, \
  is the first note (degree) of any diatonic (e.g., major or minor) scale. Rhythmic and tonal variation',
    'Level3_Lesson23_R02_do5--do4': 'The song "Tonic" by Ilie Hrubaru in Si♭ Major scale. Tonic, also called keynote, \
  is the first note (degree) of any diatonic (e.g., major or minor) scale. Rhythmic and tonal variation',

    'Level3_Lesson24_E01_la4--la3': 'The song "Riddle" by Ilie Hrubaru, in La Major',
    'Level3_Lesson24_E02_la4--la3': 'The song "Riddle" by Ilie Hrubaru, in La Major; rhythmic variation',
    'Level3_Lesson24_R01_sib4--sib3': 'The song "Riddle" by Ilie Hrubaru, in Si♭ Major; tonal and rhythmic variation',
    'Level3_Lesson24_R02_do5--do4': 'The song "Riddle" by Ilie Hrubaru, in Do Major; tonal and rhythmic variation',

    'Level3_Lesson25_E01_sib4--mib3': 'Johann Sebastian Bach, "Musette" in Mi♭ Major, part one',
    'Level3_Lesson25_E02_sib4--sib3': 'Johann Sebastian Bach, "Musette" in Mi♭ Major, part one and part two',
    'Level3_Lesson25_R01_sib4--sib3': 'The song "Flat, Natural, Sharp" in Si♭ Major, by Ilie Hrubaru',
    'Level3_Lesson25_R02_do5--do4': 'The song "Flat, Natural, Sharp" in Do Major, by Ilie Hrubaru',

    'Level3_Lesson26_E01_sib--la3-rem': '"Old French song" (from the Album for young people)\
  Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in re minor, part 1',
    'Level3_Lesson26_E02_do5--la3-rem': '"Old French song" (from the Album for young people)\
  Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in re minor, part 2',
    'Level3_Lesson26_R01_do5--la3-rem': '"Old French song" (from the Album for young people)\
  Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in re minor',
    'Level3_Lesson26_R02_do5--si3-mim': '"Old French song" (from the Album for young people)\
  Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in mi minor',

    'Level3_Lesson27_E01_la4--la3-Re': '"On the Way to Viseu", traditional Portuguese song, in Re Major, with quarters',
    'Level3_Lesson27_E02_sib4--sib3-Mib': '"On the Way to Viseu", traditional Portuguese song, in Mi♭ Major, with quarters',
    'Level3_Lesson27_R01_la4--la3-Re': '"On the Way to Viseu", traditional Portuguese song, in Mi♭ Major, with eighths',
    'Level3_Lesson27_R02_sib4--la3-ReMib': '"On the Way to Viseu", traditional Portuguese song, in Re Major with modulation to Mi♭ Major \
  Modulation is the change from one tonality (tonic, or tonal center) to another.',

    'Level3_Lesson28_E01_la4--la3-re': 'A traditional Portuguese song "The Dove" in re minor, with quarters and halves',
    'Level3_Lesson28_E02_si4--si3-mi': 'A traditional Portuguese song "The Dove" in mi minor, with quarters and halves',
    'Level3_Lesson28_R01_si4--la3-remi': 'Modulation is the change from one tonal center to another, during a piece.\
  A traditional Portuguese song "The Dove" in re minor with modulation to mi minor',
    'Level3_Lesson28_R02_do5--si3-mifa': 'Modulation is the change from one tonal center to another, during a piece.\
  A traditional Portuguese song "The Dove" in mi minor with modulation to fa minor',

};

export default ExerciseDescriptions_en;