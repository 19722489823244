import React from 'react';

const PdfViewer = ({ pdfFileName }) => {
    return (
        <iframe
            src={pdfFileName}
            width="100%"
            height="600px"
            title="PDF Viewer"
        />
    );
};

export default PdfViewer;
