import React, { useState } from "react";
import { Grid, Tabs } from '@mui/material';
import { ExerciseContainer, VerticalTab } from '../customized_components';

import Puzzle from './Puzzle';

function Games(props) {
    const [value, setValue] = useState('puzzleSound1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid
            style={{ flex: 1, display: 'grid', gridTemplateColumns: '200px 1fr' }}
        >
            <div className="sidebar">
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        indicatorColor="transparent"
                        style={{ borderRight: 'none', marginTop: '40px' }}
                    >
                        <VerticalTab label="Puzzle Audio" value="puzzleSound1" />
                        <VerticalTab label="Puzzle Meter" value="puzzleMeter1" />
                        <VerticalTab label="Puzzle Audio 2" value="puzzleSound2" />

                    </Tabs>
                </div>
            </div>
            <ExerciseContainer>
                {/* Use the `value` as the key to force re-rendering */}
                <Puzzle key={value} puzzleName={value} />
            </ExerciseContainer>
        </Grid>
    );
}

export default Games;
