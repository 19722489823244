import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import { SolfyTextField, FormTitle, ContainedButton } from './customized_components';
import { ReactComponent as Background } from '/static/icons/background.svg';
import { ReactComponent as SolfyLogo } from '/static/icons/solfyLogo.svg';
import { Typography, Paper, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import visibility icons
import ReCAPTCHA from 'react-recaptcha';
import './styles/colors.scss';

function LoginForm(props) {
    const RecaptchaPubKey = props.RecaptchaPubKey;

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle password visibility
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!captchaVerified) {
            console.log("Please complete the reCAPTCHA verification.");
            return;
        }

        axiosInstance.post('/api/login/', formData)
            .then((response) => {
                if (response.status === 200) {
                    props.setAuthenticatedCallback(true, false);
                    navigate('/app/');
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403)
                    setErrorMessage('Invalid credentials');
                else
                    console.error(error);
            });
    };

    const handleCaptchaVerify = (response) => {
        if (response) {
            console.log("reCAPTCHA verified");
            setCaptchaVerified(true);
        }
    };

    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <Background style={{ width: '100%', height: '100%', position: 'absolute', zIndex: -1 }} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <SolfyLogo style={{ position: 'absolute', top: '-50px' }} />
                <Paper className='longPaper' style={{ height: 'auto' }} elevation={0} dir={lang_dir}>
                    <FormTitle gutterBottom>
                        {Tabstext.SignInTab[props.lang]}
                    </FormTitle>
                    <form onSubmit={handleSubmit} dir={lang_dir}>
                        <SolfyTextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={Tabstext.UsernameTab[props.lang]}
                            name="username"
                            value={formData.username}
                            dir={lang_dir}
                            required
                            onChange={handleChange}
                        />
                        <SolfyTextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={Tabstext.PasswordTab[props.lang]}
                            name="password"
                            type={showPassword ? "text" : "password"} // Toggle between text and password
                            value={formData.password}
                            dir={lang_dir}
                            required
                            onChange={handleChange}
                            InputProps={{ // Add adornment to the input field
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleTogglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                            <ReCAPTCHA
                                sitekey={RecaptchaPubKey}
                                verifyCallback={handleCaptchaVerify}
                            />
                        </div>
                        <ContainedButton
                            type="submit"
                            fullWidth
                            disabled={!captchaVerified}
                        >
                            {Tabstext.SignInTab[props.lang]}
                        </ContainedButton>
                        {errorMessage && (
                            <Typography color="error" mt={2}>
                                {Tabstext.UserCredentialsErrorTab[props.lang]}
                            </Typography>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'6px' }}>
                            <Typography >
                                <a href='password_reset' style={{ color: 'var(--blue-5)' }}>
                                    {Tabstext.ForgotPasswordTab[props.lang]}
                                </a>
                            </Typography>
                            <Typography >
                                    {Tabstext.NotRegisteredTab[props.lang]}
                                    <Link to="/register" style={{ color: 'var(--blue-5)' }}>
                                        {Tabstext.RegisterTab[props.lang]}
                                    </Link>
                            </Typography>
                        </div>
                    </form>
                </Paper>
            </div>
        </div>
    );
}

export default LoginForm;
