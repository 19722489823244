import React, { useState, } from "react";
import { Typography, Tooltip, Switch } from '@mui/material';
import './styles/extras.css';
import Tabstext from './tabstext';
import { axiosInstance,  } from './axios_instance';

function ClassHomeSelect(props) {
    const inClass = props.inClass;

    const handleInClassSelect = (event, val) => {
        if (val !== props.inClass) {
            axiosInstance.post('/api/user_settings/', { in_class_mode: val }).then();
            props.inClassChangeCallback(val);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <Switch
            size='small'
            checked={inClass}
            disabled={!props.passedRectest}
            onChange={handleInClassSelect}
            inputProps={{ 'aria-label': 'controlled' }}
            />
            <Tooltip title=
                {props.inClass? Tabstext.ClassToHomeTooltip[props.lang]:Tabstext.HomeToClassTooltip[props.lang]}
                    placement="top">
                <Typography className='linkButtonLike' variant='h6' style={{ display: 'inline-block' }} >
                    {Tabstext.InClassMode[props.lang]}
                </Typography>
            </Tooltip >
        </div>
    );
}

export default ClassHomeSelect
