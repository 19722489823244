import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance,  } from './axios_instance';
import Tabstext from './tabstext';
import GroupsTable from './groups_table';
import CreateGroupsForm from './create_groups';
import { ContainedButton, PageTitle } from './customized_components';
import { Dialog, DialogContent, Slide } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MyGroups(props) {
    const [myGroups, setMyGroups] = useState([]);
    const [registerLink, setRegisterLink] = useState([]);
    const [teacher, setTeacher] = useState([]);
    const [ageGroups, setAgeGroups] = useState([]);
    const [initialFormData, setInitialFormData] = useState(null);
    const [numCreatedGroups, setNumCreatedGroups] = useState(0)
    const setNumCreatedGroupsCallback = (val) => {
        setNumCreatedGroups(numCreatedGroups+val);
    };
    const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);

    const copyGroupLink = (group) => {
        const link = registerLink + group.pk;
        const text1 = Tabstext.JoinGroupText1[props.lang]
        const text2 = Tabstext.JoinGroupText2[props.lang]
        const text3 = Tabstext.JoinGroupText3[props.lang]
        const text2copy = text1 + group.name + text2 + group.pk + text3 + link
        navigator.clipboard.writeText(text2copy);
        alert("Copied to clipboard: " + text2copy);
    };

    useEffect(() => {
        axiosInstance.get('/api/my_groups/').then((response) => {
            setTeacher(response.data.Teacher)
            setMyGroups(response.data.MyGroups);
            setRegisterLink(response.data.RegisterLink);
            setAgeGroups(response.data.AgeGroups);
            setInitialFormData({
                teachername: response.data.Teacher.user_firstname + '.' + response.data.Teacher.user_lastname,
                location: response.data.Teacher.location!=null?response.data.Teacher.location:'',
                schoolname: response.data.Teacher.school!=null?response.data.Teacher.school:'',
                year: new Date().getFullYear(),
                classname: '',
            });

        }); // end axios
    }, [numCreatedGroups]); // end useEffect

    const openCreateGroupDialog = () => {
        setShowCreateGroupDialog(true);
    };
    const closeCreateGroupDialog = () => {
        setShowCreateGroupDialog(false);
    };

    const deleteGroup = (group) => {
        axiosInstance.post('/api/delete_group/',{group:group})
        .then((response) => {
            if (response.status === 200 && response.data.message=='group deleted')
                setNumCreatedGroups(numCreatedGroups-1);
        })
    };

    if (props.display==false || myGroups==null || initialFormData==null) {
        return <div></div>;
    }

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
            <div  style={{ overflowY: 'auto'}} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin:'10px' }}>
                    <PageTitle >
                        {Tabstext.YourCurrentGroupsTab[props.lang]}
                    </PageTitle>
                    <ContainedButton
                        style={{margin:'0 24px',}}
                        startIcon={<ControlPointIcon />}
                        onClick={openCreateGroupDialog}
                        >
                        {Tabstext.CreateGroupsTab[props.lang]}
                    </ContainedButton>
                </div>
                <GroupsTable lang={props.lang}
                    myGroups={myGroups} setPageCallback={props.setPageCallback}
                    copyGroupLink={copyGroupLink} deleteGroup={deleteGroup}
                    windowSize={props.windowSize}
                />
                <Dialog
                    open={showCreateGroupDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeCreateGroupDialog}
                    aria-describedby="create-group-dialog"
                    fullWidth
                    maxWidth="sm"
                    >
                    <DialogContent>
                        <CreateGroupsForm
                            lang={props.lang}
                            initialFormData={initialFormData}
                            setNumCreatedGroupsCallback={setNumCreatedGroupsCallback}
                            ageGroups={ageGroups}
                            onClose={closeCreateGroupDialog}
                        />
                    </DialogContent>
                </Dialog>
                </div>
    );
}


export default MyGroups;

