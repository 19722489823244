export const fragmentLists = {
    puzzleSound1: {
        frags: [
            {fragPath: 'puzzle-sound/frag_1_1_0_0_0.png', level: 1, row: 1, idx: 0, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_1_1_1_0_0.png', level: 1, row: 1, idx: 1, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_1_1_2_0_0.png', level: 1, row: 1, idx: 2, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_1_1_3_0_0.png', level: 1, row: 1, idx: 3, anacrusis: 0, complement: 0, dur: 2.},

            {fragPath: 'puzzle-sound/frag_2_2_0_0_0.png', level: 2, row: 2, idx: 0, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_2_2_1_0_0.png', level: 2, row: 2, idx: 1, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_2_2_2_0_0.png', level: 2, row: 2, idx: 2, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_2_2_3_0_0.png', level: 2, row: 2, idx: 3, anacrusis: 0, complement: 0, dur: 2.},

            {fragPath: 'puzzle-sound/frag_3_3_0_0_0.png', level: 3, row: 3, idx: 0, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_3_1_0_0.png', level: 3, row: 3, idx: 1, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_3_2_0_0.png', level: 3, row: 3, idx: 2, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_3_3_0_0.png', level: 3, row: 3, idx: 3, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_4_0_0_0.png', level: 3, row: 4, idx: 0, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_4_1_0_0.png', level: 3, row: 4, idx: 1, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_4_2_0_0.png', level: 3, row: 4, idx: 2, anacrusis: 0, complement: 0, dur: 2.},
            {fragPath: 'puzzle-sound/frag_3_4_3_0_0.png', level: 3, row: 4, idx: 3, anacrusis: 0, complement: 0, dur: 2.},
        ],
        audio: {audioPath: 'puzzle-sound/whole.mp3', tempo: 120., hasAudio: true},
        meterBeatCount: 0,

    },

    puzzleMeter1: {
        frags: [
            {fragPath: 'puzzle-meter/frag_1_1_0_0_1.png', level: 1, row: 1, idx: 0, anacrusis: 0, complement: 1, dur: 3},
            {fragPath: 'puzzle-meter/frag_1_1_1_1_1.png', level: 1, row: 1, idx: 1, anacrusis: 1, complement: 1, dur: 2},
            {fragPath: 'puzzle-meter/frag_1_1_2_1_0.png', level: 1, row: 1, idx: 2, anacrusis: 1, complement: 0, dur: 3},

            {fragPath: 'puzzle-meter/frag_2_2_0_0_0.png', level: 2, row: 2, idx: 0, anacrusis: 0, complement: 0, dur: 2},
            {fragPath: 'puzzle-meter/frag_2_2_1_0_1.png', level: 2, row: 2, idx: 1, anacrusis: 0, complement: 1, dur: 3},
            {fragPath: 'puzzle-meter/frag_2_2_2_1_0.png', level: 2, row: 2, idx: 2, anacrusis: 1, complement: 0, dur: 3},

            {fragPath: 'puzzle-meter/frag_3_3_0_0_1.png', level: 3, row: 3, idx: 0, anacrusis: 0, complement: 1, dur: 3},
            {fragPath: 'puzzle-meter/frag_3_3_1_1_0.png', level: 3, row: 3, idx: 1, anacrusis: 1, complement: 0, dur: 3},
            {fragPath: 'puzzle-meter/frag_3_3_2_0_0.png', level: 3, row: 3, idx: 2, anacrusis: 0, complement: 0, dur: 2},
            {fragPath: 'puzzle-meter/frag_3_4_0_0_1.png', level: 3, row: 4, idx: 0, anacrusis: 0, complement: 1, dur: 1},
            {fragPath: 'puzzle-meter/frag_3_4_1_1_1.png', level: 3, row: 4, idx: 1, anacrusis: 1, complement: 1, dur: 2},
            {fragPath: 'puzzle-meter/frag_3_4_2_1_0.png', level: 3, row: 4, idx: 2, anacrusis: 1, complement: 0, dur: 3},
            {fragPath: 'puzzle-meter/frag_3_4_3_0_0.png', level: 3, row: 4, idx: 3, anacrusis: 0, complement: 0, dur: 2},
        ],
        audio: {audioPath: 'puzzle-meter/whole.mp3', tempo: 120., hasAudio: false},
        meterBeatCount: 2,
    }
};