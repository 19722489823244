import React from 'react';
import { useState, useEffect, } from "react";
import { Typography, Switch, Tooltip, Radio } from '@mui/material';
import './styles/extras.css';
import './styles/colors.scss';
//import { ToggleButtonGroup } from '@mui/material';
//import { SolfyToggleButton } from './customized_components';
import Tabstext from './tabstext';

function AsStudentSelect(props) {

    const [selected, setSelected] = useState(false);
    useEffect(() => {
        if (props.asStudent != null)
            setSelected(props.asStudent);
    }, [props.asStudent]);

    const handleAsStudentSelect = () => {
        props.setAsStudentCallback(!selected);
        setSelected(!selected); // invert
    };
    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    const teacherColor = selected ? 'rgba(0, 0, 0, 0.5)' : 'var(--blue-5)';
    const studentColor = selected ? 'var(--blue-5)' : 'rgba(0, 0, 0, 0.5)';


    return (
        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', position: 'relative', left: '-60px' }} >
            <Tooltip title={Tabstext.AsTeacherTooltip[props.lang]} placement="top">
                <Typography variant='h6'
                    style={{ color: teacherColor, lineHeight:3 }}>
                    {Tabstext.AsTeacherTab[props.lang]}
                </Typography>
            </Tooltip>
            <div style={{ display: 'flex', textAlign: 'center',  }}>
                <Radio
                    checked={!selected}
                    onChange={handleAsStudentSelect}
                    value="teacher"
                    sx={{ padding: '4px', '& .MuiSvgIcon-root': { fontSize: '16px', }, }}
                />
                <Radio
                    checked={selected}
                    onChange={handleAsStudentSelect}
                    value="student"
                    sx={{ padding: '4px', '& .MuiSvgIcon-root': { fontSize: '16px', }, }}
                />
            </div>
            <Tooltip title={Tabstext.AsStudentTooltip[props.lang]} placement="top">
                <Typography variant='h6' 
                    style={{ color: studentColor, lineHeight: 3 }}>
                    {Tabstext.AsStudentTab[props.lang]}
                </Typography>
            </Tooltip>
        </div>
    );
}

export default AsStudentSelect;



//<ToggleButtonGroup
//    value={props.asStudent}
//    exclusive
//    onChange={handleAsStudentSelect}
//>
//    <Tooltip title={Tabstext.AsTeacherTooltip[props.lang]} arrow placement="top" dir={lang_dir}>
//        <SolfyToggleButton
//            style={{ padding: '0px 10px' }}
//            value={false}
//        >
//            {Tabstext.AsTeacherTab[props.lang]}
//        </SolfyToggleButton>
//    </Tooltip>
//    <Tooltip title={Tabstext.AsStudentTooltip[props.lang]} arrow placement="top" dir={lang_dir}>
//        <SolfyToggleButton
//            style={{ padding: '0px 10px' }}
//            value={true}
//        >
//            {Tabstext.AsStudentTab[props.lang]}
//        </SolfyToggleButton>
//    </Tooltip>
//</ToggleButtonGroup>
