import React, { useState, useEffect } from 'react';

function SpeechBubble({className, message, position }) {
    return (

            <div className={className} style={{ top: position.top, left: position.left }}>
                {message}
            </div>

    );
}

export default SpeechBubble;