import React from 'react';
import { Bar } from 'react-chartjs-2';
import { LinearScale, CategoryScale, Chart, BarElement} from "chart.js";
import Tabstext from './tabstext';

Chart.register(LinearScale, CategoryScale, BarElement);

const Histogram = ({ hist, binEdges, lang }) => {
    // Convert the numpy format to the format required by Chart.js
    const histogramData = binEdges.slice(0, -1).map((edge, index) => ({
        bin: `${edge}`,
        frequency: hist[index],
    }));

    // Chart data
    const chartData = {
        labels: histogramData.map((bin) => bin.bin),
        datasets: [
            {
                label: 'frequency',
                data: histogramData.map((bin) => bin.frequency),
                backgroundColor: 'rgba(75, 192, 192, 0.2)', // Adjust as needed
                borderColor: 'rgba(75, 192, 192, 1)', // Adjust as needed
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const chartOptions = {
        indexAxis: 'x',
        scales: {
            x: {
                title: {
                    display: true,
                    text: Tabstext.ScoreInTable[lang],
                },
            },
            y: {
                title: {
                    display: true,
                    text: Tabstext.HistogramYlabel[lang],
                },
                ticks: {
                    precision: 0,
                },
            },
        },
    };

    return (
        <div>
            <Bar data={chartData} options={chartOptions} updateMode='resize' />
        </div>
    );
};

export default Histogram;
