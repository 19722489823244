import Messages from './messages';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Popover, Typography, Checkbox } from '@mui/material';
import Tabstext from './tabstext';
import { axiosInstance } from "./axios_instance";
import CloseIcon from '@mui/icons-material/Close';

function Tip(props) {
    const anchorRef = useRef(null);

    const [showTip, setShowTip] = useState(false); // Controls whether the tip is visible
    useEffect(() => {
        // Fetch user preference to see if the tip should be shown
        axiosInstance.get(`/api/user-tips/${props.tipKey}`)
            .then((response) => {
                setShowTip(response.data.show); // Update state based on server response
            })
            .catch((error) => {
                console.error("Error fetching user tip preference:", error);
            });
    }, [props.tipKey]);

    const tiptext = Messages[props.text][props.lang];
    const dontShowAgain = Tabstext.DontShowAgain[props.lang]


    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        if (showTip) {
            setAnchorEl(anchorRef.current); // Only set anchor when showTip is true
        } else {
            setAnchorEl(null); // Clear anchor when showTip is false
        }
    }, [showTip]);

    const open = showTip && Boolean(anchorEl); // Ensure Popover only opens when showTip is true
    const id = open ? 'simple-popover' : undefined;

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const showState = e.target.checked ? false : true
        axiosInstance
            .post(`/api/user-tips/${props.tipKey}/`, { show: showState })
            .then(() => {
                console.log("Don't show again preference saved.");
            })
            .catch((error) => {
                console.error("Error saving preference:", error);
            });
    };


    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <div ref={anchorRef} style={{ position: 'relative' }} dir={lang_dir}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(event, reason) => {
                    if (reason === "backdropClick") return; // Prevent close on outside clicks
                    setAnchorEl(null); // Allow close for other reasons
                }}
                PaperProps={{
                    style: {
                        maxWidth: '50%',
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                    }
                }}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ position: 'relative' }}>
                    <Button
                        onClick={() => setAnchorEl(null)}
                        style={{
                            position: 'absolute',
                            top: '8px', // Adjust for distance from the top
                            [lang_dir === 'rtl' ? 'left' : 'right']: '8px', // Adjust for RTL or LTR
                            zIndex: 10,
                            padding: '0', // Remove padding around the button
                            minWidth: 'auto', // Ensure it doesn't expand unnecessarily                        
                        }}
                    >
                        <CloseIcon style={{ fontSize: '16px', fontWeight: 500, color: 'black' }} />
                    </Button>
                    <Typography dir={lang_dir}
                        style={{
                            lineHeight: '1.5', textAlign: 'justify',
                            padding: '24px 24px 0px 24px', 
                        }}>
                        {tiptext}
                    </Typography>
                </div>
                <div style={{ margin: '12px' }} dir={lang_dir}>
                    <Checkbox
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                        size="small"
                    />
                    <Typography component="span" dir={lang_dir}>
                        {dontShowAgain}
                    </Typography>
                </div>
            </Popover>
        </div>
    );
}

export default Tip



