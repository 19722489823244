import React from 'react'
import { useState,  } from "react";
import Tabstext from './tabstext';
import './styles/extras.css';
import { HeadTableCell, BodyTableCell, CellLink, LinkButton } from './customized_components';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Link, Tooltip } from '@mui/material';
import { Typography,  } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function GroupsTable(props) {
    const tableHeight = props.windowHeight*0.75

    const { myGroups, setPageCallback, copyGroupLink, deleteGroup, lang } = props;

    const [clicked, setClicked] = useState(false);
    const handleDelete = (val) => {
        setClicked(true);
        deleteGroup(val);
    };

    return (
        <div className='tableWrapper' >
            <TableContainer component={Paper} style={{ boxShadow: 'none', maxHeight: `${tableHeight}px`}} >
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow >
                            <HeadTableCell style={{ borderRadius:'6px 0 0 6px',}} >
                                {Tabstext.GroupTab[lang]}
                            </HeadTableCell>
                            <HeadTableCell >{Tabstext.NumStudentsTab[lang]}</HeadTableCell>
                            <HeadTableCell >{Tabstext.ActiveStudentsTab[lang]}</HeadTableCell>
                            <HeadTableCell >{Tabstext.ActiveLastMonthTab[lang]}</HeadTableCell>
                            <HeadTableCell >{Tabstext.CopyLinkTab[lang]}</HeadTableCell>
                            <HeadTableCell style={{ borderRadius:'0 6px 6px 0', }} >
                                {Tabstext.GroupActionsTab[lang]}
                            </HeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {myGroups.map((group, index) => (
                            <TableRow key={index} >
                                <BodyTableCell >
                                    <CellLink onClick={() => setPageCallback(`${group.name}`, {})}>
                                        <Typography style={{maxWidth:'40vw'}}  > {group.name} </Typography>
                                    </CellLink>
                                </BodyTableCell>
                                <BodyTableCell >{ group.user_set.length }</BodyTableCell>
                                <BodyTableCell >{ group.active_users.length }</BodyTableCell>
                                <BodyTableCell >{ group.active_last_month_count }</BodyTableCell>
                                <BodyTableCell >
                                    <Tooltip title={Tabstext.inviteYourStudentsHover[lang]} arrow>
                                        <Link
                                            onClick={() => copyGroupLink(group)}
                                            style={{
                                                color:'black',
                                                textDecoration:'none',
                                                cursor: 'pointer',
                                                marginLeft:'6px',
                                                }}
                                            >
                                            <ContentCopyIcon fontSize='md' style={{ verticalAlign: 'middle' }} />
                                        </Link>
                                    </Tooltip>
                                </BodyTableCell>
                                <BodyTableCell >
                                    {group.user_set.length === 0 &&
                                        <Tooltip title={Tabstext.GroupDeleteHover[lang]} arrow>
                                            <LinkButton onClick={() => handleDelete(group.name)} style={{textTransform:'none'}} >
                                                {Tabstext.DeleteTab[lang]}
                                            </LinkButton>
                                        </Tooltip>
                                    }
                                </BodyTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default GroupsTable;

