import React, { useState, useEffect, useRef } from 'react';
import Stash from './Stash';
import Row from './Row';
import SpeechBubble from './SpeechBubble'; // Import the SpeechBubble component

function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true; // Identical objects or primitives
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
}

function isDictInList(dict, list) {
    for (let item of list) {
        if (deepEqual(dict, item)) {
            return true; // Dictionary found in the list
        }
    }
    return false; // Dictionary not found
}


function Level({ level, hasAudio, meterBeatCount, isUnlocked, isSolved, onLevelSolved, playSegment }) {
    const audioRef = useRef(null); // Reference to the audio element

    const shuffleFragments = (fragments) => {
        return fragments.sort(() => Math.random() - 0.5);
    };

    const [rows, setRows] = useState(level.rows);
    const [stash, setStash] = useState(shuffleFragments(level.stash));
    const [bubbles, setBubbles] = useState([]); // Use state for bubbles


    // Automatically start/stop the audio based on whether the level is unlocked or solved
    useEffect(() => {
        if (hasAudio) {
            if (isUnlocked && !isSolved && audioRef.current) { } else if (isSolved && audioRef.current) {
                audioRef.current.pause(); // Pause audio when the level is solved
            }
        }
    }, [isUnlocked, isSolved]);

    const onDropToRow = (fragment, rowIndex, insertIndex = null) => {
        setBubbles([]); // Clear bubbles


        if (!rows[rowIndex] || isSolved) return;

        const newStash = stash.filter((frag) => !deepEqual(frag, fragment));
        const newRows = rows.map((row) => ({
            ...row,
            fragments: row.fragments.filter((frag) => !deepEqual(frag, fragment)),
        }));

        if (insertIndex !== null) {
            newRows[rowIndex].fragments.splice(insertIndex, 0, fragment); // Insert at specific index
        } else {
            newRows[rowIndex].fragments.push(fragment); // Append to the end
        }

        setRows(newRows);
        setStash(newStash);
    };

    const onDropToStash = (fragment) => {
        setBubbles([]); // Clear bubbles


        if (isSolved) return;

        // Ensure the fragment is not already in the stash (prevents duplication)
        const fragmentExistsInStash = isDictInList(fragment, stash);

        // Add the fragment back to the stash only if it doesn't already exist
        if (!fragmentExistsInStash) {
            const newStash = [...stash, fragment];

            // Find and remove the fragment from the rows
            const newRows = rows.map((row) => ({
                ...row,
                fragments: row.fragments.filter((frag) => !deepEqual(frag, fragment)),
            }));

            setRows(newRows); // Update rows
            setStash(newStash); // Update stash
        };
    };

    const checkOrder = () => {


        setBubbles(() => []); // Clear bubbles before checking order

        const checkOrderButton = document.querySelector('.check-order-button');
        const button = checkOrderButton.getBoundingClientRect(); // Get button position

        const viewportWidth = window.innerWidth;
        const widthPercent = (((button.right + button.left) / 2) / viewportWidth) * 100;


        let newBubbles = [];

        console.log(bubbles.length)


        if (stash.length > 0) {
            // Display bubble near the button if the solution is incomplete
            newBubbles.push({
                name: 'speech-bubble-near-button',
                message: `You must use all fragments!`,
                position: {
                    top: button.top,
                    left: `calc(${widthPercent}% + 55px)`,
                },
            });

            setBubbles(newBubbles); // Update bubbles 
            return;
        }

        let isCorrect = true;

        rows.forEach((row) => {

            const anacruses = [];
            const complements = [];

            row.fragments.forEach((fragment, index) => {

                const fragmentElement = document.querySelector(`img[src='/media/games/${fragment.fragPath}']`);
                const rect = fragmentElement.getBoundingClientRect();

                if (fragment.row != row.row || fragment.idx != index || fragment.level != level.level) {
                    isCorrect = false;
                }

                if (meterBeatCount > 0) {
                    anacruses.push(fragment.anacrusis);
                    complements.push(fragment.complement);

                    if (index > 0) {
                        let q = anacruses[index] + complements[index - 1];



                        if (q % meterBeatCount != 0) {
                            if (q < meterBeatCount) {

                                newBubbles.push({
                                    name: 'speech-bubble-near-row',
                                    message: `missing ${meterBeatCount - q} beats!`,
                                    position: { top: rect.top - 30, left: rect.left }
                                });
                            } else if (q > meterBeatCount) {
                                newBubbles.push({
                                    name: 'speech-bubble-near-row',
                                    message: `${q - meterBeatCount} beats too many!`,
                                    position: { top: rect.top - 30, left: rect.left }
                                });
                            }
                        }
                    }
                }

            });
        });

        if (isCorrect) {
            onLevelSolved(); // Notify parent that the level is solved
        } else {
            newBubbles.push({
                name: 'speech-bubble-near-button',
                message: `Level ${level.level} Incorrect Solution. Keep trying!`,
                position: {
                    top: button.top,
                    left: `calc(${widthPercent}% + 55px)`,
                },


            });
        }

        setBubbles(newBubbles); // Update bubbles with this error
    };

    return (
        <div className={`level ${isUnlocked ? 'visible' : ''} ${isSolved ? 'solved' : ''}`}>
            {<h2 className="puzzle-h2">Level {level.level}</h2>}





            {bubbles.map(
                (bubble, index) => (
                    <SpeechBubble className={bubble.name} message={bubble.message} key={index}
                        position={bubble.position} />
                )
            )
            }

            <div style={{ display: 'flex' }}>
                {hasAudio && isUnlocked && (<button className={`play-level-button ${isSolved ? 'solved' : ''}`} onClick={() => playSegment(level.startTime, level.dur)}></button>)}
                <div className="rows">
                    {rows.map((row, rowIndex) => (
                        <Row
                            playSegment={playSegment}
                            key={rowIndex}
                            row={row}
                            onDrop={(fragment, insertIndex) => onDropToRow(fragment, rowIndex, insertIndex)}
                            isSolved={isSolved}
                        />
                    ))}
                </div>
            </div>

            <Stash stash={stash} onDropFragment={onDropToStash} playSegment={playSegment} />

            {isUnlocked && !isSolved && <button className="check-order-button" onClick={checkOrder}>Check Order</button>}
        </div>

    );
}

export default Level;
