import React from 'react'
import Introtext from './introtext';
import { Typography, } from '@mui/material';


function PracticeDemo(props) {
    const demo_src = {
        'he': "/static/demos/demo1-Aramat-He.mp4",
        'en': "/static/demos/demo1-Georgiana-En.mp4" ,
        'ro': "/static/demos/demo1-Georgiana-Ro.mp4",
    };
    const videoKey = `${props.lang}-video`;
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
        <div dir={lang_dir} style={{ display: 'flex', flex:1, flexDirection: 'column', alignItems: 'center', margin:'40px' }} >
            <Typography variant='h3' dir={lang_dir} gutterBottom 
                style={{ display: 'flex', textAlign: 'center' }} >
                {Introtext.ViewPracticeDemo[props.lang]}
            </Typography>
            <Typography variant='body1' dir={lang_dir} gutterBottom
                style={{ display: 'flex', textAlign: 'center' }} >                
                {Introtext.ViewPracticeDemo2[props.lang]}
            </Typography>
            <div style={{marginBottom:'40px'}} >
                <video key={videoKey} controls width="800" height="450">
                    <source src={demo_src[props.lang]} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}


export default PracticeDemo;
