import Messages from './messages';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Popover, Typography } from '@mui/material';

function FeedbackMessage(props) {
    const anchorRef = useRef(null);
    var message = Messages[props.feedbackData.feedback_message][props.lang];
    if (props.feedbackData.performance_score && props.feedbackData.performance_score>0)
        message += ' ' + props.feedbackData.performance_score
    if (props.recTest && messageColor == 'red')
        message += ' ' + Messages.clickon_practice[props.lang];
    var timeoutDuration = message.length * 70;

    useEffect(() => {
        //        console.log('Component mounted');
        setAnchorEl(anchorRef.current);
        const timer = setTimeout(() => { setAnchorEl(null); }, timeoutDuration);
        return () => clearTimeout(timer);
    }, [props.feedbackData]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const messageColor = props.feedbackData.message_color;

    const x_svg_src = messageColor == 'red' ? '/static/icons/red_x.svg' : '/static/icons/green_x.svg';
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    if (!props.display)
        return null;

    return (
        <div ref={anchorRef} style={{ position: 'relative' }} dir={lang_dir}>
            <Button
                variant="text"
                onClick={() => setAnchorEl(anchorRef.current)}
            >
            <div style={{ position: 'absolute', top: 0, right: 30, zIndex: '1',}}>
                <img src='/static/icons/bird1.svg' alt="Image" width="40" height="40"  />
            </div>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                PaperProps={{ style: {maxWidth: '50%'} }}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                >
                <Typography className={`feedbackMessage ${messageColor}`} dir={lang_dir}>
                    <img src='/static/icons/bird1.svg' alt="Image" width="35" height="35" style={{ margin: '0 8px' }} />
                    {message}
                        <Button
                            variant="text"
                            onClick={() => setAnchorEl(null)}
                            style={{minWidth:'0'}}
                            >
                        <img src={x_svg_src} alt="Image" width="20" height="20"  />
                        </Button>
                </Typography>
            </Popover>
        </div>
    );
}

export default FeedbackMessage



