import React, { useState, useEffect, useRef } from 'react';
import { ContainedButton, OutlinedButton, PageTitle } from './customized_components';
import { Button, Popover, Typography, } from '@mui/material';
import Tabstext from './tabstext';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';
import CloseIcon from '@mui/icons-material/Close';

function RecordingReminderPopup(props) {
    const sessionKey = "popupShown"; // Session key to track popup only once after login
    const anchorRef = useRef(null);

    //const [showTip, setShowTip] = useState(false); // Controls whether the tip is visible
    //useEffect(() => {
    //    const isSessionKeySet = sessionStorage.getItem(sessionKey);
    //    if (!isSessionKeySet) {
    //        setShowTip(true);
    //        sessionStorage.setItem(sessionKey, 'true');
    //    }
    //}, []);

    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        if (props.showTip) {
            setAnchorEl(anchorRef.current); // Only set anchor when showTip is true
        } else {
            setAnchorEl(null); // Clear anchor when showTip is false
        }
    }, [props.showTip]);

    const open = props.showTip && Boolean(anchorEl); // Ensure Popover only opens when showTip is true
    const id = open ? 'simple-popover' : undefined;

    const handleRecordingTestClick = () => {
        setAnchorEl(null);
        props.handleRecordingTestClick();
    };
    const dismiss = () => {
        setAnchorEl(null);
        props.clearTipCallback();
    };

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <div ref={anchorRef} style={{ position: 'relative' }} dir={lang_dir}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(event, reason) => {
                    if (reason === "backdropClick") return; // Prevent close on outside clicks
                    setAnchorEl(null); // Allow close for other reasons
                }}
                PaperProps={{
                    style: {
                        maxWidth: '50%',
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                    }
                }}
                anchorOrigin={{
                    vertical: 80,
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ position: 'relative' }}>
                    <Button
                        onClick={() => setAnchorEl(null)}
                        style={{
                            position: 'absolute',
                            top: '8px', // Adjust for distance from the top
                            [lang_dir === 'rtl' ? 'left' : 'right']: '8px', // Adjust for RTL or LTR
                            zIndex: 10,
                            padding: '0', // Remove padding around the button
                            minWidth: 'auto', // Ensure it doesn't expand unnecessarily                        
                        }}
                    >
                        <CloseIcon style={{ fontSize: '16px', fontWeight: 500, color: 'black' }} />
                    </Button>
                    <Typography dir={lang_dir}
                        style={{
                            lineHeight: '1.5', textAlign: 'justify',
                            padding: '24px 24px 0px 24px', 
                        }}>
                        {Tabstext.RecordingQualityExplained[props.lang]}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between', margin:'12px 24px' }} >
                    <OutlinedButton
                        onClick={dismiss}
                    >
                        {Tabstext.RemindMeLater[props.lang]}
                    </OutlinedButton>
                    < ContainedButton
                        startIcon={<MicIcon />}
                        onClick={handleRecordingTestClick}
                    >
                        <div style={{ marginRight: '10px' }}>
                            {Tabstext.RecordingTest[props.lang]}
                        </div>
                    </ContainedButton>
                </div>
            </Popover>
        </div>
    );
}

export default RecordingReminderPopup



