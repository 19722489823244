import React, { useState, useEffect } from 'react';
import Tabstext from './tabstext';
import { Tabs, } from '@mui/material';
import { PracticeTab } from './customized_components';


function PracticeRecordingsSelect(props) {
    var initialState = props.feedbackDisplay ? 'recordings' : 'practice';
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(props.feedbackDisplay ? 'recordings' : 'practice');
    }, [props.feedbackDisplay]);

    const handleSelect = (event, val) => {
        setState(val);
        props.setFeedbackDisplayCallback(val=='recordings');
    };

    return (
        <Tabs
            orientation="horizontal"
            value={state}
            onChange={handleSelect}
            indicatorColor='transparent'
            style={{ minHeight:0, }}
        >
            <PracticeTab
                label={props.reviewMode ? Tabstext.reference_score_tab[props.lang] : Tabstext.practice_tab[props.lang]}
                value={'practice'}
                />
            <PracticeTab
                label={props.reviewMode ? Tabstext.feedback_tab[props.lang] : Tabstext.your_recordings_tab[props.lang]}
                value={'recordings'}
                />
        </Tabs>
    )
}

export default PracticeRecordingsSelect;

