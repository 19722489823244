import React, { useState, useEffect } from 'react';
import { axiosInstance, setupAxiosInterceptors } from './axios_instance';

function RenderLyricsHtml(props) {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        const fetchHTMLFile = async () => {
            try {
                const response = await axiosInstance.get(props.lyrics_file_url);
                setHtmlContent(response.data);
            } catch (error) {
                console.error('Error fetching HTML file:', error);
            }
        };

        fetchHTMLFile();
    }, [props.lyrics_file_url]);

    const replaceSrcUrl = (html) => {
        const lyricsFilePath = props.lyrics_file_url.substring(0, props.lyrics_file_url.lastIndexOf('/') + 1);
        return html.replace(/src="([^"]+\.(svg|jpg))"/g, `src="${lyricsFilePath}$1"`);
    };

    return (
        <div dangerouslySetInnerHTML={{ __html: replaceSrcUrl(htmlContent) }}  />
    );
}


export default RenderLyricsHtml;

