//import {Vex} from './vexflow';
import {
    Renderer,
    Stave,
    StaveNote,
    Formatter,
    Note,
    Dot,
    Annotation,
    StaveTie,
    Accidental,
    TextNote,
    Voice,
    Barline,
    Beam,
    Tuplet,
 } from './vexflow';

import {
    parseDataNotes, determineLeadingMeasures, findWholeNotePixelsForTextedNotes, findNoteDensity, findMaxDensityPerMeasure,
    determineStaveHeightAdd, stripRest, DetermineDurationAndDash, ParseBeamsForMeasure, cumulativeSum,
} from './render_score_utils'

// stave drawing constants
var StaveLineParams = [];
var NotesXposition = []
var AccNoteTime = [0];
var CursorSpeed = [];
var StaveHeight = 0;
var NumVisibleLines = 0;
var StaveAddPixels = 20;
var scoretempo = {};
var FirstScrollLine = 2;

const keySignatureSize = {
'C':0,
'F':1,
'Bb':1.45,
'Eb':1.95,
'Ab':2.4,
'Db':2.9,
'Gb':3.4,
'Cb':4,
'G':1,
'D':1.55,
'A':2.1,
'E':2.65,
'B':3.2,
'F#':3.75,
'C#':4.3,
}


//var CursorTimeAdvance = 0.15 // sec, calibrated for tempo=120
const staveLeftMarginPixels = 10;

export function renderScore(canvas, musicData, musicCursor, maxWidth, maxContainerHeight) {

    const VF = {
        Renderer,
        Stave,
        StaveNote,
        Formatter,
        Note,
        Dot,
        Annotation,
        StaveTie,
        Accidental,
        TextNote,
        Voice,
        Barline,
        Beam,
        Tuplet,
    };

    // var beams = [];
    const CanvasExtraHeight = 18;
    const MinInterNoteSpacing = 40;
    const AdHocTextCompressionFactor = 0.75;
    const timeSignaturePixels = 25;
    const ClefPixels = 35;
    const FaKeySignaturePixels = 20;
    const StaveHeightPixels = 100;
    const StaveHeightAddPixels = 5
    const SoftmaxFactor = 8; // lower values (down to 1) aligns the notes more evenly, irrespective of their duration
    const lineWidth = maxWidth * 0.9;
    var m = 0; // measure counter
    var line = 0;
    //	var accNoteDurationInMeas = 0;
    var noteIndexInMeasure = 0;
    //var lastNoteIndexInPrevMeasure = 0;
    var vfnotes = []; // array of arrays of vfnotes per measure
    var voices1 = [];
    var voices2 = [];
    var textvoice = [];
    var ties = [];
    var stems = []
    //	var staveJustifyWidth = [];
    var staves = [];
    var beams = []; // array of arrays of beams per measure
    var tuplets = []; // array of arrays of beams per measure
    var tieStartIndex = 0;
    var numLeadingMeasures = 2;
    var CumMeasureDurations = 0;
    var x = staveLeftMarginPixels;
    var clefSplit = musicData.clef.split('-');
    const Clef = clefSplit[0];
    var remainder;
    var PixelsPerWholeNote;


    // Allow flexibiility with short window
    var LyricFontSize = 16;
    var SmallLyricFontSize = 14;

	var renderer = new VF.Renderer(canvas, VF.Renderer.Backends.CANVAS);
	var context = renderer.getContext();

	// initialize key signatures and corresponding margins
    var keySignatures = {0: 'C'};
    if (musicData.scale) {
        for (let n=0; n<musicData.scale.length; n++)
            keySignatures[musicData.scale[n][0]]=musicData.scale[n][1]
    }
    var keySignaturePixels = FaKeySignaturePixels*keySignatureSize[keySignatures[0]];

    // from timeSignatures to measureDurations dict
	if (musicData.timeSignatures==null) // backward compatibility to scores with single timeSignature
	    musicData.timeSignatures = {0: musicData.timeSignature};

    var measureDurations = {};
    var sum = 0;
    for (let k of Object.keys(musicData.timeSignatures)) {
        let sigSplit = musicData.timeSignatures[k].split('/');
        var measureDuration = parseInt(sigSplit[0])/parseInt(sigSplit[1])
        measureDurations[k] =  measureDuration;
        sum += measureDuration;
	}
	var AvgMeasure = sum/Object.keys(musicData.timeSignatures).length;

	var durationmodify = {};
    if (musicData.durationmodify)
        durationmodify = musicData.durationmodify;
    if (musicData.tempo)
        scoretempo = musicData.tempo;
        if (Object.keys(scoretempo).length > 1) {
            let t0 = Object.keys(scoretempo)[0];
            let last_tempo = scoretempo[t0]
            let ritready = 1;
            for (let i=1; i<Object.keys(scoretempo).length; i++) {
                let t = Object.keys(scoretempo)[i];
                if (scoretempo[t]>last_tempo)
                    ritready = 1;
                if (scoretempo[t]<last_tempo & ritready==1) {
                    let n = parseInt(t);
                    if (musicData.dynamics == undefined)
                        musicData.dynamics = Array(musicData.notes.length);
                    musicData.dynamics[n] = 'rit.';
                    ritready = 0;
                }
                last_tempo = scoretempo[t];
            }
        }
    else
        scoretempo = {'0': 120};


    StaveLineParams = [];
    NotesXposition = Array(musicData.notes.length+1);
    AccNoteTime = Array(musicData.notes.length+1);
    CursorSpeed = Array(musicData.notes.length);

    var parsedNotes, measureEndFlag, numMeasures, measureEndNotes;
    [parsedNotes, measureEndFlag, numMeasures, measureEndNotes] = parseDataNotes(musicData.notes, clefSplit, keySignatures, measureDurations, AccNoteTime, durationmodify, scoretempo);

    // determine numMeasuresPerLine
    let WholeNotesPerLine = Math.max(0.5, Math.floor(lineWidth / 200 / 2) * 2); // where did this come from
    let noteDensity; // stands for the maximal number of notes in a whole
    let WholeNotePixels;
    let numMeasuresPerLine;

    noteDensity = findMaxDensityPerMeasure(measureEndNotes, numLeadingMeasures, numMeasures)
    WholeNotePixels = MinInterNoteSpacing * noteDensity;
    if (musicData.text && (!musicData.textColors || !musicData.textColors.includes('red')))
        WholeNotePixels = Math.max(WholeNotePixels, findWholeNotePixelsForTextedNotes(musicData.notes, musicData.text, measureEndNotes, LyricFontSize, AdHocTextCompressionFactor));
    WholeNotesPerLine = lineWidth / WholeNotePixels;
    numMeasuresPerLine = Math.min(8, Math.max(2, Math.floor(WholeNotesPerLine / AvgMeasure)));

    // if small screen with numMeasuresPerLine=2, consider increasing to 3 with smaller font
    var fontsize = LyricFontSize;
    if (numMeasuresPerLine == 2) {
        let temp = WholeNotePixels * SmallLyricFontSize / LyricFontSize; 
        let temp1 = Math.min(WholeNotesPerLine, lineWidth / temp); // WholeNotesPerLine
        if (temp1 / AvgMeasure >= 3) {
            numMeasuresPerLine = 3;
            fontsize = SmallLyricFontSize;
        }
    }
    let finalWholeNotePixels = lineWidth / numMeasuresPerLine / AvgMeasure;

    numLeadingMeasures = determineLeadingMeasures(musicData.numLeadingMeasures, measureEndFlag, musicData.text, musicData.textColors);
    // if possible, reduce numMeasuresPerLine to fit equal number of measures per line
    if (numMeasuresPerLine > 4) {
        let temp = numMeasures - numLeadingMeasures;
        let n1 = numMeasuresPerLine;
        let num = n1;
        remainder = temp % num;
        if (remainder > 0) {
            while (n1 > 4) {
                n1 -= 1;
                let r = temp % n1;
                if (r == 0 || r > remainder) {
                    remainder = r;
                    num = n1;
                }
            }
            numMeasuresPerLine = num;
        }
    }

    // leading measures lines and WholeNote compression
    let LeadingMeasureCompression;
    let LeadingMeasuresInLineArr;
    let numLeadingLines;
    noteDensity = findMaxDensityPerMeasure(measureEndNotes,0,numLeadingMeasures);
    WholeNotePixels = MinInterNoteSpacing * noteDensity; // minimal
    let textedWholeNotesPixels = 0;
    if (musicData.text && (!musicData.textColors || !musicData.textColors.includes('red'))) {
        textedWholeNotesPixels = findWholeNotePixelsForTextedNotes(
        musicData.notes.slice(measureEndNotes[numLeadingMeasures - 2] + 1, measureEndNotes[numLeadingMeasures - 1] + 1),
        musicData.text.slice(measureEndNotes[numLeadingMeasures - 2] + 1, measureEndNotes[numLeadingMeasures - 1] + 1),
        measureEndNotes, LyricFontSize, AdHocTextCompressionFactor);
    }
    WholeNotePixels = Math.max(WholeNotePixels, textedWholeNotesPixels);
    //WholeNotesPerLine = lineWidth / WholeNotePixels;
    let numLeadingMeasuresInLine = Math.min(numLeadingMeasures,Math.max(2, Math.floor(lineWidth / (WholeNotePixels *AvgMeasure))));
    numLeadingLines = Math.ceil(numLeadingMeasures / numLeadingMeasuresInLine);
    LeadingMeasuresInLineArr = new Array(numLeadingLines).fill(numLeadingMeasuresInLine);
    remainder = numLeadingMeasuresInLine * numLeadingLines - numLeadingMeasures;
    while (remainder > 0) {
        for (let i = 0; i < numLeadingLines; i++) {
            LeadingMeasuresInLineArr[i] -= 1;
            remainder -= 1;
            if (remainder == 0)
                break;
        }
    }
    if (LeadingMeasuresInLineArr[0] == 1 && numLeadingLines == 2) { // try to absorb the 1st line in a squeezed 2nd line
        if (lineWidth >= WholeNotePixels * AvgMeasure * numLeadingMeasures)
            LeadingMeasuresInLineArr = [numLeadingMeasures];    
    }
    if (LeadingMeasuresInLineArr[numLeadingLines - 1] < numMeasuresPerLine)
        LeadingMeasureCompression = 1;
    else {
        LeadingMeasureCompression = Math.min(1, Math.max(0.5, WholeNotePixels / finalWholeNotePixels));
        LeadingMeasureCompression = Math.min(LeadingMeasureCompression, numMeasuresPerLine/LeadingMeasuresInLineArr[numLeadingLines-1])
    }

    const leftmostLeading = cumulativeSum(LeadingMeasuresInLineArr);
    leftmostLeading.unshift(0);

    // initialize StaveLineParams
    var leftMargin = ClefPixels + keySignaturePixels + timeSignaturePixels;
    StaveLineParams = [[0, 0, leftMargin, 0, 0]];

    if (musicData.FdbCode)
        var FdbCode = musicData.FdbCode; else FdbCode = [0,0];

    var StaveHeightAdd;
    var textLine;
    [StaveHeightAdd,textLine] = determineStaveHeightAdd(parsedNotes, clefSplit, musicData.text, musicData.dynamics,FdbCode);
    StaveAddPixels = StaveHeightAdd * StaveHeightAddPixels;
    StaveHeight = StaveAddPixels + StaveHeightPixels;
    NumVisibleLines = maxContainerHeight / StaveHeight;
    FirstScrollLine = 2;
    if (NumVisibleLines < 3) {
        let factor = NumVisibleLines / 3;
        if (factor >= 0.8) {
            StaveHeight = Math.floor(StaveHeight * factor);
            LyricFontSize = Math.floor(LyricFontSize * factor);
            SmallLyricFontSize = Math.floor(SmallLyricFontSize * factor);
            textLine = Math.ceil(textLine * factor);
        }
        else {
            FirstScrollLine = 1;
            factor = NumVisibleLines / 2;
            if (factor >= 0.8) {
                StaveHeight = Math.floor(StaveHeight * factor);
                LyricFontSize = Math.floor(LyricFontSize * factor);
                SmallLyricFontSize = Math.floor(SmallLyricFontSize * factor);
                textLine = Math.ceil(textLine * factor);
            }
        }
    }

	vfnotes.push([]); // initialize vfnotes[0]
	ties.push([]); // initialize ties[0]
    beams.push([]); // initialize beams[0]
    tuplets.push([]); // initialize tuplets[0]
    var line = 0;
    var IsLeftmost = true;

	//> loop over vfnotes and pack in measures
	for (let noteCnt = 0; noteCnt < musicData.notes.length; noteCnt++) {

		//> generate a VF note object and push to vfnotes array
		let notename, duration, dot, accidental;
		[notename, duration, dot, accidental] =  parsedNotes[noteCnt];
		var vfnote = new VF.StaveNote({clef: Clef, keys: [notename], duration: duration, auto_stem: true });
		if (dot) {
            VF.Dot.buildAndAttach([vfnote])
		}
        if (musicData.dynamics && musicData.dynamics[noteCnt]) {
            vfnote.addModifier(new VF.Annotation(musicData.dynamics[noteCnt])
                .setFont("Times", 14, "italic")
                .setVerticalJustification(VF.Annotation.VerticalJustify.TOP)
                .setJustification(1)); // LEFT
            if (musicData.dynamics[noteCnt]=='rit.')
                vfnote.textColor = 'black';
        }
		if (accidental)
		    vfnote.addModifier(new VF.Accidental(accidental))
		if (musicData.noteColors)
		    vfnote.setStyle({fillStyle: musicData.noteColors[noteCnt] == '' ? 'black' : musicData.noteColors[noteCnt], strokeStyle: musicData.noteColors[noteCnt] == '' ? 'black' : musicData.noteColors[noteCnt]});
		if (musicData.dynamicsColors && musicData.dynamics[noteCnt] != 'rit.')
		    vfnote.textColor = musicData.dynamicsColors[noteCnt];
        if (musicData.stems && musicData.stems.length>0 && musicData.stems[noteCnt]!=0)
            vfnote.setStemDirection(musicData.stems[noteCnt]);

		vfnotes[m].push(vfnote); // push vfnotes to vfnotes[m] (= m-th measure)

		if (musicData.ties && musicData.ties.length>0) {
		    if (musicData.ties[noteCnt]==-1) {
		        tieStartIndex = noteIndexInMeasure;
		    }
			if (musicData.ties[noteCnt]==1) {
				let tie = new VF.StaveTie({first_note: vfnotes[m][tieStartIndex], last_note: vfnotes[m][noteIndexInMeasure], first_indices: [0],	last_indices: [0]});
				if (m>0 & noteIndexInMeasure==0) { // add a tie to last vfnote of previous measure
					// tie = new VF.StaveTie({first_note: vfnotes[m-1][lastNoteIndexInPrevMeasure],	last_note: vfnotes[m-1][lastNoteIndexInPrevMeasure+1],	first_indices: [0],	last_indices: [0]});
					tie = new VF.StaveTie({first_note: vfnotes[m-1][vfnotes[m-1].length-1],	first_indices: [0], last_indices: [0]});
					ties[m-1].push(tie);
				}
				else {
				    ties[m].push(tie);
                }
			}
		}

        if (musicData.beams && musicData.beams.length>0)
            beams[m].push(musicData.beams[noteCnt])
        if (musicData.tuplets && musicData.tuplets.length>0)
            tuplets[m].push(musicData.tuplets[noteCnt])

		// add text as voice2
        if (musicData.text) {
			let note_duration = stripRest(duration);
			let text = musicData.text[noteCnt];
			let text_duration, dash_duration, dash;
            [text, text_duration, dash_duration, dash] = DetermineDurationAndDash(text, note_duration, musicData.text[Math.min(noteCnt + 1, musicData.notes.length - 1)], fontsize, finalWholeNotePixels);
			let textstruct = new VF.TextNote({text: text, font: {family: 'Times', size: fontsize},duration: text_duration}).setLine(textLine).setJustification(VF.TextNote.Justification.CENTER);
			if (musicData.textColors)
				textstruct.setStyle({fillStyle: musicData.textColors[noteCnt] == '' ? 'black' : musicData.textColors[noteCnt]});
			else
				textstruct.setStyle({fillStyle: 'black'});
			textvoice.push(textstruct);
			if (dash=='-') {
			    textstruct = new VF.TextNote({text: dash, font: {family: 'Times', size: fontsize},duration: dash_duration}).setLine(textLine).setJustification(VF.TextNote.Justification.CENTER);
                if (musicData.textColors)
                    textstruct.setStyle({fillStyle: musicData.textColors[noteCnt] == '' ? 'black' : musicData.textColors[noteCnt]});
                else
                    textstruct.setStyle({fillStyle: 'black'});
                textvoice.push(textstruct);
			}
		}

        // initialize a stave for this measure (m)
		if (measureEndFlag[noteCnt]) {
			if (Object.keys(keySignatures).includes(m.toString())) {
				var KeySignature = keySignatures[m]
				keySignaturePixels = FaKeySignaturePixels*keySignatureSize[KeySignature];
			}
			if (Object.keys(musicData.timeSignatures).includes(m.toString())) {
			    var TimeSignature = musicData.timeSignatures[m]
			    measureDuration = measureDurations[m];
    	    }
            if (IsLeftmost) {// determine once per line
                PixelsPerWholeNote = (lineWidth - keySignaturePixels - ClefPixels) / (numMeasuresPerLine * AvgMeasure);
                if (m < numLeadingMeasures) {
                    PixelsPerWholeNote *= LeadingMeasureCompression;
                }                    
            }

			let staveWidth = AvgMeasure * PixelsPerWholeNote;
            if (IsLeftmost)
                staveWidth += ClefPixels + keySignaturePixels;// + timeSignaturePixels * (m==0);

			let y = line * StaveHeight;
			var stave = new VF.Stave(x, y, staveWidth);

    		if (Object.keys(keySignatures).includes(m.toString()) | IsLeftmost)
				stave.addKeySignature(KeySignature);

			if (Object.keys(musicData.timeSignatures).includes(m.toString())) {
    			stave.addTimeSignature(musicData.timeSignatures[m]);
    	    }
			if (IsLeftmost) {
				if (clefSplit.length==2)
					stave.addClef(Clef,'default', clefSplit[1]);
				else
					stave.addClef(Clef);
			}
            CumMeasureDurations += measureDuration;
			x += staveWidth; // position of next measure if not leftmost
//			var beam = new VF.Beam(vfnotes[m]);
//			beam.setContext(context).draw();
			// define voice to support multiple voices
			var sigSplit = TimeSignature.split('/');
			var voice = new VF.Voice({num_beats: parseInt(sigSplit[0]),	beat_value: parseInt(sigSplit[1])});
			voice.setMode(VF.Voice.Mode.SOFT);
			voice.addTickables(vfnotes[m]);
			voices1.push(voice)

			stave.setContext(context).draw();
			textvoice.forEach(function(t) {t.setStave(stave)});
			var voice2 = new VF.Voice({num_beats: parseInt(sigSplit[0]), beat_value: parseInt(sigSplit[1])});
			voice2.setMode(VF.Voice.Mode.SOFT);
			voice2.addTickables(textvoice);
			voices2.push(voice2)

            if (musicData.barlines != undefined && m in musicData.barlines) {
                const barlinetype = musicData.barlines[m];
                if (barlinetype == 'double')
                    stave.setEndBarType(VF.Barline.type.DOUBLE);
                else if(barlinetype == 'end')
                    stave.setEndBarType(VF.Barline.type.END);
            }

			if(noteCnt == musicData.notes.length-1) {// force END in last measure
			    stave.setEndBarType(VF.Barline.type.END);
			}
			else { // not last measure
//				accNoteDurationInMeas = 0;
				vfnotes.push([]); // initialize next measure vfnotes[m]
				ties.push([]); // initialize next measure ties[m]
				beams.push([]); // initialize next measure beams[m]
				tuplets.push([]); // initialize next measure beams[m]
				textvoice = [];

                // check if new line starts next measure
                IsLeftmost = ((m+1 >= numLeadingMeasures && (m+1 - numLeadingMeasures) % numMeasuresPerLine == 0) || leftmostLeading.includes(m+1));
                if (IsLeftmost) { // set StaveLineParams for cursor motion                
                    let leftMargin = ClefPixels + keySignaturePixels + timeSignaturePixels * (m+1 == 0); // correspond to the previous measure
                    StaveLineParams.push([CumMeasureDurations, PixelsPerWholeNote, leftMargin, noteCnt+1, x]);
                    x = staveLeftMarginPixels;
                    line += 1;
                }
                m++; // increment measure
			}
			staves.push(stave);
			noteIndexInMeasure = 0;
		} // end create a new measure

		else
			noteIndexInMeasure += 1;

	} // end loop over musicData.notes

    StaveLineParams.push([CumMeasureDurations,PixelsPerWholeNote, ClefPixels+keySignaturePixels+timeSignaturePixels, musicData.notes.length, x]); // push one more pseudo line

    const numLines = Math.ceil(numLeadingLines + (staves.length-numLeadingMeasures) / numMeasuresPerLine);
	const canvasHeight = StaveHeight * numLines + CanvasExtraHeight/2;
	renderer.resize(Math.min(2000,maxWidth), Math.min(32000,canvasHeight)); // avoid unexplained warning on size that extends beyond 32767

	// render the score by looping over stave draw and voice draw
	let n = 0;
	for (m = 0; m < staves.length; m++) {
		staves[m].setContext(context).draw(); // needed again because of the resize
		let v = [voices1[m],voices2[m]];
		if (beams[m] && beams[m].length>0) {
            let index = ParseBeamsForMeasure(beams[m]);
            var vfbeams = []
            for (let i=1; i<index.length; i+=2) {
                let beami = new VF.Beam(vfnotes[m].slice(index[i - 1], index[i] + 1));
                let beamStyle = beami.notes[0].getStyle()
                if (beamStyle != null) {
                    let beamColor = beamStyle.fillStyle;
                    beami.setStyle({ fillStyle: beamColor, strokeStyle: beamColor });
                }
                vfbeams.push(beami)
            }
        }
		else
    	    vfbeams = VF.Beam.generateBeams(vfnotes[m]);

        let formatter = new VF.Formatter({ softmaxFactor: SoftmaxFactor }).joinVoices(v).formatToStave(v, staves[m]); // auto based on stave
        let temp = formatter.getMinTotalWidth();
		voices1[m].draw(context, staves[m]);
		voices2[m].draw(context, staves[m]);
		//VF.Formatter.FormatAndDraw(context, staves[m], vfnotes[m]); // drawing only the vfnotes doesnt require "voice" nor the above two lines
		ties[m].forEach(function(t) {t.setContext(context).draw()});
		vfbeams.forEach(function(b) {b.setContext(context).draw()});

		if (tuplets[m] && tuplets[m].length>0) {
            let index = ParseBeamsForMeasure(tuplets[m]);
//            tt = []
            for (let i=1; i<index.length; i+=2) {
                let t = new VF.Tuplet(vfnotes[m].slice(index[i-1],index[i]+1));
                t.setContext(context).draw();
            }
        }

        for (let i=0; i<vfnotes[m].length; i++) {
            NotesXposition[n] = vfnotes[m][i].getAbsoluteX();
            n++;
        }
        NotesXposition[n] = 0;
	}

    for (n=0; n<musicData.notes.length; n++) {
        CursorSpeed[n] = (NotesXposition[n+1]-NotesXposition[n])/(AccNoteTime[n+1]-AccNoteTime[n]);
    }
	// position the moving cursor after the TimeSignature
    musicCursor.style.left = (ClefPixels+timeSignaturePixels+FaKeySignaturePixels*keySignatureSize[keySignatures[0]]+staveLeftMarginPixels)+'px';
    NumVisibleLines = Math.min(Math.floor((maxContainerHeight)/StaveHeight),numLines);
    const containerHeight = StaveHeight*NumVisibleLines + CanvasExtraHeight;

    return containerHeight;
}

// StaveLineParams[l]=[cumulatedDurationUpToLine[l], PixelsPerWholeNote[l], leftMargin[l], noteCntUptoLine[l]], xEndPrevLine);
var ScrollEnable = false;
var ScrollLine = 0;

export function scoreCursorFrame(audio, tempo, cursor, container, delay) {    
    if (cursor) {
        var cursorTime = audio.currentTime;

        if (delay) {
            cursorTime = Math.max(0, cursorTime - delay);
            if (delay < 0 && audio.currentTime == 0) // handle negative delay, where currentTime will stay zero during the timeout (=-delay)
                cursorTime = 0;
        }
        if (cursorTime === 0) {
            cursor.style.transform = '';
            ScrollLine = FirstScrollLine;
            return;
        }
        const nlines = StaveLineParams.length - 1;
        const temponorm = scoretempo['0'] / tempo;        
        for (let l = 0; l < nlines; l++) {
            const firstNoteNextLine = StaveLineParams[l + 1][3];
            const AccTimePerLine = AccNoteTime[firstNoteNextLine] * temponorm;
            if (cursorTime < AccTimePerLine) {
                const yPos = l * StaveHeight;
                const firstNoteThisLine = StaveLineParams[l][3];
                let n = firstNoteThisLine;
                for (; n < firstNoteNextLine; n++) {
                    if (AccNoteTime[n + 1] * temponorm > cursorTime)
                        break;
                }
                const DurationInCurrNote = cursorTime - AccNoteTime[n] * temponorm;
                let x;
                if (n + 1 < firstNoteNextLine) {
                    x = NotesXposition[n] + DurationInCurrNote * CursorSpeed[n] / temponorm;
                } else {
                    x = NotesXposition[n] + DurationInCurrNote * (StaveLineParams[l + 1][4] - NotesXposition[n]) / (AccNoteTime[n + 1] - AccNoteTime[n]) / temponorm;
                }
                const xPos = x - cursor.offsetLeft;
                cursor.style.transform = 'translate(' + xPos + 'px, ' + yPos + 'px)';
                if (xPos < 80 && !audio.paused && l==ScrollLine && ScrollEnable) {
                    container.scrollTop += StaveHeight;
                    //container.scrollBy(0, StaveHeight);
                    ScrollLine += 1;
                    ScrollEnable = false;
                }

                break;
            }
            ScrollEnable = true;
            if (!audio.paused) {
                // cursor.scrollIntoView(true);
                cursor.scrollIntoViewIfNeeded(true); // not supported by firefox
            }
       }
    }
}

export function moveCursor(click, cursor, audio, tempo, delay) {
    const x = click.offsetX;
    const y = click.offsetY;
    const xPos = x - cursor.offsetLeft;
    const yPos = y - (cursor.offsetTop + cursor.offsetHeight);
    const epsilon = 0.001; // shift the cursor to the right a tiny bit to handle first note in line

    cursor.style.transform = 'translate(' + xPos + 'px, ' + yPos + 'px)';

    const temponorm = scoretempo['0'] / tempo;
    const l = Math.max(0, Math.round(yPos / StaveHeight));
    const first_note_this_line = StaveLineParams[l][3];
    let n = first_note_this_line;

    for (; n < StaveLineParams[l + 1][3]; n++) {
    if (x < NotesXposition[n])
        break;
    }

    if (n > 0 && NotesXposition[n] - x > x - NotesXposition[n - 1])
        n -= 1;

    const cursorTime = (AccNoteTime[n]+epsilon) * temponorm;
    audio.currentTime = cursorTime;
    if (delay)
        audio.currentTime += delay;
}

