import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from './axios_instance';
import VerticalHeader from './vertical_header';
import Dashboard from './dashboard';
import Exercise from './exercise';
import StudentDetails from './student_details';
import MyStudents from './mystudents';
import User from './user';
import Games from './games/Games';
import { StopAnimation } from './exercise_utils';
import './styles/extras.css';
import Onboarding from './onboarding';

function Home(props) {
    const [scale, setScale] = useState(1);
    const [studentUser, setStudentUser] = useState(null);
    const [passedRectest, setPassedRectest] = useState(null);
    const [userFullname, setUserFullname] = useState(null);
    const [asStudent, setAsStudent] = useState(props.headerContext.as_student);
    const [content, setContent] = useState(null);
    const [enableTabs, setEnableTabs] = useState(true);

    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    useEffect(() => {
        setUserFullname([props.headerContext.user_firstname, props.headerContext.user_lastname]);
        setPassedRectest(props.headerContext.passed_recording_test);
        setAsStudent(props.headerContext.as_student);
    }, [props.headerContext]);

    const setScaleCallback = (val) => {
        setScale(val);
    };
    const enableTabsCallback = (val) => {
        setEnableTabs(val);
    };
    const setPassedRectestCallback = (val) => {
        setPassedRectest(val);
        axiosInstance.post('/api/user_settings/', { passed_recording_test: 1 }).then();
    };

    const setAsStudentCallback = (val) => {
        setAsStudent(val);
    };

    const setUserFullnameCallback = (val1, val2) => {
        setUserFullname([val1, val2]);
    };

    const windowSize = [window.innerWidth / scale, window.innerHeight / scale];
    useEffect(() => {
        const handleResize = () => {
            const newBaseFontSize = Math.min(window.innerWidth / 110, 16);
            document.documentElement.style.setProperty('--base-font-size', `${newBaseFontSize}px`);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [scale]);

    const handleNavigation = (path, username = null) => {
        setContent(path);
        navigate(`/app/${path}`);
        if (path === 'my_progress') {
            setStudentUser(username || props.headerContext.username);
        }
        if (path !== 'solfege' && path !== 'theory' && path !== 'songs') {
            StopAnimation();
        }
    };

    // Update the content state when the location changes
    useEffect(() => {
        // Define the list of valid routes
        const validRoutes = [
            'dashboard',
            'solfege',
            'songs',
            'theory',
            'my_progress',
            'my_groups',
            'games',
            'user',
        ];
        const path = location.pathname.replace('/app/', '').split('/')[0]; // Extract the base path
        if (validRoutes.includes(path)) {
            setContent(path);
        } else {
            setContent('dashboard'); // Default to dashboard if the path is invalid
        }
    }, [location]);

    return (
        <div
            className="homePage"
            style={{
                transform: `scale(${scale})`,
                width: `${100 / scale}%`,
                height: `${100 / scale}%`,
                transformOrigin: 'top left',
            }}
        >
            <VerticalHeader
                content={content}
                authenticated={props.authenticated}
                headerContext={props.headerContext}
                langChangeCallback={props.langChangeCallback}
                lang={props.lang}
                asStudent={asStudent}
                setAsStudentCallback={setAsStudentCallback}
                enableTabs={enableTabs}
                setContentCallback={handleNavigation}
                userFullname={userFullname}
            />
            <Routes>
                <Route
                    path="dashboard/*"
                    element={
                        <Dashboard
                            {...props}
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                            asStudent={asStudent}
                            setAsStudentCallback={setAsStudentCallback}
                            passedRectest={passedRectest}
                            setPassedRectestCallback={setPassedRectestCallback}
                            setContentCallback={handleNavigation}
                            userFullname={userFullname}
                            firstLogin={props.firstLogin}
                            enableTabsCallback={enableTabsCallback}
                        />
                    }
                />
                <Route
                    path="solfege"
                    element={
                        <Exercise
                            {...props}
                            content="solfege"
                            pitchTolerance={props.headerContext.pitch_tolerance}
                            pitchToleranceRange={props.headerContext.pitch_tolerance_range}
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                            asStudent={asStudent}
                            passedRectest={passedRectest}
                        />
                    }
                />
                <Route
                    path="songs"
                    element={
                        <Exercise
                            {...props}
                            content="songs"
                            pitchTolerance={props.headerContext.pitch_tolerance}
                            pitchToleranceRange={props.headerContext.pitch_tolerance_range}
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                            asStudent={asStudent}
                            passedRectest={passedRectest}
                        />
                    }
                />
                <Route
                    path="theory"
                    element={
                        <Exercise
                            {...props}
                            content="theory"
                            pitchTolerance={props.headerContext.pitch_tolerance}
                            pitchToleranceRange={props.headerContext.pitch_tolerance_range}
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                            asStudent={asStudent}
                            passedRectest={passedRectest}
                        />
                    }
                />
                <Route
                    path="my_progress"
                    element={
                        props.authenticated ? (
                            <StudentDetails
                                {...props}
                                username={studentUser}
                                scale={scale}
                                setScaleCallback={setScaleCallback}
                                teacherReview={false}
                            />
                        ) : (
                            <Navigate to="dashboard" />
                        )
                    }
                />
                <Route
                    path="my_groups/*"
                    element={
                        props.authenticated && props.headerContext.TeacherAttr ? (
                            <MyStudents
                                {...props}
                                scale={scale}
                                pitchTolerance={props.headerContext.pitch_tolerance}
                                pitchToleranceRange={props.headerContext.pitch_tolerance_range}
                                setScaleCallback={setScaleCallback}
                                windowSize={windowSize}
                            />
                        ) : (
                            <Navigate to="dashboard" />
                        )
                    }
                />
                <Route
                    path="games"
                    element={<Games {...props}
                        scale={scale} setScaleCallback={setScaleCallback}
                        content={content} windowSize={windowSize}
                    />}
                />
                <Route
                    path="user"
                    element={
                        <User
                            {...props}
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                            setUserFullnameCallback={setUserFullnameCallback}
                            user_firstname={props.headerContext.user_firstname}
                            allGroupIds={props.headerContext.allGroupIds}
                        />
                    }
                />
                <Route path="*" element={<Navigate to="dashboard" />} />
            </Routes>
        </div>
    );
}

export default Home;
