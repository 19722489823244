import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { CellLink, } from './customized_components';

const Breadcrumbs = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const decodeCharacterByCharacter = (segment) => {
        let decoded = '';
        let i = 0;

        while (i < segment.length) {
            if (segment[i] === '%' && i + 2 < segment.length) {
                const hex = segment.slice(i + 1, i + 3);
                if (/^[0-9A-Fa-f]{2}$/.test(hex)) {
                    decoded += String.fromCharCode(parseInt(hex, 16));
                    i += 3;
                    continue;
                }
            }
            decoded += segment[i];
            i += 1;
        }

        // Decode the accumulated bytes as UTF-8
        try {
            const utf8Decoder = new TextDecoder('utf-8');
            const bytes = Uint8Array.from(decoded.split('').map((char) => char.charCodeAt(0)));
            return utf8Decoder.decode(bytes);
        } catch (e) {
            return decoded; // Fallback to raw decoded string if UTF-8 decoding fails
        }
    };

    return (
        <div style={{marginLeft:'30px'}}>
        <MuiBreadcrumbs
            aria-label="breadcrumb"
            separator="/" // Separator without spaces
            sx={{
                '& .MuiBreadcrumbs-separator': {
                    margin: 0, // Remove space around separator
                },
            }}
        >
            {/* Home Link 
                <CellLink style={{ fontSize: '12px', }}
                    onClick={() => navigate("/app")}
                >
                    Home
                </CellLink>
             */}
            {/* Dynamic Links */}
            {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                // Crop text with ellipsis for long pathnames
                const croppedValue = value.length > 100 ? `${value.slice(0, 100)}...` : value;
                const decodedCroppedValue = decodeCharacterByCharacter(croppedValue);
                const decodedValue = decodeCharacterByCharacter(value);

                // If it's the last breadcrumb, show text instead of a link
                return index === pathnames.length - 1 ? (
                    <Typography
                        key={to}
                        color="text.primary"
                        sx={{
                            maxWidth: '300px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                        }}
                        title={decodedValue} // Full text on hover
                    >
                        {decodedCroppedValue}
                    </Typography>
                ) : (
                    <CellLink
                        key={to}
                        onClick={() => navigate(to)}
                        style={{
                            maxWidth: '300px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize:'12px',
                        }}
                            title={decodedValue} // Full text on hover
                    >
                            {decodedCroppedValue}
                    </CellLink>
                );
            })}
        </MuiBreadcrumbs>
        </div>
    );
};

export default Breadcrumbs;
