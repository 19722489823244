import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import { FormTitle, SolfyTextField, ContainedButton, SmallTitle } from './customized_components';
import { Paper, } from '@mui/material';

function UserProfile(props) {
    const lang = props.lang;
    const user = props.user;
    const [groupName, setGroupName] = useState(props.groupName);

    useEffect(() => {
        setFormData({
            firstname: user.first_name, lastname: user.last_name,
            groupid: user.group_id,
        });
    }, []);

    const [formData, setFormData] = useState(null);
    const [submitEnable, setSubmitEnable] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        if (name == 'groupid')
            setGroupNameFromServer(value);
        else
            setSubmitEnable(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.post('/api/user_settings/', { firstname: formData.firstname, lastname: formData.lastname, groupid: formData.groupid }).then();
        props.setUserFullnameCallback(formData.firstname,formData.lastname);
        setSubmitEnable(false);
        props.resetViewCallback(true);
    };

    const handleCancel = (e) => {
        setFormData({
            firstname: user.first_name, lastname: user.last_name,
            groupid: user.group_id,
        });
        props.resetViewCallback(false);
    };

    const setGroupNameFromServer = (id) => {
        if (props.allGroupIds.includes(parseInt(id))) {
            axiosInstance.post('/api/get_group_name/', { group_id: id })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.group_name) {
                            setGroupName(response.data.group_name);
                            setSubmitEnable(true);
                        }
                        else {
                            setGroupName(Tabstext.NoSuchGroupTab[props.lang]);
                            setSubmitEnable(false);
                        }
                    }
                });
        }
        else {
            setGroupName(Tabstext.NoSuchGroupTab[props.lang]);
            setSubmitEnable(false);
        }
    }

    const lang_dir = lang=='he' ? 'rtl' : 'ltr';

    if (user==null || formData==null)
        return null;

    return (
        <div dir={lang_dir}
            style={{
                display: props.display ? '' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column', 
            }}
            >
            <Paper className='longPaper' dir={lang_dir} >
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', gap:'32px', marginBottom: '8px', }}>
                        <SolfyTextField
                            label={Tabstext.FirstNameTab[lang]}
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleChange}
                            dir={lang_dir}
                            fullWidth
                        />
                        <SolfyTextField
                            label={Tabstext.LastNameTab[lang]}
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleChange}
                            dir={lang_dir}
                            fullWidth
                        />
                    </div>
                    <div style={{ marginBottom: '2px' }}>
                        <SolfyTextField
                            label={Tabstext.YourGroupTab[props.lang]}
                            name="groupid"
                            value={formData.groupid}
                            dir={lang_dir}
                            fullWidth
                            onChange={handleChange}
                        />
                    </div>
                    <SmallTitle style={{ fontSize: '12px', marginBottom: '20px' }} >
                        {groupName}
                    </SmallTitle>

                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ContainedButton
                            type="submit"
                            disabled = {!submitEnable}
                        >
                            {Tabstext.SaveTab[lang]}
                        </ContainedButton>
                        <ContainedButton
                            onClick={() => handleCancel()}
                            >
                            {Tabstext.CancelTab[lang]}
                        </ContainedButton>
                    </div>
                </form>
            </Paper>
        </div>
    );
}

export default UserProfile;