import React from 'react'

const ExerciseDescriptions_ro = {
        'Level1_Lesson01_E01_sol4': 'Sunetul și nota „sol”; valoarea de pătrime, notă și pauză;',
        'Level1_Lesson01_E02_sol4': 'Pătrimi: note, sunete și pauze; modele ritmice;',
        'Level1_Lesson01_R01_sol4': 'Pătrimi: note, sunete și pauze; modele ritmice; ',
        'Level1_Lesson01_R02_sol4_v1': 'Portativul muzical: este compus din 5 linii (care creează 4 spații); ',

        'Level1_Lesson02_E01_mi4': 'Sunetul și nota „mi”; pătrimi; modele ritmice;',
        'Level1_Lesson02_E02_mi4': 'Pătrimi: note, sunete și pauze; modele ritmice;',
        'Level1_Lesson02_R01_mi4': 'Pătrimi: note, sunete și pauze; modele ritmice;',
        'Level1_Lesson02_R02_mi4': '„Cheia Sol”: spirala acestui semn indică locul notei sol, pe linia a doua a portativului; nota mi se află pe prima linie;',

        'Level1_Lesson03_E01_sol4-mi4': 'Notele și sunetele „sol” și „mi”; modele ritmice cu pătrimi;',
        'Level1_Lesson03_E02_sol4-mi4': 'Notele și sunetele „sol” și „mi”; modele ritmice cu pătrimi;',
        'Level1_Lesson03_R01_sol4-mi4': 'Notele și sunetele „sol” și „mi”; modele ritmice cu pătrimi;',
        'Level1_Lesson03_R02_sol4-mi4': 'Între două bare verticale, numite „bare de măsură” sunt două pătrimi, fapt consemnat prin „2/4”, adică „măsura de două pătrimi”;',

        'Level1_Lesson04_E01_sol4-mi4': 'Intensități: forte - f, pentru sunete considerate puternic, tari, zgomotoase, și piano - p, pentru sunete considerate slabe, încete, silențioase;',
        'Level1_Lesson04_E02_sol4-mi4': 'Pătrimi: sunete, note și pauze; modele ritmice cu sol și mi, f- p;',
        'Level1_Lesson04_R01_sol4-mi4_v1': 'Pătrimi: sunete, note și pauze; modele ritmice cu sol și mi, f- p;',
        'Level1_Lesson04_R02_sol4-mi4_v1': 'Pătrimi: sunete, note și pauze; modele ritmice cu sol și mi, f- p;',

        'Level1_Lesson05_E01_sol4-mi4_v1': 'Legato (de prelungire) este un simbol care leagă valoarea a două note de aceeași înălțime; \
         două pătrimi conectate cu legato formează o doime, și arată ca o notă „goală”; f-p;',
        'Level1_Lesson05_E02_sol4-mi4_v1': 'Pătrimi: sunete, note și pauze; legato care formează doimi; f-p;',
        'Level1_Lesson05_R01_sol4-mi4_v1': 'Pătrimi: sunete, note și pauze; doimi; f-p;',
        'Level1_Lesson05_R02_sol4-mi4_v1': 'Pătrimi: sunete, note și pauze; doimi; f-p;',

        'Level1_Lesson06_E01_sol4-mi4': 'Pauza de doime este egală cu valoarea a două pauze de pătrimi;',
        'Level1_Lesson06_E02_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p;',
        'Level1_Lesson06_R01_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p;',
        'Level1_Lesson06_R02_sol4-mi4': 'Solfegiu cu 32 de măsuri de câte două pătrimi fiecare; sunete, note și pauze de doimi și pătrimi; sol și mi; f-p;',

        'Level1_Lesson07_E01_sol4-mi4': 'Recapitulare: pătrimi, sunete, note și pauze; modele ritmice; f-p. Exersarea solfegiului în diferite moduri:\
         1. înlocuirea pauzelor cu sunete de „percuție corporală” (bătăi din palme, pocnete de degete, etc.);\
         2. cântarea în „canon”: creați două grupuri; primul (liderul), începe să cânte; când ajunge la măsura nr. 3 (cu pauze),\
            al doilea începe de la măsura nr. 1, imitând liderul cu un decalaj de două măsuri, până la final.',
        'Level1_Lesson07_E02_sol4-mi4': 'Recapitulare: pătrimi, sunete, note și pauze; modele ritmice; f-p. Exersarea solfegiului în diferite moduri:\
         1. înlocuirea pauzelor cu sunete de „percuție corporală” (bătăi din palme, pocnete de degete, etc.);\
         2. cântarea în „canon”: creați două grupuri; primul (liderul), începe să cânte, iar când ajunge la măsura nr. 3 (sol, mi), \
            al doilea începe de la măsura nr. 1, imitând liderul cu un decalaj de două măsuri, până la final.',
        'Level1_Lesson07_R01_sol4-mi4': 'Recapitulare: pătrimi, sunete, note și pauze; modele ritmice; f-p. Exersarea solfegiului în diferite moduri:\
         1. înlocuirea pauzelor cu sunete de „percuție corporală” (bătăi din palme, pocnete de degete, etc.);\
         2. cântarea în „canon”: creați două grupuri; primul (liderul), începe să cânte, iar când ajunge la măsura nr. 2 (sol, pauză),\
            al doilea începe de la măsura nr. 1, imitând liderul cu un decalaj de o măsură, până la final.',
        'Level1_Lesson07_R02_sol4-mi4': 'Recapitulare: pătrimi: sunete, note și pauze; modele ritmice; f-p. Exersarea solfegiului în diferite moduri:\
         1. înlocuirea pauzelor cu sunete de „percuție corporală” (bătăi din palme, pocnete de degete, etc.); \
         2. cântarea în „canon”: creați două grupuri; primul (liderul), începe să cânte, iar când ajunge la măsura nr. 4 (mi, pauză),\
            al doilea începe de la măsura nr. 1, imitând liderul cu un decalaj de trei măsuri, până la final.',

        'Level1_Lesson08_E01_mi4': 'Crearea unei sincope: modificarea fluxului, sau cursului ritmic obișnuit de către semnul de legato,\
         care conectează a doua pătrime dintr-o măsură, cu prima din măsura următoare, creând o doime.',
        'Level1_Lesson08_E02_mi4': 'Practicând sincopa. Două semne de legato adiacente, sau vecine, care conectează,\
         sau contopesc trei pătrimi cu aceeași înălțime, creează o valoare, de trei pătrimi (3/4);',
        'Level1_Lesson08_R01_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p; legato, sincopa și valoarea de trei pătrimi;',
        'Level1_Lesson08_R02_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p; legato, sincopa și valoarea de trei pătrimi;',

        'Level1_Lesson09_E01_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p;',
        'Level1_Lesson09_E02_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p; legato, sincopa;',
        'Level1_Lesson09_R01_sol4-mi4': 'Pătrimi și doimi: sunete, note și pauze; f-p; sunet pe timp accentuat și pauză pe timp neaccentuat,\
         sau, mai puțin accentuat;',
        'Level1_Lesson09_R02_sol4-mi4': 'O pauză de pătrime urmată de un sunet de pătrime pe timpul neaccentuat, sau mai puțin accentuat creează un contra-timp,\
         timpul mai puțin accentuat, devenind accentuat',

        'Level1_Lesson10_E01_do4_v1': 'Sunetul și nota „do” – pătrimi și doimi, note și pauze;',
        'Level1_Lesson10_E02_do4': 'Pătrimi și doimi, sunete, note și pauze; f-p;',
        'Level1_Lesson10_R01_mi4-do4': 'Modele ritmice cu mi și do; f-p;',
        'Level1_Lesson10_R02_mi4-do4': 'Modele ritmice cu mi și do; f-p;',

        'Level1_Lesson11_E01_sol4-mi4': 'Modele ritmice cu mi și do; f-p;',
        'Level1_Lesson11_E02_mi4-do4': 'Modele ritmice cu mi și do; f-p;',
        'Level1_Lesson11_R01_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p. Cântarea în „canon”; creați două grupuri: primul începe să cânte,\
         iar când ajunge la măsura nr. 9 (mi, o doime), al doilea începe de la măsura nr. 1, imitând liderul cu un decalaj de 8 masuri, până la final;',
        'Level1_Lesson11_R02_sol4-mi4-do4': 'Modele ritmice cu sol, mi, do, sau do, mi sol; arpegiu, sau arpegii; f-p. Cântarea în „canon”; creați două grupuri:\
         primul începe să cânte până ajunge la măsura nr. 5 (mi, o doime); al doilea începe de la măsura nr. 1, imitând liderul cu un decalaj de 4 măsuri, până la final;',

        'Level1_Lesson12_E01_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p;',
        'Level1_Lesson12_E02_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p;',
        'Level1_Lesson12_R01_sol4-mi4-do4': 'Modele ritmice; f – p; semnul de legato, ce unește o doime cu o pătrime.\
         Cântarea în canon: imitarea liderului după 8 măsuri;',
        'Level1_Lesson12_R02_sol4-mi4-do4': 'Modele ritmice; f – p; semnul de legato, ce unește o doime cu o pătrime.\
         Cântarea în canon: imitarea liderului după 16 măsuri;',

        'Level1_Lesson13_E01_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p; Cântarea în canon: imitarea liderului după 8 măsuri;',
        'Level1_Lesson13_E02_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p; semne de legato, care unesc 3 pătrimi, sau o doime și o pătrime.\
         Cântarea în canon: imitarea liderului cu un decalaj de 8 măsuri;',
        'Level1_Lesson13_R01_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p;  semne de legato, care unesc 3 pătrimi, sau o doime și o pătrime.\
         Cântarea în canon: imitarea liderului cu un decalaj de 8 măsuri;',
        'Level1_Lesson13_R02_sol4-mi4-do4': 'Modele ritmice cu sol, mi și do; f-p; Cântarea în canon: imitarea liderului cu un decalaj de 8 măsuri;',

        'Level1_Lesson14_E01_re4_v2': 'Sunetul și nota re; modele ritmice cu re; f-p; ',
        'Level1_Lesson14_E02_mi4-re4-do4_v1': 'Modele ritmice; f – p; anacruză: sunet și notă care preced prima pătrime accentuată;',
        'Level1_Lesson14_R01_mi4-re4-do4_v1': 'Modele ritmice; f – p; anacruză: sunet și notă care preced prima pătrime accentuată; ',
        'Level1_Lesson14_R02_mi4-re4-do4': 'Modele ritmice; f – p; anacruză și sincopă; ',

        'Level1_Lesson15_E01_sol4-mi4-re4-do4': 'Modele ritmice; f – p; sincopă; ',
        'Level1_Lesson15_E02_sol4-mi4-re4-do4': 'Modele ritmice; f – p; sincopă; ',
        'Level1_Lesson15_R01_sol4-mi4-re4-do4': 'Modele ritmice; f – p; ',
        'Level1_Lesson15_R02_sol4-mi4-re4-do4': 'Modele ritmice; f – p; ',

        'Level1_Lesson16_E01_sol4-mi4-re4-do4': 'Modele ritmice; f – p; ',
        'Level1_Lesson16_E02_sol4-mi4-re4-do4': 'Modele ritmice; f – p; ',
        'Level1_Lesson16_R01_sol4-mi4-re4-do4': 'Modele ritmice; f – p; ',
        'Level1_Lesson16_R02_sol4-mi4-re4-do4': 'Modele ritmice; f – p; contratimp: înlocuirea sunetului și notei accentuate cu o pauză;',

        'Level1_Lesson17_E01_sol4-mi4-re4-do4': 'Modele ritmice; f – p;',
        'Level1_Lesson17_E02_sol4-mi4-re4-do4': 'Valoarea de optime; două optimi (unite sau separate), formează o valoare de pătrime. Modele ritmice; f – p;',
        'Level1_Lesson17_R01_sol4-mi4-re4-do4': 'Doimi, pătrimi și optimi. Modele ritmice; f – p;',
        'Level1_Lesson17_R02_sol4-mi4-re4-do4': 'Doimi, pătrimi și optimi. Modele ritmice; f – p;',

        'Level1_Lesson18_E01_fa4_v2': 'Sunetul și nota fa; modele ritmice; f – p;',
        'Level1_Lesson18_E02_fa4_v1'	: 'Sunetul și nota fa; modele ritmice; f – p;',
        'Level1_Lesson18_R01_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi și doimi; f – p;',
        'Level1_Lesson18_R02_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi și optimi; f – p;',

        'Level1_Lesson19_E01_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi; f – p;',
        'Level1_Lesson19_E02_sol4--do4'           :	'Modele ritmice cu optimi; f – p;',
        'Level1_Lesson19_R01_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi și optimi; f – p;',
        'Level1_Lesson19_R02_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi și optimi; f – p;',

        'Level1_Lesson20_E01_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi și doimi; f – p; legato și sincopa;',
        'Level1_Lesson20_E02_sol4-fa4-mi4-re4-do4':	'Scară muzicală ascendentă. Modele ritmice cu pătrimi; f – p;',
        'Level1_Lesson20_R01_sol4-fa4-mi4-re4-do4':	'Modele ritmice cu pătrimi și doimi; f – p; solfegiu preparator pentru învățarea cântecului Micul Ionuț (folclorul copiilor);',
        'Level1_Lesson20_R02_sol4-fa4-mi4-re4-do4':	'Cântecul Micul Ionuț (folclorul copiilor). Modele ritmice cu pătrimi și doimi; f – p;',

        'Level1_Lesson21_E01_sol4-fa4-mi4-re4-do4': 'Interval: diferența de înălțime dintre două sunete (și notele respective). Exemple: de la o notă do,\
         la o altă notă do cu aceeași înălțime, intervalul se numește primă, sau unison; la fel între alte sunete de aceeași înălțime: re-re, mi-mi, fa-fa, sol-sol;',
        'Level1_Lesson21_E02_sol4-fa4-mi4-re4-do4': 'Intervale de primă, sau unison: do-do, re-re, mi-mi, fa-fa, sol-sol;\
         după executarea cu succes a acestui solfegiu, încercați să identificați și să cântați toate intervalele de „unison”. Succes!',
        'Level1_Lesson21_R01_sol4-fa4-mi4-re4-do4': 'Intervalul de secundă este diferența de înălțime dintre două sunete adiacente (alăturate) și notele respective. Exemple:\
         do-re, re-do; re-mi, mi-re; mi-fa; fa-mi; fa-sol, sol-fa;',
        'Level1_Lesson21_R02_sol4-fa4-mi4-re4-do4': 'Intervale de secundă: do-re, re-do; re-mi, mi-re; mi-fa; fa-mi; fa-sol, sol-fa; după executarea cu succes a acestui solfegiu,\
         încercați să identificați și să cântați toate intervalele de „secundă”. Succes!',

        'Level1_Lesson22_E01_sol4-fa4-mi4-re4-do4': 'Terța este intervalul de la un sunet la al treilea (de la o notă la a treia succesivă): do-mi, mi-do; re-fa, fa-re, mi-sol, sol-mi;',
        'Level1_Lesson22_E02_sol4-fa4-mi4-re4-do4': 'Intervale; după executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson22_R01_sol4-fa4-mi4-re4-do4': 'Intervale; după executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson22_R02_sol4-fa4-mi4-re4-do4': 'Intervale; după executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',

        'Level1_Lesson23_E01_sol4--do4'           : 'Intervale; după executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson23_E02_sol4-fa4-mi4-re4-do4': 'Intervale; după executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson23_R01_sol4-fa4-mi4-re4-do4': 'Baraboi – Cântec popular românesc (în valori de pătrimi).\
         După interpretarea cu succes a acestui cântec, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson23_R02_sol4-fa4-mi4-re4-do4': 'Baraboi – Cântec popular românesc (în valori de optimi).\
         După interpretarea cu succes a acestui cântec, identificați, numiți și cântați intervalele de primă (unison), secundă și terță. Succes!',

        'Level1_Lesson24_E01_sol4-fa4-mi4-re4-do4'   : 'Modele ritmice cu pătrimi și doimi; f – p. După interpretarea cu succes a acestui solfegiu, identificați, numiți și cântați\
         intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson24_E02_sol4--do4'              : 'Modele ritmice cu pătrimi și doimi; f – p. După interpretarea cu succes a acestui solfegiu, identificați, numiți și cântați\
         intervalele de primă (unison), secundă și terță. Succes!',
        'Level1_Lesson24_R01_sol4-fa4-mi4-re4-do4'   : 'Ceata lui Pițigoi - cântec popular românesc. Intervalul de cvartă: do-fa, fa-do; re-sol, sol-re. \
         După executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă, secundă, terță și cvartă.\
         Pentru consolidarea practicii intervalelor, recomandăm a reveni la Lecția22, R01 și R02;',
        'Level1_Lesson24_R02_sol4-fa4-mi4-re4-do4'   : 'Ceata lui Pițigoi - cântec popular românesc. Intervalul de cvartă: do-fa, fa-do; re-sol, sol-re.\
         După executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de primă, secundă, terță și cvartă.\
         Pentru consolidarea practicii intervalelor, recomandăm a reveni la Lecția22, R01 și R02;',

        'Level1_Lesson25_E01_sol4--do4': 'Modele ritmice cu pătrimi și doimi; f – p;',
        'Level1_Lesson25_E02_sol4--do4': 'Modele ritmice cu pătrimi și doimi; f – p. Semnul de legato dintre o pătrime și o optime de aceeași înălțime,\
         unește valorile creând o valoare egală cu 3 optimi;',
        'Level1_Lesson25_R01_sol4-fa4-mi4-re4-do4'   : 'La moară la Dorohoi – cântec popular. Modele ritmice cu pătrimi și pauze de pătrimi; f – p.\
         Pentru consolidarea practicii intervalelor, recomandăm a reveni la Lecția22, R01 și R02;',
        'Level1_Lesson25_R02_sol4--do4': 'La moară la Dorohoi – cântec popular. Modele ritmice cu pătrimi și pauze de pătrimi; f – p.\
         Pentru consolidarea practicii intervalelor, recomandăm a reveni la Lecția22, R01 și R02;',

        'Level1_Lesson26_E01_sol4-fa4-mi4-re4-do4_v1': 'Modele ritmice, note și pauze; legato și sincopa;',
        'Level1_Lesson26_E02_sol4-fa4-mi4-re4-do4'   : 'Interval de cvintă: do-sol, sol-do. Pătrimi și optimi; exerciții pregătitoare pentru cântecul\
         „Când sfinții mărșăluiesc.” După executarea cu succes a acestui solfegiu, identificați, numiți și cântați intervalele de cvintă (do-sol, sol-do);',
        'Level1_Lesson26_R01_sol4-fa4-mi4-re4-do4_v1': 'Pătrimi și optimi; legato între o pătrime și o optime și sincope cu optimi.” Trezește-te, leneșule”\
         - cântec popular pentru copii. După o interpretare reușită, identificați, numiți și cântați intervalele învățate. Pentru consolidarea practicii\
          intervalelor, recomandăm a reveni la Lecția22, R01 și R02;',
        'Level1_Lesson26_R02_sol4-fa4-mi4-re4-do4'   : 'Doimi, pătrimi și optimi; anacruză, sincopă. Cântec: „Când sfinții mărșăluiesc”.\
         După o interpretare reușită, identificați, numiți și cântați intervalele învățate. Pentru consolidarea practicii intervalelor, \
         reveniți la Lecția22, R01 și R02;',


        'Level2_Lesson01_E01_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
      "Sol" note and sound in various rhythmic patterns',
        'Level2_Lesson01_E02_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
      "Sol" note and sound in various rhythmic patterns',
        'Level2_Lesson01_R01_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
      "Sol" note and sound in various rhythmic patterns',
        'Level2_Lesson01_R02_sol4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats. \
      "Sol" note and sound in various rhythmic patterns',

        'Level2_Lesson02_E01_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats; \
      "sol" and "mi" notes and sounds',
        'Level2_Lesson02_E02_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats; \
      "sol" and "mi" notes and sounds',
        'Level2_Lesson02_R01_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats; \
      "sol" and "mi" notes and sounds',
        'Level2_Lesson02_R02_sol4-mi4': 'Time signature 3/4 consist of an accented beat followed by two unaccented beats;\
      "sol" and "mi" notes and sounds',

        'Level2_Lesson03_E01_do4_v1': 'Time signature 3/4; "do" note and sound in various rhythmic patterns',
        'Level2_Lesson03_E02_do4_v1': 'Time signature 3/4; "do" note and sound in various rhythmic patterns',
        'Level2_Lesson03_R01_sol4-mi4-do4': 'Time signature 3/4; "sol" "mi" and "do", \
      notes and sounds in various rhythmic patterns',
        'Level2_Lesson03_R02_sol4-mi4-do4': 'Time signature 3/4; "sol" "mi" and "do", \
      notes and sounds in various rhythmic patterns',

        'Level2_Lesson04_E01_re4_v1': 'Time signature 3/4; "re" note and sound in various rhythmic patterns',
        'Level2_Lesson04_E02_re4_v1': 'Time signature 3/4; "re" note and sound in various rhythmic patterns',
        'Level2_Lesson04_R01_sol4-mi4-re4-do4': 'Time signature 3/4; "sol" "mi" "re" and "do", \
      notes and sounds in various rhythmic patterns',
        'Level2_Lesson04_R02_sol4-mi4-re4-do4': 'Time signature 3/4; "sol" "mi" "re" and "do", \
      notes and sounds in various rhythmic patterns',

        'Level2_Lesson05_E01_fa4': 'Time signature 3/4; "fa" note and sound in various rhythmic patterns',
        'Level2_Lesson05_E02_fa4': 'Time signature 3/4; "fa" note and sound in various rhythmic patterns',
        'Level2_Lesson05_R01_sol4-fa4-mi4-re4-do4': 'Time signature 3/4; "sol" "fa" "mi" "re" and "do", \
      notes and sounds in various rhythmic patterns',
        'Level2_Lesson05_R02_sol4-fa4-mi4-re4-do4': 'Time signature 3/4; "sol" "fa" "mi" "re" and "do", \
      notes and sounds in various rhythmic patterns',

        'Level2_Lesson06_E01_sol4-fa4-mi4-re4-do4': 'Time signature 3/4; "sol" "fa" "mi" "re" and "do", \
      notes and sounds in various rhythmic patterns',
        'Level2_Lesson06_E02_sol4-fa4-mi4-re4-do4': 'Three-quarters connected are equal with a dotted half. \
      Time signature 3/4; "sol" "fa" "mi" "re" "do", notes and sounds in various rhythmic patterns',
        'Level2_Lesson06_R01_sol4--do4': 'Three-quarters connected are equal with a dotted half. \
      Time signature 3/4; "sol" "fa" "mi" "re" "do", notes and sounds in various rhythmic patterns',
        'Level2_Lesson06_R02_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
      notes and sounds in various rhythmic patterns',

        'Level2_Lesson07_E01_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
      notes and sounds. Rests of eighths',
        'Level2_Lesson07_E02_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
      notes and sounds',
        'Level2_Lesson07_R01_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
      notes and sounds',
        'Level2_Lesson07_R02_sol4--do4': 'Time signature 3/4; "sol" "fa" "mi" "re" "do", \
      notes and sounds',

        'Level2_Lesson08_E01_la4--la4_v2': 'Time signature 2/4; "la4" note and sound in various rhythmic patterns',
        'Level2_Lesson08_E02_la4--la4_v2': 'Time signature 3/4; "la4" note and sound in various rhythmic patterns',
        'Level2_Lesson08_R01_la4--do4': 'Time signature 2/4; the notes and sounds between la4 and do4. \
      "A-B-C" song',
        'Level2_Lesson08_R02_la4--do4': 'Time signature 2/4; the notes and sounds between la4 and do4. \
      "A-B-C" song, with rhythmic variation',

        'Level2_Lesson09_E01_la4--do4': 'Time signature 3/4; the notes and sounds between la4 and do4 in various rhythmic patterns',
        'Level2_Lesson09_E02_la4--do4_v1': 'Time signature 3/4; the notes and sounds between la4 and do4 in various rhythmic patterns',
        'Level2_Lesson09_R01_la4--do4': '"A-B-C" song, rhythmic variation 1',
        'Level2_Lesson09_R02_la4--do4': '"A-B-C" song, rhythmic variation 2',

        'Level2_Lesson10_E01_si3_v1': 'The note and sound si3 in various rhythmic patterns',
        'Level2_Lesson10_E02_si3':    'The note and sound si3 in various rhythmic patterns. Dotted quarter & eighth note rhythm',
        'Level2_Lesson10_R01_la4--si3': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',
        'Level2_Lesson10_R02_la4--si3': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',

        'Level2_Lesson11_E01_la4--si3': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',
        'Level2_Lesson11_E02_la4--do4': 'Various rhythmic patterns including dotted quarter & eighth note in 3/4',
        'Level2_Lesson11_R01_la4--si3': 'Various rhythmic patterns including dotted quarter + eighth, and rest of eighth note in 3/4',
        'Level2_Lesson11_R02_la4--si3': 'Various rhythmic patterns in 2/4. "Brother John" (traditional French song for children) \
      in Canon: imitating the leading voice after 4 measures',

        'Level2_Lesson12_E01_la3_v1': 'The note and sound la3, time signature 2/4, various rhythmic patterns',
        'Level2_Lesson12_E02_la3_v1': 'The note and sound la3, time signature 3/4, various rhythmic patterns',
        'Level2_Lesson12_R01_mi4--la3_v1': 'Various rhythmic patterns in 3/4. Romanian folk song "Crossing the hill to Baiutz"',
        'Level2_Lesson12_R02_mi4--la3_v1': 'Various rhythmic patterns in 2/4. Romanian folk song "Crossing the hill to Baiutz"',

        'Level2_Lesson13_E01_la4--la3': 'Intervals (the difference in pitch between two sounds): octave, seventh, sixth',
        'Level2_Lesson13_E02_la4--la3': 'Intervals (the difference in pitch between two sounds): octave, seventh, sixth',
        'Level2_Lesson13_R01_la4--la3': 'Russian folk song Liulinki, intervals. Analyze, name, and sing the intervals',
        'Level2_Lesson13_R02_la4--la3': 'Russian folk song Korobeiniki, intervals. Analyze, name, and sing the intervals',

        'Level2_Lesson14_E01_sol4--la3': 'Various rhythmic patterns and arpeggios in Do Major and la minor scale. In music theory,\
      "scale" is any graduated sequence of notes, tones, or intervals dividing an octave',
        'Level2_Lesson14_E02_sol4--si3': 'Various rhythmic patterns and arpeggios in Do Major and Sol Major scales. In music theory,\
      "scale" is any graduated sequence of notes, tones, or intervals dividing an octave',
        'Level2_Lesson14_R01_mi4--la3_v2': 'Arpeggio consists of at least two consecutive intervals of thirds. A variation in la minor of \
      "My country" song, by the Romanian composer Dumitru Chiriac. Analyze, name, and sing the intervals',
        'Level2_Lesson14_R02_la4--la3': 'Arpeggio consists of at least two consecutive intervals of thirds. "I look out from Doftana", \
      a Romanian folk song in la minor. Analyze, name, and sing the intervals',

        'Level2_Lesson15_E01_sol4--fad4--re4': 'Accidental: the note and sound fa#4 in various rhythmic patterns',
        'Level2_Lesson15_E02_fad4--fa4': 'Accidental: the note and sound fa#4 - continuation; fa♮ (becar, or natural), in various rhythmic patterns',
        'Level2_Lesson15_R01_la4-fa4-fad4-re4': 'Fa♮ (becar, or natural) in re minor scale, and fa#4 in Re Major scale, in various rhythmic patterns',
        'Level2_Lesson15_R02_fad4--re4': 'Fa#4 in Re Major scale - in various rhythmic patterns',

        'Level2_Lesson16_E01_la4--fad4--re4': 'Practicing Fa#4 in various rhythmic patterns. Analyze, name, and sing the intervals',
        'Level2_Lesson16_E02_la4--fad4--re4': 'Practicing Fa#4 in various rhythmic patterns. Analyze, name, and sing the intervals',
        'Level2_Lesson16_R01_la4--fad4--re4': 'Practicing Fa#4 in various rhythmic patterns. Analyze, name, and sing the intervals',
        'Level2_Lesson16_R02_la4--fad4--re4':  'The song "Little Jonathan" in Re Major scale. Analyze, name, and sing the intervals',

        'Level2_Lesson17_E01_la4--fad4--re4': 'Practice with various rhythmic patterns, including a dotted quarter and an eighth',
        'Level2_Lesson17_E02_la4--fad4--re4': 'Practice with various rhythmic patterns, including syncope',
        'Level2_Lesson17_R01_la4--fad4--re4': 'A variation of the song "My country" by the Romanian composer Dumitru Chiriac, in Re Major',
        'Level2_Lesson17_R02_la4--fad4--re4': 'Practice with various rhythmic patterns. Analyze, name, and sing the intervals ',

        'Level2_Lesson18_E01_si4_v2': 'The note and sound si4 in various rhythmic patterns',
        'Level2_Lesson18_E02_si4_v1': 'The note and sound si4 in various rhythmic patterns',
        'Level2_Lesson18_R01_si4--re4_v1': '"A-B-C" song and various rhythmic patterns in Re Major',
        'Level2_Lesson18_R02_si4--la3_v1': 'The song "Brother John" (traditional French song for children), \
      in Re Major. Canon: imitating to leading voice after 4 measures ',

        'Level2_Lesson19_E01_si4--mi4': 'Variation of the Romanian folk song "Crossing the hill to Baiutz" in 3/4 and mi minor. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson19_E02_si4--re4_v1': 'Variation 1 of the "A-B-C" song, in 2/4, in Re Major',
        'Level2_Lesson19_R01_si4--re4': 'Variation 2 of the "A-B-C" song, in 2/4, in Re Major',
        'Level2_Lesson19_R02_si4--mi4': 'Variation of the Romanian folk song "Crossing the hill to Baiutz" in 2/4 and mi minor',

        'Level2_Lesson20_E01_si4--si3': 'Practice intervals in mi minor: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
       Analyze, name, and sing the intervals',
        'Level2_Lesson20_E02_si4--si3': 'Practice intervals in mi minor: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
       Analyze, name, and sing the intervals',
        'Level2_Lesson20_R01_la4--re4': '"My dear mother" (music: Nahum Nardi, lyrics: Moshe Dafna) in re minor. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson20_R02_si4-mi4': '"My dear mother" (music: Nahum Nardi, lyrics: Moshe Dafna) in mi minor. \
      Analyze, name, and sing the intervals',

        'Level2_Lesson21_E01_la4--la3': 'Practice intervals on re minor: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
       Analyze, name, and sing the intervals',
        'Level2_Lesson21_E02_la4--la3': 'Practice intervals in Re Major: octave, seventh, sixth, fifth, fourth, third, second, prime (unison). \
       Analyze, name, and sing the intervals',
        'Level2_Lesson21_R01_si4--re4': 'The song "Take Caramels" in Re Major. Analyze, name, and sing the intervals',
        'Level2_Lesson21_R02_la4--do4': 'The song "Take Caramels" in Mi Major. Analyze, name, and sing the intervals',

        'Level2_Lesson22_E01_la4--la3': 'Practice intervals in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson22_E02_la4--la3': 'Practice intervals in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson22_R01_la4--la3_v1': '"What good and pleasant it is" - Israeli folk song, words from Psalms. \
      Canon: imitating the leading voice after 16 measures',
        'Level2_Lesson22_R02_la4--la3_v1': 'What good and pleasant it is" - Israeli folk song, words from Psalms. \
      Canon: imitating the leading voice after 16 measures. Different rhythmic patterns',

        'Level2_Lesson23_E01_do5_v2': 'The note and sound do5. Various rhythmic patterns',
        'Level2_Lesson23_E02_do5': 'The note and sound do5. Various rhythmic patterns',
        'Level2_Lesson23_R01_do5--do4': '"Dayenu", Passover Song. Quarters-based rhythmic patterns',
        'Level2_Lesson23_R02_do5--do4': '"Dayenu", Passover Song. Eighths-based rhythmic patterns',

        'Level2_Lesson24_E01_do5--do4': 'Practice intervals in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson24_E02_do5--do4': 'Practice intervals in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson24_R01_do5--mi4': 'Romanian folk song with halves and quarters',
        'Level2_Lesson24_R02_do5--mi4_v1': 'Romanian folk song with quarters and eighths. \
       Anacrusis: a note (or more), which precedes the first beat of the first complete measure',

        'Level2_Lesson25_E01_do5--do4': 'Practice intervals in Do Major, in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson25_E02_la4--la3': 'Practice intervals in la minor, in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson25_R01_do5--do4_v1': 'Quarter-based rhythmic patterns: "Can-Can" by Jacques Offenbach',
        'Level2_Lesson25_R02_do5--do4': 'Eights-based rhythmic patterns: "Can-Can" by Jacques Offenbach',

        'Level2_Lesson26_E01_la3--do5_v1': 'Practice intervals in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson26_E02_do5--la3_v1': 'Practice intervals in different rhythmic patterns. \
      Analyze, name, and sing the intervals',
        'Level2_Lesson26_R01_sol4--do4_v1': 'Excerpt from "Ode to Joy" by Ludwig van Beethoven, in Do Major',
        'Level2_Lesson26_R02_la4--la3_v1': 'Excerpt from "Ode to Joy" by Ludwig van Beethoven, in Re Major',

        'Level2_Lesson27_E01_mi4--la3': 'The sound and the note "do#4"; Rhythmic patterns in 3/4',
        'Level2_Lesson27_E02_la4--fad4-fa4--la3_v2': 'Rhythmic patterns in 3/4 with do#4 and do4',
        'Level2_Lesson27_R01_mi4--la3': 'Variations of the song "My country" by the Romanian composer Dumitru Chiriac \
      in 2 parts: 1. in la minor, 2. in La Major',
        'Level2_Lesson27_R02_fad4--la3': 'A rhythmic variation of the song "A-B-C", in La Major',

        'Level2_Lesson28_E01_si4--la3': 'A traditional canon-shaped song: "Give us peace." \
      Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',
        'Level2_Lesson28_E02_si4--re4': 'A traditional canon-shaped song: "Give us peace." \
      Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',
        'Level2_Lesson28_R01_la4--la3': 'A traditional canon-shaped song: "Give us peace." \
      Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',
        'Level2_Lesson28_R02_si4--la3': 'A traditional canon-shaped song: "Give us peace." \
      Text, from the Latin Mass; first voice. Analyze, name, and sing the intervals',

        'Level3_Lesson01_E01_la4--dod4': 'The sharp (♯), flat (♭), and natural (♮) symbols are called accidentals. Fa# and do#, symmetrical rhythmic patterns',
        'Level3_Lesson01_E02_fad4--dod4': 'The sharp (♯), flat (♭), and natural (♮) symbols are called accidentals. Fa# and do#, symmetrical rhythmic patterns',
        'Level3_Lesson01_R01_si4--la3': 'Excerpt 1 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
        'Level3_Lesson01_R02_si4--la3': 'Excerpt 2 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',

        'Level3_Lesson02_E01_si4--la3': 'Excerpt 3 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
        'Level3_Lesson02_E02_si4--la3': 'Excerpt 4 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
        'Level3_Lesson02_R01_si4--la3': 'Excerpt 1 & 2 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',
        'Level3_Lesson02_R02_si4--la3': 'Excerpt 3 & 4 from "Winter Song" by composer Yinon Tzur. Practice with asymmetrical rhythmic patterns',

        'Level3_Lesson03_E01_mi4--la3': 'Practicing with "Do#". Symmetrical rhythmic patterns',
        'Level3_Lesson03_E02_la4--re4': 'Practicing with "Fa#". Symmetrical rhythmic patterns',
        'Level3_Lesson03_R01_do5--la3': 'First excerpt from "Furry of Dawn" by the composer Yinon Tzur. Practice Fa sharp and natural. Asymmetrical rhythmic patterns',
        'Level3_Lesson03_R02_si4--la3': 'Second excerpt from "Furry of Dawn" by the composer Yinon Tzur. Practice Fa sharp and natural. Asymmetrical rhythmic patterns',

        'Level3_Lesson04_E01_sib4--sib3': 'Practicing the notes and sounds si♭4 and si♭3',
        'Level3_Lesson04_E02_do5--si3-sib3': 'Practicing the notes and sounds si♭4-si4 and si♭3-si3',
        'Level3_Lesson04_R01_do5--la3': 'Practicing the notes and sounds si♭4 and si♭3. Rhythmic patterns in 3/4',
        'Level3_Lesson04_R02_do5--la3': 'Practicing the notes and sounds si♭4 and si♭3. Rhythmic patterns in 2/4; syncope',

        'Level3_Lesson05_E01_do5--do4': 'Variation on 3/4 of the song "A-B-C" - in Fa Major',
        'Level3_Lesson05_E02_do5--do4': 'A new variation on 3/4 of the song "A-B-C" - in Fa Major',
        'Level3_Lesson05_R01_do5--do4': 'Variation on 3/4 of the romanian popular song "Baraboi" - in Fa Major ',
        'Level3_Lesson05_R02_do5--do4': 'Practicing in Fa Major ',

        'Level3_Lesson06_E01_do5-mib4-sib3': 'A new note and sound: mi♭. Intervals (defined as a distance in pitch between any two notes): \
      third, fourth, fifth, sixth. Analyze, sing and note the names of the intervals',
        'Level3_Lesson06_E02_sib4-mib4-sib3': 'Interval practice: third, fourth, fifth, sixth. Arpeggios \
      Analyze, sing and note the names of the intervals',
        'Level3_Lesson06_R01_sol4--sib3': 'The song "Brother Jacob" in Si♭ Major (French children song) \
      Singing in canon: imitating the leading group, after 2 measures',
        'Level3_Lesson06_R02_sol4--sib3': 'The song "Brother Jacob" in Si♭ Major (French children song) \
      Canon: the "second voice", that imitate the leading voice after 2 measures ',

        'Level3_Lesson07_E01_fa4--sib3': 'Rhythmic patterns in Si♭ Major. Analyze, sing and note the names of the intervals',
        'Level3_Lesson07_E02_sib4--mib4': 'Rhythmic patterns in Mi♭ Major. Analyze, sing and note the names of the intervals',
        'Level3_Lesson07_R01_sib4--la3': 'A folk song. Practice in re minor and Fa Major scales,',
        'Level3_Lesson07_R02_do5--si3': 'A folk song. Practice in mi minor and Sol Major scales',

        'Level3_Lesson08_E01_sib4--sib3': 'Rhythmic patterns in Mi♭ Major. Exercise with anacrusis and syncopes \
      Analyze, sing and note the names of the intervals',
        'Level3_Lesson08_E02_sib4--sib3': 'Rhythmic patterns in Mi♭ Major. Exercise with anacrusis and syncopes \
      Analyze, sing and note the names of the intervals',
        'Level3_Lesson08_R01_fa4--sib3': 'Romanian folk song "The Naughty Bunch" in Si♭ Major; rhythmic patterns with halves and quarters',
        'Level3_Lesson08_R02_fa4--sib3': 'Romanian folk song "The Naughty Bunch" in Si♭ Major; rhythmic patterns with quarters and eighths',

        'Level3_Lesson09_E01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
        'Level3_Lesson09_E02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
        'Level3_Lesson09_R01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',
        'Level3_Lesson09_R02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',

        'Level3_Lesson10_E01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
        'Level3_Lesson10_E02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - part one. Arrangement by the composer Michael Dulitsky',
        'Level3_Lesson10_R01_do5--sib3': 'Solfege in Si♭ Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',
        'Level3_Lesson10_R02_do5--do4': 'Solfege in Do Major, by Nikolai Laduchin - parts one and two. Arrangement by the composer Michael Dulitsky',

        'Level3_Lesson11_E01_sold4': 'The note and sound sol♯',
        'Level3_Lesson11_E02_si4-sold4-mi4': 'The note and sound sol♯',
        'Level3_Lesson11_R01_si4-sol4-sold4-mi4': 'Two-parts solfege: the first part (eight measures) in mi minor scale \
       and the second part (the next 8 measures) in Mi Major scale',
        'Level3_Lesson11_R02_si4--mi4': 'Practicing solfege in Mi Major',

        'Level3_Lesson12_E01_si4--mi4': 'Exercise in Mi Major. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson12_E02_si4--mi4': 'What sounds do you have to sing instead of the rests, to get the "Little Jonathan" song? Exercise in Mi Major ',
        'Level3_Lesson12_R01_si4--mi4': 'Compare with the previous solfege, and find out the answer to the question asked at E02',
        'Level3_Lesson12_R02_si4--mi4': 'Exercise in Mi Major. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',

        'Level3_Lesson13_E01_si4--si3': 'Rhythmic patterns with rests of eighths - in Mi Major \
      Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson13_E02_si4--mi4': 'Rhythmic patterns with rests of eighths in Mi Major \
      Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson13_R01_do5--sib3': 'Excerpt 1 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
        'Level3_Lesson13_R02_do5--do4': 'Excerpt 2 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',

        'Level3_Lesson14_E01_do5--sib3': 'Excerpt 1 + 2 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
        'Level3_Lesson14_E02_do5--sib3': 'Excerpt 3 "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
        'Level3_Lesson14_R01_do5--la3': 'Excerpt 4 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',
        'Level3_Lesson14_R02_do5--la3': 'Excerpt 5 from "The City of Sails" from the soundtrack of the game "Sword Coast Legends", composer Yinon Tzur',

        'Level3_Lesson15_E01_lab4': 'The note and sound la♭4',
        'Level3_Lesson15_E02_lab4-fa4': 'The note and sound la♭4. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson15_R01_do5--mib4' :'The song "Take caramels" in Mi♭ Major',
        'Level3_Lesson15_R02_do5--mib4': 'Rhythmic variation of the song "A-B-C", in Mi♭ Major',

        'Level3_Lesson16_E01_do5--sib3': 'Rhythmic patterns in Mi♭ Major. Replace the rests with \
       "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson16_E02_sib5--mib4': 'Rhythmic patterns in Mi♭ Major. Replace the rests with \
       "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson16_R01_do5--mib4': 'Variation in 3/4 of the Romanian folk song "Cross the hill to Baiutz", in fa minor scale',
        'Level3_Lesson16_R02_do5--fa4': 'Variation in 3/4 of the Romanian folk song "Cross the hill to Baiutz", in fa minor scale',

        'Level3_Lesson17_E01_si4--si3': 'Intervals in Mi♭ Major. Analyze, sing and note the names of the intervals',
        'Level3_Lesson17_E02_si4--si3': 'Intervals in Mi♭ Major. Analyze, sing and note the names of the intervals',
        'Level3_Lesson17_R01_A-do5--sib3' :'The song "Brother Jacques" (French children song) in Mi♭ Major \
      Singing in canon: the second voice will imitate the leading voice after 4 measures',
        'Level3_Lesson17_R02_B-do5--sib3': 'The song "Brother Jacques" (a French children song) in Mi♭ Major \
      Singing in canon: the second voice imitates the leading voice after 4 measures',

        'Level3_Lesson18_E01_do5--do4': 'Intervals in Fa Major. Analyze, sing and note the names of the intervals',
        'Level3_Lesson18_E02_do5--do4': 'Intervals in Fa Major. Analyze, sing and note the names of the intervals',
        'Level3_Lesson18_R01_si4--si3': '"Liulinki", Russian folk song in si minor. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',
        'Level3_Lesson18_R02_do5--do4': '"Liulinki", Russian folk song in do minor. Replace the rests with "body percussion sounds": applause, snapping fingers, pats on the knees',

        'Level3_Lesson19_E01_do5--sib3': 'Excerpt 1 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',
        'Level3_Lesson19_E02_sib4--sib3' :'Excerpt 2 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',
        'Level3_Lesson19_R01_do5--sib3': 'Excerpt 3 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',
        'Level3_Lesson19_R02_do5--sib3': 'Excerpt 4 from "Champions" (from the Album Into the Storm), composer Ynon Zur. Asymmetrical rhythmic patterns',

        'Level3_Lesson20_E01_do5--sib3': 'Excerpt 1 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
      composer Ynon Tzur. Asymmetrical rhythmic patterns',
        'Level3_Lesson20_E02_do5--sib3': 'Excerpt 2 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
      composer Ynon Tzur. Asymmetrical rhythmic patterns',
        'Level3_Lesson20_R01_do5--si3': 'Excerpt 3 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
      composer Ynon Tzur. Asymmetrical rhythmic patterns',
        'Level3_Lesson20_R02_sib4--la3': 'Excerpt 4 from "Laduguers Anvil", soundtrack of the game "Sword Coast Legends" \
      composer Ynon Tzur. Asymmetrical rhythmic patterns',

        'Level3_Lesson21_E01_sib4--sib3': 'A "Lullaby" by Johannes Brahms, in Si♭ Major',
        'Level3_Lesson21_E02_do5--do4': 'A "Lullaby" by Johannes Brahms, in Do Major',
        'Level3_Lesson21_R01_sib4--la3': 'Part one of a "Minuete" by Johann Sebastian Bach (in Si♭ Major). "Minuet" is a musical work in 3/4',
        'Level3_Lesson21_R02_sib4--la3': 'Part two of a "Minuete" by Johann Sebastian Bach (in Si♭ Major). "Minuet" is a musical work in 3/4',

        'Level3_Lesson22_E01_la4--la3': '"Lullaby" by Franz Schubert in Re Major',
        'Level3_Lesson22_E02_sib4--sib3': '"Lullaby" by Franz Schubert in Mi♭ Major',
        'Level3_Lesson22_R01_sib4--la3': '"Minuete" by Johann Sebastian Bach, in Si♭ Major. "Minuet" is a musical work in 3/4 and two parts',
        'Level3_Lesson22_R02_do5--sib3': '"Minuete" by Johann Sebastian Bach, in Do Major. "Minuet" is a musical work in 3/4 and two parts',

        'Level3_Lesson23_E01_la4--la3': 'The song "Tonic" by Ilie Harubaru in La Major scale. Tonic, also called keynote, \
      is the first note (degree) of any diatonic (e.g., major or minor) scale.',
        'Level3_Lesson23_E02_la4--la3': 'The song "Tonic" by Ilie Hrubaru in La Major scale. Tonic, also called keynote, \
      is the first note (degree) of any diatonic (e.g., major or minor) scale. Rhythmic variation',
        'Level3_Lesson23_R01_sib4--sib3': 'The song "Tonic" by Ilie Hrubaru in Si♭ Major scale. Tonic, also called keynote, \
      is the first note (degree) of any diatonic (e.g., major or minor) scale. Rhythmic and tonal variation',
        'Level3_Lesson23_R02_do5--do4': 'The song "Tonic" by Ilie Hrubaru in Si♭ Major scale. Tonic, also called keynote, \
      is the first note (degree) of any diatonic (e.g., major or minor) scale. Rhythmic and tonal variation',

        'Level3_Lesson24_E01_la4--la3': 'The song "Riddle" by Ilie Hrubaru, in La Major',
        'Level3_Lesson24_E02_la4--la3': 'The song "Riddle" by Ilie Hrubaru, in La Major; rhythmic variation',
        'Level3_Lesson24_R01_sib4--sib3': 'The song "Riddle" by Ilie Hrubaru, in Si♭ Major; tonal and rhythmic variation',
        'Level3_Lesson24_R02_do5--do4': 'The song "Riddle" by Ilie Hrubaru, in Do Major; tonal and rhythmic variation',

        'Level3_Lesson25_E01_sib4--mib3': 'Johann Sebastian Bach, "Musette" in Mi♭ Major, part one',
        'Level3_Lesson25_E02_sib4--sib3': 'Johann Sebastian Bach, "Musette" in Mi♭ Major, part one and part two',
        'Level3_Lesson25_R01_sib4--sib3': 'The song "Flat, Natural, Sharp" in Si♭ Major, by Ilie Hrubaru',
        'Level3_Lesson25_R02_do5--do4': 'The song "Flat, Natural, Sharp" in Do Major, by Ilie Hrubaru',

        'Level3_Lesson26_E01_sib--la3-rem': '"Old French song" (from the Album for young people)\
      Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in re minor, part 1',
        'Level3_Lesson26_E02_do5--la3-rem': '"Old French song" (from the Album for young people)\
      Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in re minor, part 2',
        'Level3_Lesson26_R01_do5--la3-rem': '"Old French song" (from the Album for young people)\
      Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in re minor',
        'Level3_Lesson26_R02_do5--si3-mim': '"Old French song" (from the Album for young people)\
      Op. 39, No. 16 by Pyotr Ilyich Tchaikovsky in mi minor',

        'Level3_Lesson27_E01_la4--la3-Re': '"On the Way to Viseu", traditional Portuguese song, in Re Major, with quarters',
        'Level3_Lesson27_E02_sib4--sib3-Mib': '"On the Way to Viseu", traditional Portuguese song, in Mi♭ Major, with quarters',
        'Level3_Lesson27_R01_la4--la3-Re': '"On the Way to Viseu", traditional Portuguese song, in Mi♭ Major, with eighths',
        'Level3_Lesson27_R02_sib4--la3-ReMib': '"On the Way to Viseu", traditional Portuguese song, in Re Major with modulation to Mi♭ Major \
      Modulation is the change from one tonality (tonic, or tonal center) to another.',

        'Level3_Lesson28_E01_la4--la3-re': 'A traditional Portuguese song "The Dove" in re minor, with quarters and halves',
        'Level3_Lesson28_E02_si4--si3-mi': 'A traditional Portuguese song "The Dove" in mi minor, with quarters and halves',
        'Level3_Lesson28_R01_si4--la3-remi': 'Modulation is the change from one tonal center to another, during a piece.\
      A traditional Portuguese song "The Dove" in re minor with modulation to mi minor',
        'Level3_Lesson28_R02_do5--si3-mifa': 'Modulation is the change from one tonal center to another, during a piece.\
      A traditional Portuguese song "The Dove" in mi minor with modulation to fa minor',
};

export default ExerciseDescriptions_ro;