import React, { useEffect, useState, useRef } from 'react';
import Level from './Level';
import './styles/puzzle.css';
import { fragmentLists } from './fragments';  // Import fragment file names

function Puzzle(props) {
  const [levels, setLevels] = useState([]);
  const [unlockedLevels, setUnlockedLevels] = useState([0]); // Only the first level is unlocked
  const [solvedLevels, setSolvedLevels] = useState([]); // Track solved levels

  const puzzleData = fragmentLists[props.puzzleName] || [];

  const fragments = puzzleData['frags'];
  const audio = puzzleData['audio'];
  const audioRef = useRef(null);
  const timeoutRef = useRef(null);

  const meterBeatCount = puzzleData['meterBeatCount']


  useEffect(() => {
    // Organize fragments into levels and set state
    const levelsData = organizeFragments(fragments);
    setLevels(levelsData);
  }, [props.puzzleName]);

  const organizeFragments = (fragments) => {
    const levels = {};

    fragments.forEach((fragData) => {
      const { fragPath, level, row, idx, anacrusis, complement, dur } = fragData;

      if (!levels[level]) {
        levels[level] = { level, stash: [], rows: {},  startTime: null, dur: null };
      }

      if (!levels[level].rows[row]) {
        levels[level].rows[row] = { row, fragments: [] };
      }

      // Push fragment with its metadata
      levels[level].stash.push({fragPath, level, row, idx, anacrusis, complement, dur, startTime: null });});

    var t = 0.;


    for (const [ level, dictionary ] of Object.entries(levels)) {
      // do something with `key` and `value`

      levels[level].startTime = t;
      levels[level].stash.forEach((frag) => {
        frag.startTime = t;
        t = t + frag.dur;
      })
      levels[level].dur = t - levels[level].startTime;
    }

    // Convert levels to array and ensure rows are arrays for rendering
    return Object.values(levels).map((level) => ({...level, rows: Object.values(level.rows),}));
  };

  // Function to handle level completion
  const handleLevelSolved = (levelIndex) => {
    setSolvedLevels([...solvedLevels, levelIndex]);

    const nextLevel = levelIndex + 1;
    if (nextLevel < levels.length) {
      setUnlockedLevels([...unlockedLevels, nextLevel]); // Unlock next level
    }
  };

  const playSegment = (startTime, dur) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = startTime * (60/audio.tempo); // Set the start time
      audioRef.current.play(); // Start playing

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Stop after the duration
      timeoutRef.current =  setTimeout(() => {
        audioRef.current.pause(); // Pause the audio
      }, dur * 1000 *(60/audio.tempo)
      ); // Convert duration to milliseconds
    }
  };

  return (
    <div className="App">
      <audio ref={audioRef}><source src={`/media/games/${audio.audioPath}`} type="audio/mpeg"/></audio>
      <h1>Musical Puzzle</h1>
      {levels.map((level, index) => (
          unlockedLevels.includes(index) && ( // Render only if the level is unlocked
              <Level
                  level={level}
                  key={level.level}
                  hasAudio={audio['hasAudio']}
                  meterBeatCount={meterBeatCount}
                  isUnlocked={unlockedLevels.includes(index)} // Only render unlocked levels
                  isSolved={solvedLevels.includes(index)} // Lock level if solved
                  onLevelSolved={() => handleLevelSolved(index)} // Mark the level as solved
                  playSegment={playSegment}
          />
        )
      ))}
    </div>
  );
}

export default Puzzle;
