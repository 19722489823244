import React from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import MyGroups from './mygroups';
import GroupDetails from './group_details';
import StudentDetails from './student_details';
import ExerciseDetails from './exercise_details';

function MyStudents(props) {
    const navigate = useNavigate();

    // Dynamic navigation helper
    const setPageCallback = (path, params) => {
        const fullPath = Object.keys(params).reduce((acc, key) => `${acc}/${params[key]}`, path);
        navigate(`/app/my_groups${fullPath}`);
    };

    return (
        <Routes>
            {/* Root Route: MyGroups */}
            <Route
                path="/"
                element={
                    <MyGroups
                        lang={props.lang}
                        setPageCallback={(groupId) => setPageCallback(`/${groupId}`, {})}
                        windowSize={props.windowSize}
                    />
                }
            />

            {/* Group Details */}
            <Route
                path=":groupId"
                element={
                    <GroupDetailsWrapper
                        {...props}
                        setPageCallback={(studentId) => setPageCallback(`/${studentId}`, {})}
                    />
                }
            />

            {/* Student Details */}
            <Route
                path=":groupId/:studentId"
                element={
                    <StudentDetailsWrapper
                        {...props}
                        setPageCallback={(exerciseId) => setPageCallback(`/${exerciseId}`, {})}
                    />
                }
            />

            {/* Exercise Details */}
            <Route
                path=":groupId/:studentId/:exerciseId"
                element={<ExerciseDetailsWrapper {...props} />}
            />
        </Routes>
    );
}

function GroupDetailsWrapper(props) {
    const { groupId } = useParams();

    return (
        <GroupDetails
            lang={props.lang}
            group={groupId}
            setPageCallback={props.setPageCallback}
            windowSize={props.windowSize}
            pitchToleranceRange={props.pitchToleranceRange}
        />
    );
}

function StudentDetailsWrapper(props) {
    const { groupId, studentId } = useParams();

    return (
        <StudentDetails
            lang={props.lang}
            username={studentId}
            group={groupId}
            setPageCallback={props.setPageCallback}
            windowSize={props.windowSize}
            SolfegeLevels={props.SolfegeLevels}
            TheoryCourses={props.TheoryCourses}
            SongClasses={props.SongClasses}
            teacherReview={true}
        />
    );
}

function ExerciseDetailsWrapper(props) {
    const { groupId, studentId, exerciseId } = useParams();

    return (
        <ExerciseDetails
            lang={props.lang}
            pk={exerciseId}
            username={studentId}
            group={groupId}
            windowSize={props.windowSize}
            pitchToleranceRange={props.pitchToleranceRange}
            scale={props.scale}
            setScaleCallback={props.setScaleCallback}
            setPageCallback={props.setPageCallback}
        />
    );
}

export default MyStudents;
