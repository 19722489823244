import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, } from './axios_instance';


const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

const SessionProvider = ({ children, setAuthenticatedCallback, sessionTimeout, expiredText }) => {
    const navigate = useNavigate();
    const sessionTimeoutRef = useRef(null);
    const SESSION_TIMEOUT = sessionTimeout * 1000; // translate input in sec to ms

    const startSessionTimer = () => {
        clearSessionTimer();
        sessionTimeoutRef.current = setTimeout(() => {
            handleSessionExpired();
        }, SESSION_TIMEOUT);
    };

    const resetSessionTimer = () => {
        startSessionTimer();
    };

    const clearSessionTimer = () => {
        if (sessionTimeoutRef.current) {
            clearTimeout(sessionTimeoutRef.current);
        }
    };

    const handleSessionExpired = () => {
        alert(expiredText);
        clearSessionTimer();
        Logout(true);
        window.location.href = '/';
    };

    const Logout = async () => {
        try {
            const response = await axiosInstance.post('/api/logout/');
            setAuthenticatedCallback(false);
            console.log(response.data); // "Logged out successfully."
        } catch (error) { console.error(error) };
    };

    useEffect(() => {
        startSessionTimer();
        return () => clearSessionTimer();
    }, []);

    useEffect(() => {
        const events = ['click', 'keydown', 'scroll'];
        const resetTimer = () => resetSessionTimer();

        events.forEach(event => window.addEventListener(event, resetTimer));

        return () => {
            events.forEach(event => window.removeEventListener(event, resetTimer));
        };
    }, []);

    return (
        <SessionContext.Provider value={{ resetSessionTimer }}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;
