// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  height: 100%;
  margin: 0;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  margin: 0 8px 0 0;
}

/*.ellipsis-text:hover {
  white-space: nowrap;
  overflow: visible;
  text-overflow: initial;
  position: relative;
  z-index: 10;*/ /* to ensure it overlays other content */
  /*max-width: none;*/ /* allow it to expand */
/*}*/


.music-cursor {
    position: absolute;
    left: 70px;
    top: 40px;
    height: 40px;
    width: 10px;
    background-color: #FFC300;
}

.canvas-container {
    position: relative;
    height: 400px;
    clear: both;
    background-color: white;
    overflow-y:auto;
    overflow-x:hidden;
    /*border: 1px solid black;*/
}
`, "",{"version":3,"sources":["webpack://./solfy-fe/styles/extras.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;;;;eAKe,EAAE,wCAAwC;EACvD,mBAAmB,EAAE,uBAAuB;AAC9C,IAAI;;;AAGJ;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,2BAA2B;AAC/B","sourcesContent":["body {\r\n  height: 100%;\r\n  margin: 0;\r\n}\r\n\r\n.ellipsis-text {\r\n  overflow: hidden;\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n  max-width: 140px;\r\n  margin: 0 8px 0 0;\r\n}\r\n\r\n/*.ellipsis-text:hover {\r\n  white-space: nowrap;\r\n  overflow: visible;\r\n  text-overflow: initial;\r\n  position: relative;\r\n  z-index: 10;*/ /* to ensure it overlays other content */\r\n  /*max-width: none;*/ /* allow it to expand */\r\n/*}*/\r\n\r\n\r\n.music-cursor {\r\n    position: absolute;\r\n    left: 70px;\r\n    top: 40px;\r\n    height: 40px;\r\n    width: 10px;\r\n    background-color: #FFC300;\r\n}\r\n\r\n.canvas-container {\r\n    position: relative;\r\n    height: 400px;\r\n    clear: both;\r\n    background-color: white;\r\n    overflow-y:auto;\r\n    overflow-x:hidden;\r\n    /*border: 1px solid black;*/\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
