import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

// Custom styling for small text, using props for additional styles
const SmallText = styled('span')(({ Style }) => ({
    ...Style, // Apply additional styles
}));

// Custom styling for normal text, using props for additional styles
const NormalText = styled('span')(({ Style }) => ({
    ...Style, // Apply additional styles
}));


export const CustomTypography = React.forwardRef(({ text, normalstyle, smallstyle, addclass, disabled=false,...props }, ref) => {
    // Regular expression to find text enclosed in brackets
    const parts = text.split(/(\[.*?\])/);
    const dimmedColor = 'rgba(0, 0, 0, 0.38)';
    const effectiveNormalStyle = {
        ...normalstyle,
        color: disabled ? dimmedColor : normalstyle.color || 'inherit',
    };
    const effectiveSmallStyle = {
        ...smallstyle,
        color: disabled ? dimmedColor : smallstyle.color || 'inherit',
    };
    return (
        <Typography
            className={addclass}
            style={{ lineHeight: 1 }}
            ref={ref}
            {...props}
        >
            {parts.map((part, index) =>
                part.startsWith('[') && part.endsWith(']') ? (
                    <SmallText key={index} Style={effectiveSmallStyle}>{part.slice(1, -1)}</SmallText> // Remove brackets and apply smaller font with additional style
                ) : (
                        <NormalText key={index} Style={effectiveNormalStyle}>{part}</NormalText> // Regular font with additional style
                )
            )}
        </Typography>
    );
});
